import {NetworkState, networkStateReducer, post} from "../networkStateReducer";
import {OrderSearchReq, OrderSearchResponse} from "./ongoingOrders";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/past-order"

export const pastOrdersReducer = networkStateReducer<OrderSearchReq>(domain)

export function useGetPastOrders(): [NetworkState<OrderSearchResponse>, (orderSearchReq: OrderSearchReq) => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<OrderSearchResponse>>(state => state.order.past),
        (orderSearchReq) => dispatch(post(domain, "/customer-api/v2/order/get-orders-completed", orderSearchReq))
    ]
}
