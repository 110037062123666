import React, {PropsWithChildren, useEffect, useMemo} from "react";
import {ReactKeycloakProvider, useKeycloak} from '@react-keycloak/web'
import Keycloak from "keycloak-js";
import {useDispatch} from "react-redux";
import {setToken, setUserInfo} from "../reducers/user/userInfo";
import {AuthClientEvent} from "@react-keycloak/core/lib/types";
import {useHistory} from "react-router-dom";

export interface UserInfo {
    email: string;
    email_verified: boolean;
    family_name: string;
    given_name: string;
    name: string;
    preferred_username: string;
    sub: string;
    dob?: {
        date: string;
        month: string;
        year: string;
    };
    user_address?: string;
    phone_number: string;
}


export function parseCookie(str: string): {[k: string]: string} {
    return str
        .split(';')
        .map(v => v.split('='))
        .filter(v => v.length === 2)
        .reduce<{[k: string]: string}>((acc, v) => {
            acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
            return acc;
        }, {});
}

export const keycloak = Keycloak({
    realm: (window as any).REALM ?? parseCookie(document.cookie)["X-APPIGO-REALM"] ?? 'appigo-retailer',
    clientId: 'web-portal',
    url: `/auth/`
})

export function AuthProvider({children}: PropsWithChildren<{}>) {

    const dispatch = useDispatch();

    return <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{
            onLoad: 'check-sso',
            checkLoginIframe: false
        }}
        onEvent={(eventType: AuthClientEvent) => {
            switch (eventType) {
                case "onAuthSuccess":
                    dispatch(setToken(keycloak.token))
                    keycloak.loadUserInfo().then(userInfo => {
                        dispatch(setUserInfo(userInfo as UserInfo))
                    })
                    break;
                case "onAuthLogout":
                    dispatch(setToken(undefined))
                    dispatch(setUserInfo(undefined))
            }
        }}
    >
        {children}
    </ReactKeycloakProvider>
}

export function RequireLogin({children}: PropsWithChildren<{}>) {

    const {keycloak} = useKeycloak();
    const {push} = useHistory()

    useEffect(() => {
        if (!keycloak.authenticated) {
            push("/")
        }
    },[keycloak.authenticated])

    return <>
        {children}
    </>

}
