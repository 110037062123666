import React, { useEffect } from "react";
import { useSchema } from "../../../../reducers/schema";
import { isSuccess } from "../../../../reducers/networkStateReducer";
import { useLocalStorage } from "../../Body/Checkout/CheckoutFlowRevamp/utils";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useClearCart } from "../../../../reducers/cart/clearCart";
import { useStoreSelectionPopUp } from "../../../../reducers/ui/storeSelectionPopUp";

export function StoreSelection() {

    const [schema] = useSchema();
    const [getItem] = useLocalStorage<{ inventoryId: string }>();
    let [state, clearCart, resetClearCart] = useClearCart();
    const [storeSelectionPopup, setStoreSelectionPopup] = useStoreSelectionPopUp()

    useEffect(() => {
        if (isSuccess(state)) {
            window.location.reload()
        }
    }, [state])

    useEffect(() => {
        return clearCart
    }, [])

    if (!isSuccess(schema)) return null

    return <div className="dropdown">
        {!!schema.data.inventoryLocations?.length && schema.data.inventoryLocations?.length > 1 && <button className="btn btn-light login-button dropdown-toggle my-2 my-sm-0 p-1 border-0 bg-transparent"
            type="button" onClick={(e) => {
                setStoreSelectionPopup(true)
            }}
            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {schema.data.inventoryLocations?.find((inventory) => inventory.id == getItem('inventoryId')?.inventoryId)?.name}
            <FontAwesomeIcon className={"pl-1"} icon={faMapMarkerAlt} size={"lg"} />
        </button>}
    </div>
}