import {isError, isSuccess, NetworkState, networkStateReducer, post, reset} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import { CreateCustomerReq, Customer } from ".";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { toastCustomOptions } from "view/common/commons";

export const domain = "@appigo-retailer/affiliate/updateCustomer"

export const updateCustomerReducer = networkStateReducer<CreateCustomerReq>(domain)

export function useUpdateCustomer(): [NetworkState<Customer>, (req: CreateCustomerReq) => void, () => void] {
    let dispatch = useDispatch();
    const networkState = useSelector<RootState, NetworkState<Customer>>(state => state.affiliate.updateCustomer)

    useEffect(() => {
        if (isSuccess(networkState)) {
            toast.success('Payment Info Update Successfully', {
                ...toastCustomOptions,
                toastId:"success affiliate"
            })
        } else if (isError(networkState)) {
            toast.error('Payment Info Update Failed', {
                ...toastCustomOptions,
                toastId:"error affiliate"
            })
        }
    }, [networkState])

    return [
        networkState,
        (req) => dispatch(post(domain, "/customer-api/v2/affiliate/customer", req)),
        () => dispatch(reset(domain))
    ]
}
