import {NetworkState, networkStateReducer, post, reset} from "../networkStateReducer";
import {Cart} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/cart/set/promoCode"

export const cartSetPromoCodeReducer = networkStateReducer<Cart>(domain)

export function useCartSetPromoCode(): [NetworkState<Cart>, (promoCode?: string) => void, () => void] {

    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<Cart>>(state => state.cart.setPromoCode),
        (promoCode) => dispatch(post(domain, "/customer-api/v2/cart/set/promoCode", {promoCode})),
        () => dispatch(reset(domain))
    ]
}
