import {NetworkState, networkStateReducer, post} from "../networkStateReducer";
import {CardOptions, Cart} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/cart/set/save-card-options"

export const cartSetCardOptionsReducer = networkStateReducer<Cart>(domain)

export function useCartSetUseSaveCard(): [NetworkState<Cart>, (useSavedCard: boolean) => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<Cart>>(state => state.cart.setPaymentMethod),
        (useSavedCard) => {
            let req: CardOptions = {
                useSavedCard
            };
            dispatch(post(domain, "/customer-api/v2/cart/set/save-card-options", req));
        }
    ]
}

export function useCartSetSaveCard(): [NetworkState<Cart>, (saveCard: boolean) => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<Cart>>(state => state.cart.setPaymentMethod),
        (saveCard) => {
            let req: CardOptions = {
                saveCard
            };
            dispatch(post(domain, "/customer-api/v2/cart/set/save-card-options", req));
        }
    ]
}

export function useCartSetCardId(): [NetworkState<Cart>, (cardId: string) => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<Cart>>(state => state.cart.setPaymentMethod),
        (cardId) => {
            let req: CardOptions = {
                cardId
            };
            dispatch(post(domain, "/customer-api/v2/cart/set/save-card-options", req));
        }
    ]
}
