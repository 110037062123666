import {
    get,
    NetworkState,
    networkStateReducer, post, reset,
} from "../networkStateReducer";
import {components, operations} from "../../__generated/api";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {Address, ProfileRes} from "./get";

export const domain = "@appigo-retailer/profile/update/address/remove"

export const profileRemoveAddressReducer = networkStateReducer<Address>(domain)


export function useProfileRemoveAddress(): [NetworkState<Address>, (addressId?: string) => void, () => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<Address>>(state => state.profile.removeAddress),
        (addressId) => dispatch(post(domain, "/customer-api/v2/profile/update/address/remove/{addressId}",{}, {path:{addressId: !!addressId ? addressId : undefined}})),
        () => dispatch(reset(domain))
    ];

}
