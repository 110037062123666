import {TrackingAdaptor, TrackingEvent} from "./trackingProvider";
import ReactGA from "react-ga4";
import {Ga4AnalyticsConfig} from "./types";


export interface ItemType {
    item_id?: string | null;
    item_name?: string | null;
    affiliation?: string | null;
    coupon?: string | null;
    discount?: number | null;
    index?: number | null;
    item_brand?: string | null;
    item_category?: string | null;
    item_category2?: string | null;
    item_category3?: string | null;
    item_category4?: string | null;
    item_category5?: string | null;
    item_list_id?: string | null;
    item_list_name?: string | null;
    item_variant?: string | null;
    location_id?: string | null;
    price?: number | null;
    quantity?: number | null;
}


export function googleAnalytics(googleAnalyticsConfig: Ga4AnalyticsConfig | undefined): TrackingAdaptor {

    if (!!googleAnalyticsConfig && googleAnalyticsConfig.isEnabled && !!googleAnalyticsConfig.gaTrackingId) {
        console.log("Initiate GA4 -", googleAnalyticsConfig)
        ReactGA.initialize([{trackingId: googleAnalyticsConfig.gaTrackingId}]);
        // !!apiKey && TagManager.initialize({gtmId:apiKey})
    }

    function sendGA4Event(
        eventName: string,
        eventData: { [p: string]: any }
    ) {
        console.log("React GA event --", eventName, "EventDATA", eventData)
        ReactGA.gtag("event", eventName, eventData);
    }

    function sendGA4PageView(page: string, title: string) {
        console.log("React GA pageview", page, title)
        ReactGA.send({hitType: "pageview", page: page, title: title});
    }


    return {
        track(trackingEvent: TrackingEvent) {
            console.log("Tracking Event: ", trackingEvent, "React GA Config: ", googleAnalyticsConfig)
            if (!!googleAnalyticsConfig && (!googleAnalyticsConfig.isEnabled || !googleAnalyticsConfig.isBuiltInTracking)) {
                console.log("React GA is disabled. Skipping tracking event: ", trackingEvent)
                return;
            }

            switch (trackingEvent.type) {
                case "PageView":
                    // ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });
                    sendGA4PageView(window.location.pathname + window.location.search, document.title)
                    // ReactGA.pageview(window.location.pathname);
                    break;
                case "Search":
                    // ReactGA.event({category: 'User', action: 'search'}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    sendGA4Event('search', trackingEvent.data)
                    break;
                case "CustomizeProduct":
                    // ReactGA.event({category: 'User', action: "customize_product"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    sendGA4Event("customize_product", trackingEvent.data)
                    break;
                case "AddToCart":
                    // ReactGA.event({category: 'User', action: "add_to_cart"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    sendGA4Event("add_to_cart", trackingEvent.data)
                    break;
                case "InitiateCheckout":
                    // ReactGA.event({category: 'User', action: "begin_checkout"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    sendGA4Event("begin_checkout", trackingEvent.data)
                    break;
                case "AddShippingDetails":
                    sendGA4Event("proceeded_to_shipping_details", trackingEvent.data)
                    // ReactGA.event({category: 'User', action: "proceeded_to_shipping_details"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    break;
                case "PaymentAndBilling":
                    // ReactGA.event({category: 'User', action: "proceeded_to_payment_and_billing"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    sendGA4Event("proceeded_to_payment_and_billing", Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]))
                    break;
                case "PromoApply":
                    sendGA4Event("promo_apply", trackingEvent.data)
                    // ReactGA.event({category: 'User', action: "promo_apply"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    break;
                case "PlaceOrderCOD":
                    sendGA4Event("place_order_cod", trackingEvent.data)
                    // ReactGA.event({category: 'User', action: "place_order_cod"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    break;
                case "PlaceOrderCard":
                    sendGA4Event("place_order_card", trackingEvent.data)
                    // ReactGA.event({category: 'User', action: "place_order_card"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    break;
                case "IPGFailure":
                    // ReactGA.event({category: 'User', action: "ipg_failure"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    sendGA4Event("ipg_failure", trackingEvent.data)
                    break;
                case "ViewContent":
                    sendGA4Event("view_item", trackingEvent.data)
                    break;
                case "PaymentCompleteCOD":
                    // ReactGA.event({category: 'User', action: "purchase"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    sendGA4Event("payment_success_cod", trackingEvent.data)
                    break;
                case "PaymentComplete":
                    sendGA4Event("payment_success", trackingEvent.data)
                    // ReactGA.event({category: 'User', action: "purchase"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    break;
                case "Purchase-GA":
                    // ReactGA.event({category: 'User', action: "purchase"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    sendGA4Event("purchase", trackingEvent.data)
                    break;
            }

        }
    }

}
