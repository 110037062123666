import {combineReducers} from "redux";
import {cartAddReducer} from "./add";
import {cartGetReducer} from "./get";
import {cartRemoveReducer} from "./remove";
import {cartUpdateReducer} from "./update";
import {cartSetEmailReducer} from "./setEmail";
import {cartSetPaymentMethodReducer} from "./setPaymentMethod";
import {cartSetOutletReducer} from "./setOutlet";
import {cartSetBillingAddressReducer} from "./setBillingAddress";
import {cartSetCardOptionsReducer} from "./saveCardOptions";
import {cartSetShippingAddressReducer} from "./setShippingAddress";
import {cartSetDeliveryMethodReducer} from "./setDeliveryMethod";
import {NetworkState, NetworkStateFamily} from "../networkStateReducer";
import {Cart, DeliveryPlan} from "./types";
import {getDeliveryPlansReducer} from "./getDeliveryPlans";
import {cartSetDeliveryPlanReducer} from "./setDeliveryPlan";
import {cartSetPromoCodeReducer} from "./setPromoCode";
import {validateOrderReducer} from "./validateOrder";
import {cartSetIsBillingSameAsShipping} from "./setIsBillingSameAsShipping";
import {cartSetShippingAddressGeoCoordinatesReducer} from "./setGeoCoordinates";
import {cartSetInventoryLocationReducer} from "./setInventoryLocation";
import {clearCartReducer} from "./clearCart";
import {cartSetOrderDueDateReducer} from "./setSelectedOrderDueDate";
import {cartSetSelectedCurrencyReducer} from "./setSelectedCurrency";
import {cartSetStorePickupDueDateReducer} from "./setStorePickupDueDate";
import {additionalInfoReducer} from "./setAdditionalInfo";
import { fileUploadReducer } from "./fileUpload";
import { setFileReducer } from "./setFiles";

export interface CartState {
    add: NetworkStateFamily<Cart>
    get: NetworkStateFamily<Cart>
    remove: NetworkStateFamily<Cart>
    update: NetworkStateFamily<Cart>
    setEmail: NetworkState<Cart>
    setAdditionalInfo: NetworkState<Cart>
    setPromoCode: NetworkState<Cart>
    setBillingAddress: NetworkState<Cart>
    setShippingAddress: NetworkState<Cart>
    setGeoCodeToShippingAddress: NetworkState<Cart>
    setOutlet: NetworkState<Cart>
    setPaymentMethod: NetworkState<Cart>
    setCardOptionsReducer: NetworkState<Cart>
    setDeliveryMethod: NetworkState<Cart>,
    deliveryPlans: NetworkState<DeliveryPlan[]>
    deliveryPlan: NetworkState<Cart>
    validateCart: NetworkState<Cart>
    setIsBillingSameAsShipping: NetworkState<Cart>
    setInventoryLocation: NetworkState<Cart>
    setSelectedOrderDueDate: NetworkState<Cart>
    clearCart: NetworkState<Cart>,
    setSelectedCurrency: NetworkState<Cart>,
    setStorePickupDueDate:NetworkState<Cart>,
    fileUpload: NetworkStateFamily<string[]>,
    setFile: NetworkState<Cart>
}

export const cartReducer = combineReducers<CartState>({
    add: cartAddReducer,
    get: cartGetReducer,
    remove: cartRemoveReducer,
    update: cartUpdateReducer,
    setEmail: cartSetEmailReducer,
    setAdditionalInfo: additionalInfoReducer,
    setPromoCode: cartSetPromoCodeReducer,
    setPaymentMethod: cartSetPaymentMethodReducer,
    setOutlet: cartSetOutletReducer,
    setBillingAddress: cartSetBillingAddressReducer,
    setCardOptionsReducer: cartSetCardOptionsReducer,
    setShippingAddress: cartSetShippingAddressReducer,
    setGeoCodeToShippingAddress: cartSetShippingAddressGeoCoordinatesReducer,
    setDeliveryMethod: cartSetDeliveryMethodReducer,
    deliveryPlans: getDeliveryPlansReducer,
    deliveryPlan: cartSetDeliveryPlanReducer,
    validateCart: validateOrderReducer,
    setIsBillingSameAsShipping: cartSetIsBillingSameAsShipping,
    setInventoryLocation: cartSetInventoryLocationReducer,
    clearCart: clearCartReducer,
    setSelectedOrderDueDate: cartSetOrderDueDateReducer,
    setSelectedCurrency: cartSetSelectedCurrencyReducer,
    setStorePickupDueDate: cartSetStorePickupDueDateReducer,
    fileUpload: fileUploadReducer,
    setFile: setFileReducer
})
