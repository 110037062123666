import i18n, {PostProcessorModule, Resource, TFunction, TOptions} from "i18next";
import {initReactI18next} from "react-i18next";
import intervalPlural from 'i18next-intervalplural-postprocessor';
import {default as messages_en} from './messages_en.json'
import {default as messages_si} from './messages_si.json'
import {default as messages_ta} from './messages_ta.json'
import Backend from 'i18next-http-backend'
import {DateTime} from "luxon";
import {baseName} from "../connectors";

export interface I18nParameters {
    currencyCode?: string,
    language?: string
}

const resources: Resource = {
    en: { translation: messages_en },
    si: { translation: messages_si },
    ta: { translation: messages_ta }
};

const processModule: PostProcessorModule = {
    type: "postProcessor",
    name: 'appigoPostProcess',
    process(value: string, key: string, options: TOptions, translator: any): string {
        console.log(value, key, options, translator)
        return value
    }
}

export function configureI18n({currencyCode, language}: I18nParameters) {

    i18n
        .use(Backend)
        .use(initReactI18next)
        .use(intervalPlural)
        // .use(processModule)
        .init({
            lng: language ?? "en",
            // resources,
            fallbackLng: 'en',
            keySeparator: ".",
            saveMissing: true,
            saveMissingTo: "all",
            missingKeyHandler(lng, ns, key, fallbackValue) {
                console.info(`Replace missing key: lng=[${lng}] ns=[${ns}] key=[${key}] fallback=[${fallbackValue}]`)
                i18n.services.backendConnector.saveMissing(
                    lng, ns, key, fallbackValue
                )
            },
            interpolation: {
                escapeValue: false,
                alwaysFormat: true,
                formatSeparator: "|",
                format(value, format, lng) {
                    let currency = currencyCode?.split("|")[0]
                    let currencyFormat = new Intl.NumberFormat(lng, {
                        style: 'currency',
                        currency: currency?.length ? currency : 'LKR',
                        currencyDisplay: "symbol",
                        minimumFractionDigits: 2
                    });
                    switch (format) {
                        case 'currency':
                            try {
                                return typeof value == "object" ? currencyFormat.format(parseFloat(!!currency && !!value[currency] ? value[currency] : 0)) : currencyFormat.format(parseFloat(value))
                            } catch (e) {
                                return value
                            }
                        case 'percent':
                            return typeof value == "object" ? new Intl.NumberFormat(lng, {style: 'percent'}).format(parseFloat(!!currency && !!value[currency] ? value[currency] : 0)) : new Intl.NumberFormat(lng, {style: 'percent'}).format(parseFloat(value))
                        case "date":
                            return !!value ? DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT as any) : value
                        case 'variances':
                            return Object.values(value ?? {}).join(',')
                        case 'currencyType':
                            return currencyFormat.formatToParts(0).find(({type}) => type === 'currency')?.value ?? ''
                    }
                    if (typeof value === "object" && !!value?.en) {
                        return value[lng ?? 'en'] ?? value.en
                    } else if (typeof value === "object") {
                        return ""
                    }

                    return value
                }
            },
            backend: {
                loadPath: `/i18n/${baseName()}/locale/{{lng}}.json`,
                addPath: `/i18n/${baseName()}/missing/{{lng}}`,
                crossDomain: false
            }
        });
}
