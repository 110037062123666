import {NetworkState, networkStateReducer, post, reset} from "../networkStateReducer";
import {Cart} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/cart/set-is-billing-same-as-shipping"

export const cartSetIsBillingSameAsShipping = networkStateReducer<Cart>(domain)

export function useSetIsBillingSameAsShippingToCart(): [NetworkState<Cart>, (isBillingSameAsShipping: String) => void, () => void] {

    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<Cart>>(state => state.cart.setIsBillingSameAsShipping),
        (isBillingSameAsShipping) => dispatch(post(domain, "/customer-api/v2/cart/set/is-billing-same-as-shipping", isBillingSameAsShipping)),
        () => dispatch(reset(domain))
    ]
}