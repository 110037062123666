import {get, NetworkState, networkStateReducer, post} from "../networkStateReducer";
import {Cart} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {Outlet} from "../outlet";

export const domain = "@appigo-retailer/cart/set/outlet"

export const cartSetOutletReducer = networkStateReducer<Cart>(domain)

export function useCartSetOutlet(): [NetworkState<Cart>, (outlet: Outlet) => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<Cart>>(state => state.cart.setOutlet),
        (outlet) => dispatch(post(domain, "/customer-api/v2/cart/set/store", outlet))
    ]
}
