import {
    get, getFamily,
    NetworkState, NetworkStateFamily,
    networkStateReducer, networkStateReducerFamily, post, reset, resetFamily, resolveFamily,
} from "../networkStateReducer";

import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import { BanksResult } from ".";


export const domain = "@appigo-retailer/affiliate/getBankList"

export const getBankListReducer = networkStateReducerFamily<BanksResult>(domain)

export function useGetBankList (family: string = 'Default') : [NetworkState<BanksResult>, () => void,() => void] {
    let dispatch = useDispatch();

    return [
        resolveFamily(family, useSelector<RootState, NetworkStateFamily<BanksResult>>(state => state.affiliate.getBankList)),
        () => dispatch(getFamily(domain, "/customer-api/v2/affiliate/banks", family)),
        () => dispatch(resetFamily(domain, family))
    ]
}