import {
    get,
    NetworkState,
    networkStateReducer,
} from "../networkStateReducer";
import {components, operations} from "../../__generated/api";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/profile/get"

export const profileGetReducer = networkStateReducer<ProfileRes>(domain)

export type ProfileRes = components["schemas"]["ConsumerProfile"]
export type Address = components["schemas"]["Address"]

export function useProfileGet(): [NetworkState<ProfileRes>, () => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<ProfileRes>>(state => state.profile.get),
        () => dispatch(get(domain, "/customer-api/v2/profile/get"))
    ];

}
