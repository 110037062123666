import {
    get,
    NetworkState,
    networkStateReducer, post,
} from "../networkStateReducer";
import {components, operations} from "../../__generated/api";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {ProfileRes} from "./get";
import {ConsumerProfileReq} from "./updateBillingAddress";

export const domain = "@appigo-retailer/profile/update"

export const profileUpdateConsumerProfile= networkStateReducer<ConsumerProfileReq>(domain)

export function useConsumerProfileUpdate(): [NetworkState<ProfileRes>, (profile: ConsumerProfileReq) => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<ProfileRes>>(state => state.profile.updateProfile),
        (profile) => dispatch(post(domain, "/customer-api/v2/profile/update/profile", profile))
    ];

}
