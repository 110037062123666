import React from 'react';
import {Link} from "react-router-dom";

export default function ProductUnavailable() {

    return (
        <main className="flex-shrink-0">
            <div className="main-section pt-0">
                <div className="container">
                    <div className="card card-body border-0 mt4 mb-4 text-center">
                        <div className="row">
                            <div className="col-sm-2 offset-sm-5 col-4 offset-4">
                                <img src="/merchant-resources/empty-image.png" className="w-100"/>
                            </div>
                        </div>
                        <h4>Product no longer available</h4>
                        <div className="row justify-content-center my-3">
                            <div className="col-sm-3">
                                <Link to={"/"}><button className="btn btn-primary w-100">Go Back</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
