import {NetworkState, networkStateReducer, post} from "../networkStateReducer";
import {Cart, FileSchema} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/cart/set/files"

export const setFileReducer = networkStateReducer<Cart>(domain)

export function useCartFile(): [NetworkState<Cart>, (files: FileSchema[]) => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<Cart>>(state => state.cart.setFile),
        (files) => dispatch(post(domain, "/customer-api/v2/cart/set/file", files))
    ]
}
