import {get, NetworkState, networkStateReducer} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {ManagedCard} from "./index";

export const domain = "@appigo-retailer/managed-card-list"

export const managedCardListReducer = networkStateReducer<ManagedCard[]>(domain)

export function useCardList(): [NetworkState<ManagedCard[]>, () => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<ManagedCard[]>>(state => state.managedCards.list),
        () => dispatch(get(domain, "/customer-api/v2/card/list"))
    ]
}
