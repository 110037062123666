import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {components} from "../../../../__generated/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {definition as faCaretDown} from "@fortawesome/free-solid-svg-icons/faCaretDown";
import {toLocation} from "../../Body/Home/Banner/Banner";
import {useSchema} from "../../../../reducers/schema";
import {isSuccess} from "../../../../reducers/networkStateReducer";
import {useI18n, WithPlaceholders} from "../../../../i18n/I18nSupport";
import {RelatedItems} from "./RelatedItems/RelatedItems";
import {SubMenus} from "./SubMenus";
import {parseQueryParams, useSearch} from "../../../../reducers/product/search";
import useResizeObserver from "use-resize-observer/polyfilled";
import {MoreMenuItems} from "./MoreMenu/MoreMenuItems";

export type Menu = components["schemas"]["Menu"]

export type MenuWithItems = Menu & {
    menuItems: Menu[]
}

export function isMenuWithItems(menu: Menu): menu is MenuWithItems {
    return !!(menu as MenuWithItems).menuItems
}

interface Menus {
    categoryItems: Menu[],
    containerWidth: number,
    containerHeight: number
}

interface MultiMenuContentProps {
    menu: Menu,
    open: boolean,
    doOpen(): void,
    menuAlignment?: string,
    setCumulativeWidth(height: number): void,
    cumulativeWidth: number,
    containerWidth?: number
}

function MultiMenuContent({
                              menu,
                              open,
                              doOpen,
                              menuAlignment,
                              setCumulativeWidth,
                              cumulativeWidth,
                              containerWidth = window.innerWidth
                          }: MultiMenuContentProps) {

    let {push} = useHistory()

    let {tx} = useI18n("header.menu");
    const [result, search] = useSearch(`related-${menu.link}`);
    const {ref,} = useResizeObserver<HTMLLIElement>({
        onResize: size => {
            let sum = !!size.width ? size.width + cumulativeWidth : cumulativeWidth;
            setCumulativeWidth(sum)
        }
    });
    const [showMenu, setShowMenu] = useState<boolean>(true);

    useEffect(() => {
        if (!isSuccess(result)) {
            const searchReq = parseQueryParams(menu.link);
            search({...searchReq, first: 8, after: 0, source: "related"});
        }
    }, [showMenu])

    return <WithPlaceholders value={{menu}}>
        <li key={menu.link} className={`d-inline dropdown ${open ? 'show' : ''}`} ref={ref}>
            <button className="btn dropdown-toggle px-3 appigo-header-label" type="button" id="dropdownMenuButton1"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    onMouseOver={() => {
                        setShowMenu(true)
                    }}
                    onClick={(e) => {
                        setShowMenu(false)
                        push(menu.link)
                    }}>
                {tx`name`} <FontAwesomeIcon icon={faCaretDown} size={"sm"}/>
            </button>

            {showMenu && <div
                className={`appigo-sub-menu dropdown-menu w-100`}
                style={{maxWidth: containerWidth, margin: 'auto'}}
                aria-labelledby="dropdownMenuButton1">
                <div className="container">
                    <div className="menu-content">
                        <div className="row">
                            <SubMenus menu={menu} setShowMenu={setShowMenu}/>
                            {isSuccess(result) && result?.data?.edges?.length &&
                                <RelatedItems products={result.data} menu={menu} setShowMenu={setShowMenu}/>}
                        </div>
                    </div>
                </div>
            </div>}
        </li>
    </WithPlaceholders>;
}

interface SingleMenuContentProps {
    menu: Menu,

    setCumulativeWidth(height: number): void,

    cumulativeWidth: number
}

function SingleMenuContent({menu, cumulativeWidth, setCumulativeWidth}: SingleMenuContentProps) {
    let {tx} = useI18n("header.menu");
    let {push} = useHistory()

    const {ref,} = useResizeObserver<HTMLLIElement>({
        onResize: size => {
            let sum = !!size.width ? size.width + cumulativeWidth : cumulativeWidth;
            setCumulativeWidth(sum)
        }
    });

    return <WithPlaceholders value={{menu}}>
        <li key={menu.link} className={`d-inline`} ref={ref}>
            <button className="btn nav-item px-3 appigo-header-label" type="button" id="dropdownMenuButton1"
                    onClick={(e) => {
                        push(menu.link)
                    }}>
                {tx`name`}
            </button>
        </li>
    </WithPlaceholders>
}

interface MoreMenuContentProps {
    menus: Menu[],
    containerWidth?: number
}

function MoreMenuContent({menus, containerWidth=window.innerWidth}: MoreMenuContentProps) {

    let {tx} = useI18n("header.menu");
    const [showMenu, setShowMenu] = useState<boolean>(true);

    return <li className={`d-inline dropdown`}>
        <button className="btn dropdown-toggle px-3 appigo-header-label" type="button" id="dropdownMenuButton4"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onMouseOver={() => setShowMenu(true)}>
            {tx`moreMenuLabel|More `}<FontAwesomeIcon icon={faCaretDown} size={"sm"}/>
        </button>
        {showMenu && <MoreMenuItems menus={menus} setShowMenu={setShowMenu} containerWidth={containerWidth}/>}
    </li>
}

export function NavMenuItems({categoryItems, containerWidth}: Menus) {

    let [opened, setOpened] = useState<string | undefined>();

    const [schema] = useSchema()
    const [cumulativeWidth, setCumulativeWidth] = useState<number>(0);
    const [index, setIndex] = useState<number>(0);
    let desirableWidth = Math.ceil(containerWidth * 0.75);

    useLayoutEffect(() => {
        if (desirableWidth > cumulativeWidth) {
            setIndex(index + 1)
        }
    }, [cumulativeWidth])

    if (!isSuccess(schema)) return null

    return (
        <div className="row no-gutters justify-content-between">
            <div className="menu-list">
                <ul className="d-flex m-0 p-0" style={{
                    listStyle: "none",
                    justifyContent: toLocation(schema.data.menuAlignment),
                }}>
                    {categoryItems.filter((item, key) => key < 3).map((menu, key) => {
                        if (isMenuWithItems(menu) && (menu.menuItems?.length ?? 0 > 1)) {
                            return <MultiMenuContent key={key}
                                                     menu={menu}
                                                     open={menu.name?.en === opened}
                                                     doOpen={() => {
                                                         setOpened(menu.name?.en === opened ? undefined : menu.name?.en)
                                                     }} menuAlignment={toLocation(schema.data.menuAlignment)}
                                                     setCumulativeWidth={setCumulativeWidth}
                                                     cumulativeWidth={cumulativeWidth}
                                                     containerWidth={containerWidth}/>
                        }
                        else return <SingleMenuContent menu={menu} key={key}  setCumulativeWidth={setCumulativeWidth}
                                                         cumulativeWidth={cumulativeWidth}/>
                    })}

                    {/*{categoryItems.some((item, key) => key === index) &&*/}
                    {/*    <MoreMenuContent menus={categoryItems.filter((item, key) => key >= index)} containerWidth={containerWidth}/>*/}
                    {/*}*/}
                </ul>
            </div>
        </div>
    );
}

