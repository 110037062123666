import axios from 'axios'
import {baseName, baseUrl} from "./index";
import {RootState} from "../reducers";
import axiosMiddleware from "redux-axios-middleware";
import {keycloak} from "../auth/AuthProvider";
import {setRemoteErrorState} from "../reducers/ui/errorStatus";


export const axiosInstance = axios.create({
    baseURL: baseUrl,
    responseType: "json"
})

const middlewareConfig = {
    interceptors: {
        request: [
            {
                success: function ({ getState, dispatch, getSourceAction }:any, req:any) {
                    console.log(req.baseURL, baseName(), `${req.baseURL}/${baseName()}`)
                    const state: RootState = getState();
                    req.baseURL = `${req.baseURL}/${baseName()}`
                    req.headers = { ...req.headers, 'Content-Type': 'application/json'}
                    let token = state.token.token;
                    let inventory = state.inventory.inventoryId;
                    req.headers = { ...req.headers, 'Authorization': !!token ? `Bearer ${token}` : null}
                    req.headers = { ...req.headers, 'X-INVENTORY-LOCATION': !!inventory ? `${inventory}` : null}
                    return req;
                }
            }
        ],
        response: [
            {
                success: function ({ getState, dispatch, getSourceAction }:any, res:any) {
                    let remoteErrorState = setRemoteErrorState({});
                    if(JSON.stringify(getState()?.uiState?.remoteErrorStatus) !== JSON.stringify(remoteErrorState)) {
                        dispatch(remoteErrorState)
                    }
                    return Promise.resolve(res)
                },
                error: function ({ getState, dispatch, getSourceAction }:any, res:any) {
                    if (res.response.status === 401) {
                        keycloak.login()
                    } else if (res.response.status === 503 && res.response.data?.statusCode === 'E2003') {
                        dispatch(setRemoteErrorState({
                            errorCode: res.response.data?.statusCode,
                            errorDescription: res.response.data?.statusDescription,
                            httpStatus: res.response.status
                        }))
                    }
                    return Promise.reject(res)
                }
            }
        ]
    },
};

export const backendConnectorMiddleware = axiosMiddleware(
    axiosInstance,
    middlewareConfig
)
