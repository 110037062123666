import {isError, isSuccess, NetworkState, networkStateReducer, put, reset} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import { AffiliateLink, AffiliateLinkRequest } from ".";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { toastCustomOptions } from "view/common/commons";

export const domain = "@appigo-retailer/affiliate/updateAffiliateLink"

export const updateAffiliateLinkReducer = networkStateReducer<AffiliateLinkRequest>(domain)

export function useUpdateAffiliateLink(): [NetworkState<AffiliateLink>, (affiliate_token: String, req: AffiliateLinkRequest) => void, () => void] {
    let dispatch = useDispatch();
    const networkState = useSelector<RootState, NetworkState<AffiliateLink>>(state => state.affiliate.updateAffiliateLink)

    useEffect(() => {
        if (isSuccess(networkState)) {
            toast.success('Affiliate Link Update Successfully', {
                ...toastCustomOptions,
                toastId:"success affiliate"
            })
        } else if (isError(networkState)) {
            toast.error('Affiliate Link Update Failed', {
                ...toastCustomOptions,
                toastId:"error affiliate"
            })
        }
    }, [networkState])

    return [
        networkState,
        (affiliate_token, req) => dispatch(put(domain, "/customer-api/v2/affiliate/{affiliate_token}", req, { path: {affiliate_token}})),
        () => dispatch(reset(domain))
    ]
}
