import {get, NetworkState, networkStateReducer, post} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {OrderDetails} from "./index";
import {Cart} from "../cart/types";

export const domain = "@appigo-retailer/order-details"

export const orderReducer = networkStateReducer<OrderDetails>(domain)

export function useOrder(): [NetworkState<Cart>, (orderId: string) => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<OrderDetails>>(state => state.order.current),
        (orderId) => dispatch(get(domain, "/customer-api/v2/order/get/{orderId}", {path: {orderId}}))
    ]
}
