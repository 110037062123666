import { useEffect, useState } from "react";

export interface DeBounceStateProps<T> {
    init?: T,
    triggerOnInitValue?: boolean
}

export function useDebouncedState<T>(wait: number, init: T, triggerOnInitValue: boolean = true): [T, (value: T) => void, (T | undefined)] {
    let [value, setValue] = useState<T>(init);
    let [debouncedValue, setDebouncedValue] = useState<T | undefined>(triggerOnInitValue ? init : undefined);

    useEffect(() => {
        console.log("Debounced state", debouncedValue)
        let timeout = setTimeout(() => {
            setDebouncedValue(value)
        }, wait);
        console.log(timeout)
        return () => clearTimeout(timeout)
    }, [value])

    return [
        value,
        setValue,
        debouncedValue
    ]
}

export function compareObject(o1: any, o2: any): boolean {
    if (!o1 || !o2) return false;

    for (var p in o1) {
        if (o1.hasOwnProperty(p)) {
            if (typeof o1[p] != "object" && typeof o2[p] != "object" && o1[p] !== o2[p]) {
                return false;
            }
        }
    }
    for (var p in o2) {
        if (o2.hasOwnProperty(p)) {
            if (typeof o1[p] != "object" && typeof o2[p] != "object" && o1[p] !== o2[p]) {
                return false;
            }
        }
    }
    return true;
};