import { NetworkState, networkStateReducer, get, reset, resolveFamily } from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {Cart} from "./types";

export const domain = "@appigo-retailer/cart/validateOrder"

export const validateOrderReducer = networkStateReducer<Cart>(domain)

/**
 *
 * Returns a stateful value confirms to NetworkState, a function to issue network requests and clean up function.
 * Upon function execution, a <code>get</code> call will be issued to <code>/customer-api/v2/cart/validate</code>
 *
 * @param {string} entityKey  unique id to manage different entities as network states.
 * @example
 * ...
 * function ValidateOrderExample() {
 *     const [validateOrderState, validateOrder, cleanValidateOrderState] = useValidateOrder()
 *
 *     //To cleanup the entity upon unload
 *     useEffect(() => cleanValidateOrderState);
 *
 *     //Callback function
 *     function onValidateOrder(params: ValidateOrder_Params) {
 *       validateOrder(params)
 *     }
 *
 *     //Do on pending
 *     if (isPending(validateOrderState)) {
 *         return <>pending</>
 *     }
 *
 *     //Do on success
 *     if (isSuccess(validateOrderState)) {
 *         return <>{JSON.stringify(validateOrderState.data)}</>
 *     }
 *
 *     //Do on error
 *     if (isError(validateOrderState)) {
 *         return <>An error occurred {JSON.stringify(validateOrderState.error)}</>
 *     }
 * }
 */
export function useValidateOrder(): [NetworkState<Cart>,() => void, () => void] {
    let dispatch = useDispatch();

    /**
     * Upon function execution, a <code>get</code> call will be issued to <code>/customer-api/v2/cart/validate</code>
     *
     */
    function execute() {
        let parameters = {
        }

        dispatch(get(domain, "/customer-api/v2/cart/validate", parameters))
    }

    return [
        useSelector<RootState, NetworkState<Cart>>(state => state.cart.validateCart),
        execute,
        () => dispatch(reset(domain))
    ]
}
