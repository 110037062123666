import {ToastOptions} from 'react-toastify'

export const toastCustomOptions: ToastOptions = {
    position: "top-right",
    closeOnClick: true,
    closeButton: true,
    autoClose: 2000,
    hideProgressBar: false,
    pauseOnHover: true,
    bodyStyle: {
        textAlign: "center",
        fontSize: "1.2rem"
    },
    draggable: true,
    progress: undefined,
}

export  const modalBackgroundStyle = {
    display: "block",
    background: "rgba(17,15,15,0.5)",
    transition: "50ms ease-in",
}