import {get, NetworkState, networkStateReducer} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/refresh"

export const refreshReducer = networkStateReducer<string>(domain)

export function useRefreshCallback(): [NetworkState<string>, ()=> void] {
    const dispatch = useDispatch();
    return [
        useSelector<RootState, NetworkState<string>>(state => state.user.refreshState),
        () => dispatch(get(domain, "/customer-api/v2/user/refresh"))
    ]
}
