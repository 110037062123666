import {NetworkState, networkStateReducer, post} from "../networkStateReducer";
import {Cart} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/cart/set/additionalInfo"

export const additionalInfoReducer = networkStateReducer<Cart>(domain)

export function useCartSetAdditionalInfo(): [NetworkState<Cart>, (additionalInfo: string) => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<Cart>>(state => state.cart.setAdditionalInfo),
        (msg) => dispatch(post(domain, "/customer-api/v2/cart/set/additional-info", msg))
    ]
}
