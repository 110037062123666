import {components} from "../../__generated/api";
import {NetworkState, NetworkStateFamily} from "../networkStateReducer";
import {ManagedPage} from "../pages/types";
import {combineReducers} from "redux";
import {findReducer} from "../pages/findPage";
import {PageState} from "../pages";
import {getBankListReducer} from "./getBankList";
import { getAllAffiliateLinksReducer } from "./getAllAffiliateLinks";
import { getCustomerReducer } from "./getCustomer";
import { createAffiliateLinkReducer } from "./createAffiliateLink";
import { updateCustomerReducer } from "./updateCustomer";
import { updateAffiliateLinkReducer } from "./updateAffiliateLink";
import { getAffiliateServiceStausReducer } from "./getAffiliateServiceStaus";
import { getCustomerStatisticsReducer } from "./getCustomerStatistics";

export type AffiliateLinkRequest = components["schemas"]["AffiliateLinkRequest"]
export type AffiliateLink = components["schemas"]["AffiliateLink"]
export type CustomerReport = components["schemas"]["CustomerReport"]
export type BanksResult = components["schemas"]["BanksResult"]
export type Customer = components["schemas"]["Customer"]
export type CreateCustomerReq = components["schemas"]["CreateCustomerReq"]
export type CustomerReportResult = components["schemas"]["CustomerReportResult"]
export type Statistics = components["schemas"]["Statistics"]

export interface Affiliate {
    getBankList: NetworkStateFamily<BanksResult>
    getAllAffiliateLinks: NetworkStateFamily<CustomerReport>
    getCustomer: NetworkStateFamily<Customer>,
    createAffiliateLink: NetworkState<AffiliateLink>,
    updateCustomer: NetworkState<CreateCustomerReq>,
    updateAffiliateLink: NetworkState<AffiliateLinkRequest>,
    getAffiliateServiceStaus: NetworkStateFamily<Boolean>,
    getCustomerStatistics: NetworkStateFamily<Statistics>
}

export const affiliateReducer = combineReducers<Affiliate>({
    getBankList: getBankListReducer,
    getAllAffiliateLinks: getAllAffiliateLinksReducer,
    getCustomer: getCustomerReducer,
    createAffiliateLink: createAffiliateLinkReducer,
    updateCustomer: updateCustomerReducer,
    updateAffiliateLink: updateAffiliateLinkReducer,
    getAffiliateServiceStaus: getAffiliateServiceStausReducer,
    getCustomerStatistics: getCustomerStatisticsReducer
})


