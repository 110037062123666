import { Action, Reducer } from "redux";
import { useDispatch, useSelector } from 'react-redux';
import {RootState} from "../index";


// Reducer

export interface AddressSectionDetails {
    id?: string;
    city?: string;
    district?: string;
    province?: string;
    village?: string;
}

export interface AddressSectionState {
    addressSectionDetails: AddressSectionDetails;
}

interface SetAddressSectionDetailsAction extends Action {
    type: "@appigo-retailer-merchant-ui/set-address-section-details";
    payload: AddressSectionDetails;
}

function isSetAddressSectionDetailsAction(action: Action): action is SetAddressSectionDetailsAction {
    return action.type === "@appigo-retailer-merchant-ui/set-address-section-details";
}

function setAddressSectionDetailsAction(details: AddressSectionDetails): SetAddressSectionDetailsAction {
    return {
        type: "@appigo-retailer-merchant-ui/set-address-section-details",
        payload: details,
    };
}

export const addressSectionReducer: Reducer<AddressSectionState, SetAddressSectionDetailsAction> = (
    state = { addressSectionDetails: {} },
    action
) =>
    isSetAddressSectionDetailsAction(action)
        ? { addressSectionDetails: { ...state.addressSectionDetails, ...action.payload } }
        : state;

// Custom Hook

export function useCityInfo(): [AddressSectionDetails, (details: AddressSectionDetails) => void] {
    const dispatch = useDispatch();
    const addressSectionDetails = useSelector<RootState, AddressSectionDetails>(
        state => state.uiState.addressDetailsState.addressSectionDetails
    );

    const setAddressSectionDetails = (details: AddressSectionDetails) => {
        dispatch(setAddressSectionDetailsAction(details));
    };

    return [addressSectionDetails, setAddressSectionDetails];
}
