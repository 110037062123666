import {
    get, getFamily,
    NetworkState, NetworkStateFamily,
    networkStateReducer, networkStateReducerFamily, post, reset, resetFamily, resolveFamily,
} from "../networkStateReducer";

import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import { CustomerReportResult } from ".";


export const domain = "@appigo-retailer/affiliate/get-all-affiliate-links"

export const getAllAffiliateLinksReducer = networkStateReducerFamily<CustomerReportResult>(domain)

export function useGetAllAffiliateLinks (family: string = "Default") : [NetworkState<CustomerReportResult>, (size?: number, page?: number) => void,() => void] {
    let dispatch = useDispatch();

    return [
        resolveFamily(family, useSelector<RootState, NetworkStateFamily<CustomerReportResult>>(state => state.affiliate.getAllAffiliateLinks)),
        (size, page) => dispatch(getFamily(domain, "/customer-api/v2/affiliate/report", family, {query: {...(size ? {size} : {}), ...(page ? {page} : {})}})),
        () => dispatch(resetFamily(domain, family))
    ]
}