import {NetworkState, networkStateReducer, post, reset,} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {Address} from "./get";

export const domain = "@appigo-retailer/profile/address/save-address"

export const saveAddressReducer = networkStateReducer<Address>(domain)

export function useSaveAddress(): [NetworkState<Address>, (address: Address) => void, () => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<Address>>(state => state.profile.saveAddress),
        (address) => {
            dispatch(post(domain, "/customer-api/v2/profile/update/address", address));
        },
        () => dispatch(reset(domain))
    ]
}
