import {Action, Reducer} from "redux";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export interface RemoteErrorStatus {
    httpStatus?: number
    errorCode?: string
    errorDescription?: string
}

interface SetRemoteErrorStatus extends Action {
    type: "@appigo-retailer-ui/remote-error-status"
    httpStatus?: number
    errorCode?: string
    errorDescription?: string
}

function isRemoteErrorStatus(action: Action): action is SetRemoteErrorStatus {
    return action.type === "@appigo-retailer-ui/remote-error-status"
}

export function setRemoteErrorState(status: RemoteErrorStatus): SetRemoteErrorStatus {
    return {
        ...status,
        type: "@appigo-retailer-ui/remote-error-status"
    }
}

export const errorStatusReducer: Reducer<RemoteErrorStatus, SetRemoteErrorStatus> = (state = {  }, action) =>
    isRemoteErrorStatus(action) ? {...action} : state

export function useErrorStatus(): [RemoteErrorStatus, (status: SetRemoteErrorStatus) => void] {
    const dispatch = useDispatch();
    return [
        useSelector<RootState, RemoteErrorStatus>(state => state.uiState.remoteErrorStatus),
        status => dispatch(setRemoteErrorState(status))
    ]
}
