import React, {useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {definition as faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {definition as faPhoneAlt} from "@fortawesome/free-solid-svg-icons/faPhoneAlt";
import {definition as faFacebook} from "@fortawesome/free-brands-svg-icons/faFacebook";
import {definition as faInstagram} from "@fortawesome/free-brands-svg-icons/faInstagram";
import {definition as faTwitter} from "@fortawesome/free-brands-svg-icons/faTwitter";
import {useSchema} from "../../../reducers/schema";
import {isSuccess} from "../../../reducers/networkStateReducer";
import {DateTime} from "luxon";
import {useI18n, WithPlaceholders} from "../../../i18n/I18nSupport";
import {Link} from "react-router-dom";
import {useFindPageById} from "../../../reducers/pages/findPage";
import {faYoutube} from "@fortawesome/free-brands-svg-icons/faYoutube";
import {faTiktok} from "@fortawesome/free-brands-svg-icons/faTiktok";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons/faWhatsapp";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons/faLinkedin";

export function mediaToIcon(media: string) {
    switch (media) {
        case "facebook": return faFacebook
        case "instagram": return faInstagram
        case "twitter": return faTwitter
        case "youtube": return faYoutube
        case "tikTok": return faTiktok
        case "whatsApp": return faWhatsapp
        case "linkedIn": return faLinkedin
        default: return faEnvelope
    }
}

export interface PageLinkProps{
    pageId : string
}

export interface PageLinkObjectProps{
    pageId: string
    pageTitle?: { [key: string]: string }
}
export function PageLink({pageId}: PageLinkProps) {

    const [page, findPage, reset] = useFindPageById(pageId);
    const {tx,localized} = useI18n("footer.pageLink");

    useEffect(() => {
        return reset
    }, [])

    useEffect(() => {
        findPage(pageId)
    },[pageId])

    if (!isSuccess(page)) return <></>

    let title = page.data.title

    return <WithPlaceholders value={{title}}>
        <li className="d-flex flex-column mt-1">
            <Link to={`/information/page/${page.data.id}`} style={{textDecoration:"none"}}> <span className="btn-text__text footer_btn-text footer_btn-text-default"> {tx`title|{{title}}`} </span></Link>
        </li>

    </WithPlaceholders>

}
export default function Footer() {

    let [schema]  = useSchema()
    const {tx, ts} = useI18n("footer")

    if(!isSuccess(schema)) return null

    let footer = schema.data.footer;
    return (
        <WithPlaceholders value={{
            footer
        }}>
            <footer className="footer mt-auto ">
                <div className="container">
                    <div className={"row"}>
                        <div className={"col-sm-3"}>
                            <div className="row footer-logo-container justify-content-center justify-content-sm-start">
                                <div className="col-lg-5 col-sm-6 col-5">
                                    <img src={schema.data.brandLogo} className="mb-3 w-100 footer-logo"/>
                                    <p className={"text-center text-sm-left"}>{footer.address.split(",").map(val => {
                                        return <span key={val}> {val} </span>
                                    })}<br/>
                                        {footer.addressTwo?.split(",").map(val => {
                                            return <span key={val}> {val} </span>
                                        })}<br/>
                                        {footer.city} {<br/>} {footer.postalCode} </p>
                                </div>
                            </div>
                        </div>
                        <div className={"col-sm-4"}>
                            <p className="font-weight-bold mt-3">{footer.pageLinks?.map((id,key) => <PageLink pageId={id} key={key}/>)}</p>
                        </div>
                        <div className={"col-sm-5 d-sm-block align-items-end"}>
                            <p className="font-weight-bold mt-3 ">{tx`connectWithUsLabel`}</p>
                            <div className={"footer-social-media"}>
                                <ul className="mt-3 m-0 p-0">
                                    {footer.socialMediaConfig?.map(({media, link}) => <li className="d-inline">
                                        <a className="footer-button-icon btn-icon btn-icon-footer mr-2" target="_blank"
                                           href={link}><FontAwesomeIcon icon={mediaToIcon(media)} size={"lg"}/></a>
                                    </li>) ?? null}
                                    {/*<li className="d-inline"><a className="footer-button-icon btn-icon mr-2" target="_blank"><FontAwesomeIcon icon={faFacebook} size={"lg"}/></a></li>*/}
                                    {/*<li className="d-inline"><a className=" btn-icon mr-2 footer-button-icon" target="_blank"><FontAwesomeIcon icon={faInstagram} size={"lg"}/></a></li>*/}
                                </ul>
                            </div>
                            <div className="input-group mt-4 footer-news-letter">
                                <input className="form-control" type="text" placeholder={ts`subscribe`}/>
                                <button className="btn btn-primary subscribe-btn">{tx`subscribeButtonLabel`}</button>
                            </div>
                            <div className="justify-content-start mt-5">
                                {!!schema.data.footer.telephone &&
                                <a className="btn-text mr-5" href={`tel:${schema.data.footer.telephone}`}>
                            <span className="btn-text__icon footer_btn-text__icon">
                            <FontAwesomeIcon icon={faPhoneAlt} size={"lg"}/></span><span
                                    className="btn-text__text footer_btn-text footer_btn-text-default">{tx`phone|{{footer.telephone}}`}</span></a>}
                                {!!schema.data.footer.email &&
                                <a className="btn-text" href={`mailto:${schema.data.footer.email}`}><span
                                    className="btn-text__icon footer_btn-text__icon mr-md-1"><FontAwesomeIcon
                                    icon={faEnvelope}
                                    size={"lg"}/></span><span
                                    className="btn-text__text footer_btn-text footer_btn-text-default">{tx`email|{{footer.email}}`}</span></a>}
                            </div>

                        </div>
                    </div>

                </div>
                <div className="footer-sub-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col text-center">
                                Copyright
                                © <span>{DateTime.fromISO((new Date().toISOString())).toFormat("yyyy")}</span> {schema.data.merchantName}
                                . Powered by <a href={schema.data.siteUrl ?? "https://appigo.co/"} target="_blank"> appiGo</a>
                                {/*<>
                                All rights reserved. | <a href="https://appigo.co/terms-of-service" target={"_blank"}>Terms</a> | <a href="https://appigo.co/privacy-policy" target={"_blank"}>Privacy Policy</a>
                            </>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </WithPlaceholders>
    );
}
