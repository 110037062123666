import {
    get,
    NetworkState,
    networkStateReducer
} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {BrandDataSchema} from "./types";

export const domain = "@appigo-retailer/brandData/get"

export const brandDataGetReducer = networkStateReducer<BrandDataSchema>(domain)

export function useBrandData(): [NetworkState<BrandDataSchema>, () => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<BrandDataSchema>>(state => state.brandData),
        () => dispatch(get(domain, "/customer-api/v2/brandData/get"))
    ]
}