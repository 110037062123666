import {
    get, getFamily,
    NetworkState, NetworkStateFamily,
    networkStateReducer, networkStateReducerFamily, post, reset, resetFamily, resolveFamily,
} from "../networkStateReducer";

import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import { Statistics } from ".";


export const domain = "@appigo-retailer/affiliate/get-customer-statistics"

export const getCustomerStatisticsReducer = networkStateReducerFamily<Statistics>(domain)

export function useGetCustomerStatistics (family: string = 'Default') : [NetworkState<Statistics>, () => void,() => void] {
    let dispatch = useDispatch();

    return [
        resolveFamily(family, useSelector<RootState, NetworkStateFamily<Statistics>>(state => state.affiliate.getCustomerStatistics)),
        () => dispatch(getFamily(domain, "/customer-api/v2/affiliate/statistics", family)),
        () => dispatch(resetFamily(domain, family))
    ]
}