import {
    get,
    NetworkState, NetworkStateFamily,
    networkStateReducer,
    networkStateReducerFamily,
    post, postFamily, resetFamily,
    resolveFamily
} from "../networkStateReducer";
import {CartItemRequest, Cart} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/cart/update"

export const cartUpdateReducer = networkStateReducerFamily<Cart>(domain)

export function useCartUpdate(family: string = "Default"): [NetworkState<Cart>, (req: CartItemRequest) => void, () => void] {
    let dispatch = useDispatch();

    return [
        resolveFamily(family,useSelector<RootState, NetworkStateFamily<Cart>>(state => state.cart.update)),
        (req) => dispatch(postFamily(domain, "/customer-api/v2/cart/update", family, req)),
        () => dispatch(resetFamily(domain, family))
    ]
}
