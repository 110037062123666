import React, { Reducer, useCallback, useEffect, useReducer, useState } from "react";
import { Product } from "../../../../../reducers/product/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { definition as faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { isError, isSuccess } from "../../../../../reducers/networkStateReducer";
import { definition as faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { definition as faMoneyBillAlt } from "@fortawesome/free-solid-svg-icons/faMoneyBillAlt";
import { definition as faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { definition as faUndo } from "@fortawesome/free-solid-svg-icons/faUndo";
import { definition as faCreditCard } from "@fortawesome/free-solid-svg-icons/faCreditCard";
import {
    initProductState,
    productStateReducer,
    QuantityAction,
    QuantityState,
    updateProduct,
    updateQuantity
} from "./util";
import { useCart } from "../../../../../reducers/cart/get";
import { useCartAdd } from "../../../../../reducers/cart/add";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { useCartPopUp } from "../../../../../reducers/ui/cartPopUp";
import { useI18n, WithPlaceholders } from "../../../../../i18n/I18nSupport";
import { useProfileGet } from "../../../../../reducers/profile/get";
import { useProfileUpdateWishList, WishListItem } from "../../../../../reducers/profile/updateWishListItems";
import { keycloak } from "../../../../../auth/AuthProvider";
import { Link } from "react-router-dom";
import { useTracking } from "../../../../../tracking/trackingProvider";
import { useSchema } from "../../../../../reducers/schema";
import { useLocalStorage, useSessionStorage } from "../../Checkout/CheckoutFlowRevamp/utils";
import { CartItemRequest } from "../../../../../reducers/cart/types";
import { useSetInventoryLocation } from "../../../../../reducers/cart/setInventoryLocation";
import { BuyNowButton } from "./BuyNowButton";
import { toast } from "react-toastify";
import { toastCustomOptions } from "../../../../common/commons";
import { useDefaultCurrencyCode } from "../../../../../reducers/ui/defaultCurrencyCode";
import { toastWithDanger } from "../../Cart/util";
import {ItemType} from "../../../../../tracking/googleAnalytics";
import {useDefaultLanguage} from "../../../../../reducers/ui/defaultLanguage";
import {PriceSection} from "./ProductInformation";


interface ProductStateSectionPartProps {
    product: Product
    state: QuantityState

    dispatch(action: QuantityAction): void
}

function StockLabel({ product, state }: ProductStateSectionPartProps) {
    const { tx } = useI18n("product.info.stockLabel")
    if (state.available && product.availability) {
        return <div className="m-0 bg-success text-white p-1 text-center">{tx`inStock`}</div>
    }
    return <div className="m-0 bg-danger text-white p-1 text-center">{tx`outOfStock`}</div>
}

interface ThresholdLabelSetterProps {
    remain: number,
    value: number
}

function ThresholdLabelSetter({ remain, value }: ThresholdLabelSetterProps) {

    const { tx } = useI18n("product.info.thresholdLabels")

    if (!!remain && (remain > (value / 2))) {
        // return `Less than ${value} items available!`
        return <>{tx`minThreshold`}</>
    } else {
        if (remain === 1) {
            // return `Last item available!`
            return <>{tx`lastItem`}</>
        } else {
            // return `Only ${remain} items available!`
            return <>{tx`availableQuantity`}</>
        }
    }
}

const MaximumProductWarning = () => {
    const { tx } = useI18n("product.info.thresholdLabels")
    return <>{tx`availableQuantity`}</>
}


function ThresholdLabel({ product, state }: ProductStateSectionPartProps) {
    const [value, setValue] = useState<number>()
    const [remain, setRemain] = useState<number>()
    const [currentCart, setCurrentCart] = useState<number>();


    // console.log("minimumTheshold", product.minThreshold, "available product", state.availableQuantity)

    useEffect(() => {
        setValue(product.minThreshold ?? 0)
        setRemain(state.availableQuantity)
        setCurrentCart(state.quantity)
    }, [product, state])

    if (!state.available || !value) return null;

    return <>
        {!!remain && (remain < value) && product.availability &&
            <div className="noSelect text-danger-50 p-2 text-center mt-2 mt-lg-1 mr-lg-2 rounded"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                <ThresholdLabelSetter remain={remain} value={value} />
            </div>}
        {!!remain && (remain >= value) && currentCart === remain &&

            <div className="noSelect text-danger-50 p-2 text-center mt-2 mt-lg-1 mr-lg-2 rounded"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                <MaximumProductWarning />
            </div>}

    </>

}

export function ProductQuantity({ product, dispatch, state }: ProductStateSectionPartProps) {

    const { tx } = useI18n("product.info.quantity")
    const [schema] = useSchema()
    const [currentQuantity, setCurrentQuantity] = useState(1);
    const [availableQuantity, setAvailableQuantity] = useState(state.availableQuantity);

    if (isSuccess(schema)) {
        if (schema.data.generalSettings?.useSiteAsCatalog === true) {
            return <></>
        }
    }

    // useEffect(() => {
    //     console.log(`product quantity -> ${product.quantity}`)
    // },[product.quantity])

    function updateQuantityStates(v: number) {
        if (product?.availability && state.available) {
            if (isNaN(v)) {
                // console.log("v not a number", v)
                dispatch(updateQuantity(1))
                setCurrentQuantity(state.quantity)
            } else if (state.availableQuantity >= v) {
                // console.log("v is within a limit", v)
                dispatch(updateQuantity(v))
                setCurrentQuantity(state.quantity)
            } else {
                // console.log("v is outside limit", v)
                dispatch(updateQuantity(state.availableQuantity))
                setCurrentQuantity(state.quantity)
            }
        }

    }

    // console.log("currentAvailable", state.availableQuantity, "CurrentQuantity", state.quantity)
    return <>

        <div className="row mr-0 pb-1 ">

            <div className="input-group input-group__quantity noSelect w-100 flex-lg-row-reverse flex-column">
                <div className={"d-flex flex-row justify-content-end"}>
                    <div onClick={() => {
                        // dispatch(updateQuantity((state.availableQuantity > 0) ? state.quantity - 1 : 1))
                        // setCurrentQuantity(state.quantity)
                        if (state.availableQuantity > 0) {
                            dispatch(updateQuantity(state.quantity - 1))
                            setCurrentQuantity(state.quantity - 1)
                        } else {
                            dispatch(updateQuantity(state.quantity))
                            setCurrentQuantity(state.quantity)
                        }
                    }}
                        className="input-group-prepend" style={{ cursor: "alias" }}>
                        <a id={"product-quantity-minus-btn"} className="btn-icon btn-icon_sm minus-quantity-btn-icon_sm"><FontAwesomeIcon icon={faMinus} size={"sm"} style={{ color: "white" }} /></a>
                    </div>
                    <input disabled={false}
                        type="number"
                        className="mx-0 noSelect bg-background"
                           style={{borderRadius :"0px", border:"1px solid rgba(39,27,117,0.8)"}}
                        onChange={(e) => {
                            console.log("OnChange", e)
                            updateQuantityStates(parseInt(e.target.value))
                            // setCurrentQuantity(parseInt(e.target.value))
                        }
                        }
                        placeholder="1"
                        value={state.quantity}
                        aria-label=""
                        aria-describedby="" />
                    <div
                        onClick={(e) => {
                            if (((state.availableQuantity - state.quantity >= 1) && (state.available && product?.availability))) {
                                dispatch(updateQuantity(state.quantity + 1))
                                setCurrentQuantity(state.quantity + 1)
                            } else {
                                dispatch(updateQuantity(state.quantity))
                                setCurrentQuantity(state.quantity)
                            }

                        }}
                        className="input-group-prepend">
                        <a id={"product-quantity-plus-btn"} className="btn-icon btn-icon_sm plus-quantity-btn-icon_sm"><FontAwesomeIcon icon={faPlus} size={"sm"} style={{ color: "white" }} /></a>
                    </div>
                </div>
                <ThresholdLabel product={product} state={state} dispatch={dispatch} />
            </div>
        </div>
    </>
}

export function AddToCart({ product, state }: ProductStateSectionPartProps) {

    let [cartPopUpState, setCartPopUpState] = useCartPopUp()
    const [cart] = useCart();
    const [schema] = useSchema()
    const [addedStatus, doAddToCart, resetAdd] = useCartAdd();
    const { tx } = useI18n("product.info.addToCartButtonLabel")
    const { ts } = useI18n("warnings.popUpsWarning")
    const { track } = useTracking();
    let [addedToCart, setAddedToCart] = useState<boolean>(false);
    const [getItem, setItem, removeItem, clearSessionStorage] = useSessionStorage();
    let [response, setInventoryLocation, resetInventoryLocationSetter] = useSetInventoryLocation();
    let [getInventoryLocation] = useLocalStorage<{ inventoryId: string }>();
    let [isAddToCartClicked, setAddToCartClicked] = useState(false)
    const [currency] = useDefaultCurrencyCode();
    const [language] = useDefaultLanguage();
    const [currCurrency, setCurrCurrency] = useState<string>();
    const [isCurrencyAvailable, setIsCurrencyAvailable] = useState<boolean>(true);



    useEffect(() => {
        if (!!currency) {
            setCurrCurrency(currency?.split("|")[0])
        }
    }, [currency])

    useEffect(() => {
        if (isSuccess(cart) && !cart.data.inventoryLocation && getInventoryLocation("inventoryId").inventoryId) {
            setInventoryLocation(getInventoryLocation("inventoryId").inventoryId)
        }
    }, [cart, getInventoryLocation("inventoryId").inventoryId])

    useEffect(() => {
        if (isSuccess(cart)) {
            Object.values(cart.data.items).some(a => a.product.baseId === product.baseId && a.product.varianceKey === product.varianceKey) ? setAddedToCart(true) : setAddedToCart(false)
        }
    }, [cart, product.varianceKey])

    useEffect(() => {
        if (isSuccess(addedStatus)) {
            resetAdd()
        }
    }, [addedStatus])

    useEffect(() => {
        if (isSuccess(schema) && !!schema.data.generalSettings?.isRegisteredUsersOnly) {
            let itemToAddFromStorage = getItem(`cartItem_${product.baseId}:${product.varianceKey}`)
            console.log(`Added Item to cache ${itemToAddFromStorage}`)
            if (itemToAddFromStorage != undefined && keycloak.authenticated) {
                doAddToCart(itemToAddFromStorage as CartItemRequest)
                console.log(`Removing after adding Item from cache ${product.baseId}:${product.varianceKey}`)
                clearSessionStorage()
            } else {
                console.log(`Removed Item from cache ${product.baseId}:${product.varianceKey}`)
                removeItem(`cartItem_${product.baseId}:${product.varianceKey}`, 30000)
            }
        }
    }, [schema, product])

    useEffect(() => {
        if (!!currCurrency) {
            if (!!product.enableSpecialPrice && !!product.specialPrice) {
                setIsCurrencyAvailable(!!product.specialPrice[currCurrency] && (product.specialPrice[currCurrency] > 0))
            } else {
                setIsCurrencyAvailable(!!product.price && !!product.price[currCurrency] && (product.price[currCurrency] > 0))
            }
        }
    }, [currCurrency])

    let addToCart = useCallback<() => void>(() => {
        // console.log("ProductInAddingTo Cart", product)
        let gaItems: ItemType[] = [{
                item_id: product.id,
                item_category: product.categoryId,
                quantity: state.quantity,
                item_name: product?.name?.[language]?.toString() ?? ""
            }]

        track({
            type: "AddToCart",
            data: {
                value: !!product.price ? product.price[currency.split("|")[0]] : 0,
                currency: currency.split("|")[0] ?? Object.keys(product.price ?? {})[0] ?? "USD",
                content_ids: product.baseId ?? '',
                content_type: product.varianceKey ?? '',
                quantity: state.quantity,
                name: product?.name?.[language]?.toString(),
                items: gaItems
            }
        });
        doAddToCart({
            selected: true,
            productId: product.baseId ?? '',
            variance: product.varianceKey ?? '',
            quantity: product.quantity == null ? 1 : state.quantity
        })
        // if (cartPopUpState) {
        //     setCartPopUpState(!cartPopUpState)
        // }
        setAddToCartClicked(true)
    }, [product, state]);

    if (isSuccess(schema)) {
        if (schema.data.generalSettings?.useSiteAsCatalog == true) {
            return <></>
        }

        if (schema.data.generalSettings?.isRegisteredUsersOnly == true) {
            if (keycloak.authenticated == null || !keycloak.authenticated) {
                return <div className="col-sm-6 mt-sm-0 mt-2 p-1">
                    <Link to={"/login"}>
                        <button
                            className={`btn btn-primary w-100 text-white ${state.available ? 'cursor-pointer' : 'alias-pointer'}`}
                            disabled={false}
                            data-toggle="modal"
                            data-target="#login-modal"
                            onClick={(e) => {
                                if ((!state.available || !product.availability || !isCurrencyAvailable) && (product.quantity != null)) {
                                    toastWithDanger(ts`outOfStock`);
                                } else {
                                    keycloak.login()
                                }
                            }}>{tx`add`}
                        </button>
                    </Link>
                </div>
            }

        }

    }
    return <div className="col-sm-6 mt-sm-0 mt-2 p-1">
        {/*{isAddToCartClicked && <AddToCartConfirmation addToCartSate={isAddToCartClicked} setAddToCartState={setAddToCartClicked}/>}*/}
        <button id={"product-page-add-to-cart-btn"} className={`btn btn-primary w-100 text-white ${state.available ? 'cursor-pointer' : 'alias-pointer'}`}
            disabled={false}
            data-toggle="modal"
            data-target="#login-modal"
            onClick={() => {
                if ((!state.available || !product.availability || !isCurrencyAvailable) && !(product.quantity == null)) {
                    toastWithDanger(ts`outOfStock`);
                } else {
                    addToCart()
                }
            }
            }>{addedToCart ? tx`update` : tx`add`}
        </button>
    </div>
}

export type DeliveryOptionData = {
    matchTag: string,
    label: string,
    icon: IconDefinition
}

interface DeliveryOptionProps {
    label: string
    icon: IconDefinition
}

function DeliveryOption({ label, icon }: DeliveryOptionProps) {
    return <tr>
        <td className="pl-0 py-2">
            <span className="text-light">
                <FontAwesomeIcon icon={icon} size={"sm"} />
            </span> {label}
        </td>
        <td className="py-2 text-success text-right"><FontAwesomeIcon icon={faCheckCircle} size={"lg"} /></td>
    </tr>
}

interface DeliveryOptionsProps {
    res: Product
}

const deliveryOptionsValues: DeliveryOptionData[] = [
    { matchTag: "acceptReturns", label: "Accept returns", icon: faUndo },
    { matchTag: "cashOnDelivery", label: "Cash on delivery", icon: faMoneyBillAlt },
    { matchTag: "cardPayments", label: "Card Payments", icon: faCreditCard }
]

function DeliveryOptions({ res }: DeliveryOptionsProps) {

    const { tx } = useI18n("product.info.deliveryOptions")

    return <div className={"noSelect"}>
        <table className="table table-borderless mt-3">
            <tbody>
                {/*{*/}
                {/*    deliveryOptionsValues*/}
                {/*        .filter((val) => Array.of(res?.acceptCardPayment, res?.acceptReturns, res?.cashOnDelivery)[val.matchTag] === true)*/}
                {/*        .map(({label,icon}) => <DeliveryOption icon={icon} label={label}/>)*/}
                {/*}*/}
                {res.cashOnDelivery && <tr>
                    <td className="pl-0 py-2"><span className="text-light"><FontAwesomeIcon icon={faMoneyBillAlt}
                        size={"sm"} />
                    </span> {tx`cashOnDelivery`}
                    </td>
                    <td className="py-2 text-success text-right"><FontAwesomeIcon icon={faCheckCircle} size={"lg"} /></td>
                </tr>}
                {res.acceptReturns && <tr>
                    <td className="pl-0 py-2"><span className="text-light"><FontAwesomeIcon icon={faUndo}
                        size={"sm"} /></span> {tx`acceptReturns`}
                    </td>
                    <td className="py-2 text-success text-right"><FontAwesomeIcon icon={faCheckCircle} size={"lg"} /></td>
                </tr>}
                {res.acceptCardPayment && <tr>
                    <td className="pl-0 py-2"><span className="text-light"><FontAwesomeIcon icon={faCreditCard}
                        size={"sm"} />
                    </span> {tx`cardPayments`}
                    </td>
                    <td className="py-2 text-success text-right"><FontAwesomeIcon icon={faCheckCircle} size={"lg"} /></td>
                </tr>}
            </tbody>
        </table>
    </div>
}

interface ProductStateSectionProps {
    product: Product
}

export function AddToWishList({ product }: ProductStateSectionProps) {

    const [profile, getProfile] = useProfileGet();
    const [updatedProfile, doAddToWishList, reset] = useProfileUpdateWishList();
    const { tx } = useI18n("product.info.wishListButton")

    let [currWishList, setCurrWishList] = useState<WishListItem[]>();
    let [addedToWishList, setAddedToWishList] = useState<boolean>(false);
    const { track } = useTracking();

    useEffect(() => {
        getProfile()
    }, [])

    useEffect(() => {
        if (isSuccess(profile) && !!profile.data.wishList) {
            setCurrWishList(profile.data.wishList)
        }
    }, [profile])

    useEffect(() => {
        if (isSuccess(updatedProfile)) {
            setCurrWishList(updatedProfile.data.wishList)
        }
    }, [updatedProfile])


    useEffect(() => {
        if (currWishList) {
            console.log(
                currWishList.filter(a => a.baseId === product.baseId && a.varianceKey === product.varianceKey)
            )
            currWishList.some(a => a.baseId === product.baseId && a.varianceKey === product.varianceKey) ? setAddedToWishList(true) : setAddedToWishList(false)
        }
    }, [product, currWishList])

    useEffect(() => {
        if (isSuccess(updatedProfile)) {

            toast.success(`Product ${addedToWishList ? 'removed from' : 'added to'} wishlist successfully`, {
                ...toastCustomOptions,
                toastId: "success product"
            })
        } else if (isError(updatedProfile)) {
            toast.error(`Product ${addedToWishList ? 'removal' : 'addition'} failed`, {
                ...toastCustomOptions,
                toastId: "error product"
            })
        }
    }, [updatedProfile])

    useEffect(() => {

        if (isSuccess(updatedProfile)) {
            return reset
        }
    }, [updatedProfile])

    let addToWishList = useCallback<() => void>(() => {
        doAddToWishList({
            baseId: product.baseId ?? '',
            varianceKey: product.varianceKey ?? '',
        })
    }, [product]);

    // if (!isSuccess(profile)) return <></>

    if (keycloak.authenticated) {

        return <div className="row mt-3">
            <div className="col-sm-12 mt-sm-0 mt-2 p-1">
                <button id={"product-page-add-to-wish-list-btn "} className="btn btn-light w-100"
                    onClick={addToWishList}>{addedToWishList ? tx`removeFromWishListLabel` : tx`addToWishListLabel`}
                </button>
            </div>
        </div>

    } else {
        return <div className="row mt-3">
            <div className="col-sm-12 mt-sm-0 mt-2 p-1">
                <Link to={"/login"}>

                    <button className="btn btn-light w-100"
                        onClick={(e) => {
                            keycloak.login()
                        }}>{tx`addToWishListLabel`}</button>
                </Link>
            </div>
        </div>

    }
}

export function ProductStateSection({ product }: ProductStateSectionProps) {

    let [state, dispatch] = useReducer<Reducer<QuantityState, QuantityAction>>(productStateReducer, initProductState);
    const [showAddToCartPopup, setShowAddToCartPopup] = useState<boolean>(false)

    useEffect(() => {
        dispatch(updateProduct(product))
    }, [product])

    return <>
        <div className={"d-flex flex-column w-100 "}>
            <div className="d-flex flex-column justify-content-end align-items-stretch">

                <div className="justify-content-end quantity-btn-web-view my-2">
                    {!(product.hideProductCounter) ? (
                        <ProductQuantity product={product} state={state} dispatch={dispatch}/>
                    ) : (
                        <div className={"justify-content-start mt-2 quantity-btn-web-view"}
                             style={{height: "auto", minHeight: "38px"}}>
                            <p className="my-2" style={{visibility: "hidden"}}>Blank</p>
                        </div>
                    )}
                </div>
                <hr className={"mb-0"} style={{width: "1%"}}/>
                <div className="d-flex justify-content-end my-3 ">
                    <PriceSection product={product}/>
                </div>
                <div className="justify-content-end buy-ticket-web-view mt-3">
                    {/*<AddToCart product={product} state={state} dispatch={dispatch} />*/}
                    <BuyNowButton product={product} state={state} dispatch={dispatch}/>
                </div>
                {/*<AddToWishList product={product} />*/}
                {/*<DeliveryOptions res={product} />*/}
            </div>
        </div>
    </>
}
