import {SchedulerProfileRule} from "../../../../reducers/schema";
import {DateTime} from "luxon";
import {toast} from "react-toastify";
import {toastCustomOptions} from "../../../common/commons";
import { OpenHours, Outlet, ScheduleRule } from "reducers/outlets/types";

export function filterWeekdays(day: Date, inDays?: SchedulerProfileRule[]): boolean {
    let set = new Set<string>(inDays?.map(v => v.value))
    let out: number[] = []
    set.forEach((value) => {
        if (value == "SUNDAY") {
            out = [...out, 0]
        } else if (value == "MONDAY") {
            out = [...out, 1]
        } else if (value == "TUESDAY") {
            out = [...out, 2]
        } else if (value == "WEDNESDAY") {
            out = [...out, 3]
        } else if (value == "THURSDAY") {
            out = [...out, 4]
        } else if (value == "FRIDAY") {
            out = [...out, 5]
        } else if (value == "SATURDAY") {
            out = [...out, 6]
        }
    })

    return !out.includes(day.getDay())
}

export function excludedMonthlyDays(monthRange: number,inDays?: SchedulerProfileRule[]): Date[] {
    let dateRange = Array.from(new Set<number>(inDays?.map(v => parseInt(v.value))))
    let out: Date[] = []
    if (!!dateRange && dateRange?.length > 0) {
        for (let i = 0; i < monthRange; i++) {
            for (let j = 0; j < (dateRange?.length ?? 0); j++) {
                if (DateTime.now().day < dateRange[j]) {
                    out = [...out, DateTime.now().plus({day: (dateRange[j] - DateTime.now().day),month: i}).toJSDate()]
                } else {
                    out = [
                        ...out,
                        DateTime.now().minus({day: (DateTime.now().day - dateRange[j])})
                            .plus({month:i})
                            .toJSDate()
                    ]
                }
            }
        }
    }
    return out
}

export function excludedYearlyDays(inDays?: SchedulerProfileRule[]): Date[] {
    let dateRange = inDays?.map(v => v.value)
    if (!!dateRange) {
        return dateRange.map(date => DateTime.fromFormat(date,"yyyy-MM-dd").toJSDate())
    }
    return []
}

export function toastWithDanger(message: string) {
    toast.error(message, {
        ...toastCustomOptions,
        toastId: "error product",
    })
}

export function isTimeAvailable(time: Date, storeOpenHours: OpenHours[], timezone: string, scheduleRules: ScheduleRule[] = []) {
    const selectedDate = DateTime.fromJSDate(time).setZone(timezone)
    const selectedDayOfweek = selectedDate.weekdayLong;
    const openHours = storeOpenHours?.filter(openHour => !!openHour.isOpen && selectedDayOfweek == openHour.dayOfWeek) || []
    if (!openHours.length) return false;
    
    const timeStr = selectedDate.toFormat("HH:mm")

    const ruleRes = scheduleRules.some(rule => {
        if (rule.type == 'DAY_OF_MONTH' && +rule.value == selectedDate.day) return true;
        if (rule.type == 'DAY_OF_YEAR' && rule.value == selectedDate.toFormat("yyyy-MM-dd")) return true;
        if (rule.type == 'DAY_OF_WEEK' && rule.value.toLowerCase() == selectedDate.weekdayLong.toLowerCase()) return true;
        return false;
    })
    if(ruleRes) return false;
    return openHours[0].start <= timeStr && openHours[0].end >= timeStr;
}

export function getOrderPrepairationTime(orderPreparationDurationInHrs: number, storeOpenHours: OpenHours[], timezone: string, scheduleRules: ScheduleRule[] = [], deliveryEstimationHrs = 0, date?: Date): Date {
    let preDate = date ?? DateTime.now().toJSDate();
    if (!!orderPreparationDurationInHrs) {
        preDate = DateTime.fromJSDate(preDate).plus({hours: orderPreparationDurationInHrs}).toJSDate()
    }

    const preparationDateTime = DateTime.fromJSDate(preDate).setZone(timezone);
    if (preparationDateTime.diffNow('days').days >= 60 || !storeOpenHours.length) return preDate;

    const preDateDayOFweek = preparationDateTime.weekdayLong;
    const openHours = storeOpenHours?.filter(openHour => !!openHour?.isOpen && preDateDayOFweek == openHour.dayOfWeek) || []
    const from = openHours.map(openHour => openHour.start)?.sort()?.[0];
    const to = openHours.map(openHour => openHour.end)?.sort()?.pop();
    const prepTime = preparationDateTime.toFormat("HH:mm");
    
    if(from && from <= prepTime && to && to >= prepTime) {
        return preDate
    } else if(!from || !to || to < prepTime) {
        let prepDateTime = preparationDateTime.plus({ days: 1})
        const openHours = storeOpenHours?.filter(openHour => !!openHour?.isOpen && prepDateTime.weekdayLong == openHour.dayOfWeek) || []
        const nextAvTimefrom = openHours.map(openHour => openHour.start)?.sort()?.[0];
        if (nextAvTimefrom) prepDateTime = prepDateTime.set({ hour: +nextAvTimefrom?.split(':')?.[0], minute: +nextAvTimefrom?.split(':')?.[1]})
        
        if (nextAvTimefrom && isTimeAvailable(prepDateTime.toJSDate(), storeOpenHours, timezone, scheduleRules)) return prepDateTime.toJSDate();
        else return getOrderPrepairationTime(orderPreparationDurationInHrs, storeOpenHours, timezone, scheduleRules, deliveryEstimationHrs, prepDateTime.toJSDate())
    } else {
        const offsetHrs = deliveryEstimationHrs % 12;
        return preparationDateTime.set({ 
            hour: Math.min((+from?.split(':')?.[0] + offsetHrs), 18), 
            minute: +from?.split(':')?.[1]
        }).toJSDate()
    }

}

export function getAvailableTime(preparationDate: Date, date: Date, storeOpenHours: OpenHours[], timezone: string, deliveryEstimationHrs = 0) {
    const selectedDate = DateTime.fromISO(date.toISOString()).setZone(timezone)
    const selectedDayOFweek = selectedDate.weekdayLong;
    const openHours = storeOpenHours?.filter(openHour => !!openHour.isOpen && selectedDayOFweek == openHour.dayOfWeek) || []
    const preparationDateTime = DateTime.fromJSDate(preparationDate ?? new Date());
    
    let timeSlot = {
        from: "09:00",
        to: "09:00"
    }
    if (openHours.length) {
        if (preparationDateTime.setZone(timezone).toFormat("yyyy-MM-dd") == selectedDate.toFormat("yyyy-MM-dd")) {
            const to = openHours.map(openHour => openHour.end).sort().pop()
            const from = openHours.map(openHour => openHour.start).sort()[0]
            const preparationTime = preparationDateTime.setZone(timezone).toFormat("HH:mm")
            timeSlot = {
                from: [from, preparationTime].sort().pop() ?? "09:00",
                to: to ?? "09:00"
            }
        } else {
            timeSlot = {
                from: openHours.map(openHour => openHour.start).sort()[0] ?? "09:00",
                to: openHours.map(openHour => openHour.end).sort().pop() ?? "09:00"
            }
        }
    } else {
        timeSlot = {
            from: "09:00",
            to: "18:00"
        }
    }

    const offsetHrs = deliveryEstimationHrs % 12;
    const [fromHrs, fromMin] = timeSlot.from.split(':').map(it => +it)
    const [toHrs, toMin] = timeSlot.to.split(':').map(it => +it)
    return {
        from: `${(Math.min((fromHrs + offsetHrs), 18) + '').padStart(2, '0')}:${(fromMin + '').padStart(2, '0')}`,
        to: `${(Math.min((toHrs + offsetHrs), 23) + '').padStart(2, '0')}:${(toMin + '').padStart(2, '0')}`
    };
}

export function getTimeZone(country: string | undefined) {
    return country == "Sri Lanka" ? "Asia/Colombo" : country == "Bangladesh" ? "Asia/Dhaka" : "Asia/Manila"
}

export function getDeliveryAvailableHours(outlets: Outlet[], inventoryLocationId: string | undefined) {
    return outlets.filter(outlet => outlet.inventoryLocation == inventoryLocationId)?.[0]?.openHours?.schedule ?? []
}