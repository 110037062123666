import {Action, Reducer} from "redux";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export interface PromotionPopupState {
    open: boolean
}

interface SetPromotionPopupState extends Action {
    type: "@appigo-retailer-ui/set-promotion-popup-status"
    open: boolean
}

function isSetPromotionPopUpState(action: Action): action is SetPromotionPopupState {
    return action.type === "@appigo-retailer-ui/set-promotion-popup-status"
}

function setPromotionPopUpState(open: boolean): SetPromotionPopupState {
    return {
        open,
        type: "@appigo-retailer-ui/set-promotion-popup-status"
    }
}

export const promotionPopUpReducer: Reducer<PromotionPopupState, SetPromotionPopupState> = (state = { open: false }, action) =>
    isSetPromotionPopUpState(action) ? {open: action.open} : state

export function usePromotionPopUp(): [boolean, (open: boolean) => void] {
    const dispatch = useDispatch();
    return [
        useSelector<RootState, PromotionPopupState>(state => state.uiState.promotionPopup).open,
        open => dispatch(setPromotionPopUpState(open))
    ]
}