import React from "react";
import {useSchema} from "../../../reducers/schema";
import {useI18n, WithPlaceholders} from "../../../i18n/I18nSupport";
import {isSuccess} from "../../../reducers/networkStateReducer";
import {Link} from "react-router-dom";
import {mediaToIcon, PageLinkObjectProps} from "./Footer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DateTime} from "luxon";
import {faEnvelope, faPhoneAlt, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {useLocalStorage} from "./../Body/Checkout/CheckoutFlowRevamp/utils";
import {useBrandList} from "../../../reducers/brands/searchBrands";
// import {LazyLoadImage} from "react-lazy-load-image-component";
// import {createImgProxyUrl} from "../../common/CreateImgProxyUrl";

export function PageLinkRevamp({pageId, pageTitle}: PageLinkObjectProps) {
    const {tx, localized} = useI18n("footer.pageLink");

    return <WithPlaceholders value={{title: pageTitle}}>
        {/*<li className="d-flex flex-row mt-1 mr-2">*/}
        <li className="d-flex flex-row justify-content-center justify-content-lg-start footer-text">
            <Link to={`/information/page/${pageId}`} target={"_blank"}>
                <span
                    className="btn-text__text footer_btn-text footer_btn-text-default page-link-container"> {tx`title|{{title}}`} </span></Link>
        </li>

    </WithPlaceholders>

}

interface ContactInfoItemProps {
    data: string;
    icon: IconDefinition;
    label: JSX.Element;
    value: JSX.Element;
    data2?: string;
    value2?: JSX.Element;
    type?: ContactInfoType;
    justifyContent?: 'justify-content-start' | 'justify-content-end' | 'justify-content-center' | 'justify-content-between' | 'justify-content-around';
}

export enum ContactInfoType {
    mail = `mailto:`, phone = `tel:`
}

function ContactInfoItem({icon, data, label, value, data2, value2, type, justifyContent}: ContactInfoItemProps) {

    return (
        <a className={`btn-text d-flex w-100 ${justifyContent}`} href={`${type}${data}`}>
            <div>
                <span className="btn-text__icon footer_btn-text__icon d-flex justify-content-center">
                    <FontAwesomeIcon icon={icon} size={"lg"} color="white"/>
                </span>
            </div>
            <div className={"w-100 d-flex justify-content-start"}>
                <span className="btn-text__text footer_btn-text footer_btn-text-default d-flex flex-column">
                    <label className={"d-flex"} style={{fontSize: "0.65rem", margin: "0.1rem", width:"100%"}}>
                        {label}
                    </label>
                    <label style={{fontSize: "0.9rem", fontWeight: "bold"}}>
                        {(data && value) &&
                            <a className="btn-text__text footer_btn-text footer_btn-text-default"
                               href={`${type}${data}`}
                               style={{textDecoration: 'none', color: 'black'}}>{value}</a>}
                        {(data2 && value2) &&
                            <span>
                                <span> | </span>
                                <a className="btn-text__text footer_btn-text footer_btn-text-default"
                                   href={`${type}${data2}`}
                                   style={{textDecoration: 'none', color: 'black'}}>{value2}</a>
                            </span>}
                    </label>
                </span>
            </div>
        </a>
    );
}


// function ContactInfoItem({data, type}: { data: string, type: string }) {
//     return (
//         <a href={`${type}${data}`} className="btn-text footer-text" style={{color: 'inherit' }}>
//             {data}
//         </a>
//     );
// }


function getIconClasses(icon: string, socialMediaWithStandardColors: boolean) {
    return socialMediaWithStandardColors ? `social-${icon}` : 'footer-button-icon';
}

export function FooterRevamp() {

    let [schema] = useSchema()
    const {tx, ts} = useI18n("footer")
    const [getItem] = useLocalStorage<{ inventoryId: string }>();
    const [brandsList, getBrandsList, clear] = useBrandList();
    const shouldShowOtherBrands = isSuccess(brandsList) && brandsList.data.data.length !== 1;

    if (!isSuccess(schema)) return null

    const footer = schema.data.footer;
    const selectedInventoryId = getItem('inventoryId')?.inventoryId;
    const selectedInventoryLocation = selectedInventoryId ? schema.data.inventoryLocations?.filter(inventory => inventory.id == selectedInventoryId)?.[0] : null

    return <WithPlaceholders value={{
        footer
    }}>
        <footer className="footer mt-auto"
                style={{background: "linear-gradient(to top, black, transparent)", paddingTop: "30px", height: "12%"}}>
            <div className="container " style={{width: "85%"}}>
                <div className="d-flex flex-column flex-lg-row ">
                    {/* Section 1 */}
                    <div className="col-lg-7 col-12 d-flex footer-section1-description">
                        <div className="footer-logo-container">
                            {/*<p className="text-center text-lg-left footer-title">*/}
                            <p className={shouldShowOtherBrands? "text-center text-lg-left footer-title" : "d-none"}>
                                Serendisco Music Festival 2024
                            </p>

                            {/* Address */}
                            {selectedInventoryLocation ? (
                                <p className="text-center text-lg-left footer-text footer-section234-content">
                                    {selectedInventoryLocation.address?.addressLine1 && (
                                        <span>{selectedInventoryLocation.address?.addressLine1} <br/></span>
                                    )}
                                    {selectedInventoryLocation.address?.addressLine2 && (
                                        <span>{selectedInventoryLocation.address?.addressLine2} <br/></span>
                                    )}
                                    {selectedInventoryLocation.address?.city && (
                                        <span>{selectedInventoryLocation.address?.city} <br/></span>
                                    )}
                                    {selectedInventoryLocation.address?.province && (
                                        <span>{selectedInventoryLocation.address?.province} <br/></span>
                                    )}
                                    {selectedInventoryLocation.address?.postalCode && (
                                        <span>{selectedInventoryLocation.address?.postalCode} <br/></span>
                                    )}
                                </p>
                            ) : (
                                footer.address && (
                                    <p className="text-center text-lg-left footer-text">
                                        {footer.address.split(",").map(val => <span key={val}>{val}<br/></span>)}
                                        {footer.city}<br/>
                                        {footer.postalCode}
                                    </p>
                                )
                            )}
                        </div>
                    </div>

                    {/* Section 2, 3, and 4 */}
                    <div className="d-flex flex-row justify-content-around col-lg-6 col-12 footer-section234-content ">
                        <div className="row w-100 d-flex justify-content-sm-end justify-content-center">
                            {/* Section 2 - Helpful Links */}
                            <div className="col-lg-5 col-6 ">
                                <p className="footer-title">Links</p>
                                <ul className="list-unstyled text-center text-sm-left">
                                    <div className="d-flex flex-column">
                                        {footer.pageLinkObjects?.map((pageObj, key) => (
                                            <PageLinkRevamp
                                                pageId={pageObj.url ?? ""}
                                                pageTitle={pageObj.pageTitle}
                                                key={key}
                                            />
                                        ))}
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Section 3 - About Us */}
                    <div className="col-lg-5 col-12 footer-contact-content-section">
                        <div
                            className="d-flex footer-contact-content justify-content-around align-items-center mt-1 text-center text-md-left">
                            <div className={"footer-phone-content w-100 d-flex justify-content-around"}>
                            {(!!selectedInventoryLocation?.address?.contactNumber || !!schema.data.footer.telephone) && (
                                <ContactInfoItem
                                    data={selectedInventoryLocation?.address?.contactNumber || schema.data.footer.telephone || ""}
                                    label={tx`telephoneLabel|Have a Question?`}
                                    value={
                                        selectedInventoryLocation?.address?.contactNumber ? (
                                            <span>{selectedInventoryLocation?.address?.contactNumber}</span>
                                        ) : (
                                            tx`phone|{{footer.telephone}}`
                                        )
                                    }
                                    icon={faPhoneAlt}
                                    data2={selectedInventoryLocation?.address?.contactNumber2}
                                    value2={<span>{selectedInventoryLocation?.address?.contactNumber2}</span>}
                                    type={ContactInfoType.phone}
                                    // justifyContent={"justify-content-between"}
                                />
                            )}
                            </div>
                            <div className={"w-100 d-flex justify-content-around"}>
                                {!!schema.data.footer.email && (
                                    <ContactInfoItem
                                        data={schema.data.footer.email}
                                        label={tx`emailLabel|Contact Us at`}
                                        value={tx`email|{{footer.email}}`}
                                        icon={faEnvelope}
                                        type={ContactInfoType.mail}
                                        // justifyContent={"justify-content-between"}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>



                <div>
                    <div
                        className="footer-social-media d-flex flex-column justify-content-end pt-2 pb-2 pl-2 text-center text-sm-left"
                        style={{paddingBottom: "50px"}}
                    >
                        {!!footer.socialMediaConfig?.length && footer.enableSocialMediaConfigs ? (
                            <div>
                                {/* Optional: Add a label */}
                            </div>
                        ) : (
                            <div/>
                        )}
                        <div>
                            <ul
                                className="m-0 p-0 d-flex justify-content-lg-start "
                                style={{
                                    listStyleType: "none",
                                    justifyContent: "center",
                                    padding: 0
                                }}
                            >
                                {footer.enableSocialMediaConfigs &&
                                    footer.socialMediaConfig?.map(({media, link}, index) => (
                                        !!footer.socialMediaConfig?.length && (
                                            <li
                                                key={index}
                                                style={{
                                                    marginRight: index !== footer.socialMediaConfig?.length - 1 ? "10px" : "0",
                                                }}
                                            >
                                                <a
                                                    className={`btn-icon btn-icon-footer mt-1 ${getIconClasses(media, !!footer.socialMediaWithStandardColors)}`}
                                                    target="_blank"
                                                    href={link}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        width: "30px",
                                                        height: "30px",
                                                        borderRadius: "50%",
                                                        backgroundColor: "#f0f0f0",
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={mediaToIcon(media)} size="lg"/>
                                                </a>
                                            </li>
                                        )
                                    ))}
                            </ul>
                        </div>
                    </div>
                </div>


            </div>


            <div className="footer-sub-footer mt-lg-3 mt-sm-0">
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                <span className="footer-developer-info">
                    Design & Developed by{" "}
                    <a
                        href="https://appigo.co/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                    {/*<img src={AboutUs3} alt="about-us-3.jpeg" className={"img-fluid"}/>*/}
                    {/*<LazyLoadImage*/}
                    {/*    src={isSuccess(schema) ? createImgProxyUrl(`/merchant-resources/${schema.data.merchantId}/appigo-logo.png`, 50, 20) : ""}*/}
                    {/*    className="footer-appigo-logo" alt="FAIL"/>*/}
                        appiGo
                    </a>{" "}
                    @<span>{DateTime.fromISO(new Date().toISOString()).toFormat("yyyy")}</span>
                </span>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    </WithPlaceholders>
}
