import {
    get, isError, isSuccess,
    NetworkState,
    networkStateReducer, post, reset,
} from "../networkStateReducer";
import {components, operations} from "../../__generated/api";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {ConsumerProfileReq} from "./updateBillingAddress";
import {useEffect} from "react";
import {toast} from "react-toastify";
import {toastCustomOptions} from "../../view/common/commons";

export const domain = "@appigo-retailer/profile/update/wishList"

export const profileUpdateWishListReducer = networkStateReducer<ConsumerProfileReq>(domain)

export type WishListItem = components["schemas"]["WishListItem"]

export function useProfileUpdateWishList(): [NetworkState<ConsumerProfileReq>, (item: WishListItem) => void,() => void] {

    let dispatch = useDispatch();

    const networkState =  useSelector<RootState, NetworkState<ConsumerProfileReq>>(state => state.profile.updateWishList)

    return [
        networkState,
        (item) => dispatch(post(domain,"/customer-api/v2/profile/update/wishList",item)),
        () => dispatch(reset(domain))
    ];
}
