import {get, NetworkState, networkStateReducer} from "../networkStateReducer";
import {components} from "../../__generated/api";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/login"

export const loginReducer = networkStateReducer<string>(domain)

export function useLoginCallback(): [NetworkState<string>, ()=> void] {
    const dispatch = useDispatch();
    return [
        useSelector<RootState, NetworkState<string>>(state => state.user.loginState),
        () => dispatch(get(domain, "/customer-api/v2/user/login"))
    ]
}
