import {
    getFamily,
    NetworkState,
    NetworkStateFamily,
    networkStateReducerFamily, resetFamily,
    resolveFamily
} from "../networkStateReducer";
import {Product} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/product/getFromSlugs"

export const getProductFromSlugReducer = networkStateReducerFamily<Product>(domain)

export function useProductFromSlugs(family: string): [NetworkState<Product>, (productSlug: string, varianceSlug?: string) => void, () => void] {
    let dispatch = useDispatch();

    return [
        resolveFamily(family, useSelector<RootState, NetworkStateFamily<Product>>(state => state.product.getFromSlugs)),
        (productSlug, varianceSlug) => dispatch(getFamily(domain, "/customer-api/v2/products/{productSlug}", family, { path: {productSlug}, query: varianceSlug ?  {varianceSlug} :  undefined})),
        () => dispatch(resetFamily(domain, family))
    ]
}