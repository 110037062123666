import {
    get, getFamily,
    NetworkState,
    NetworkStateFamily,
    networkStateReducer,
    networkStateReducerFamily,
    post, resetFamily, resolveFamily
} from "../networkStateReducer";
import {CartItemRequest, Cart} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/cart/remove"

export const cartRemoveReducer = networkStateReducerFamily<Cart>(domain)

export function useCartRemove(family: string = "default"): [NetworkState<Cart>, (productId: string, variance: string) => void, () => void] {
    let dispatch = useDispatch();

    return [
        resolveFamily(family, useSelector<RootState, NetworkStateFamily<Cart>>(state => state.cart.remove)),
        (productId, variance) => dispatch(getFamily(domain, "/customer-api/v2/cart/delete",family, {query: {productId, variance}})),
        () => dispatch(resetFamily(domain, family))
    ]
}
