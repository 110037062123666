import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {definition as faUser} from "@fortawesome/free-solid-svg-icons/faUser";
import {definition as faAngleDown} from "@fortawesome/free-solid-svg-icons/faAngleDown";
import {definition as faSignOutAlt} from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import {useKeycloak} from "@react-keycloak/web";
import {useUserInfo} from "../../../../reducers/user/userInfo";
import Button from "react-bootstrap/Button";
import {Link} from 'react-router-dom'
import {useLogoutCallback} from "../../../../reducers/user/logout";
import {ProfileRes, useProfileGet} from "../../../../reducers/profile/get";
import {isSuccess} from "../../../../reducers/networkStateReducer";
import {ConsumerProfileReq} from "../../../../reducers/profile/updateBillingAddress";
import {UserInfo} from "../../../../auth/AuthProvider";
import {useI18n} from "../../../../i18n/I18nSupport";
import {useCartSetPromoCode} from "../../../../reducers/cart/setPromoCode";
import {definition as faHeart} from "@fortawesome/free-solid-svg-icons/faHeart";
import {useLocalStorage} from "../../Body/Checkout/CheckoutFlowRevamp/utils";

const expression = /\s[^\s]*$/;

export const nameShortener = (text: string, limit: number) => {
    if (text.length > limit) {
        const part = text.slice(0, limit - 3);
        if (part.match(expression)) {
            return part.replace(expression, '...');
        }
        return part + '...';
    }
    return text;
}

export function Login() {

    const [userInfo] = useUserInfo();
    const [profile, fetchProfile] = useProfileGet()
    const [, logoutCallback] = useLogoutCallback();
    const {keycloak} = useKeycloak();
    const [user, setUser] = useState<UserInfo>()
    const [profileData, setProfileData] = useState<ProfileRes>()
    const [result, setPromoCode] = useCartSetPromoCode();
    const {tx} = useI18n("header")
    const [getItem] = useLocalStorage<{ inventoryId: string }>();

    useEffect(() => {
        if (keycloak.authenticated && (isSuccess(profile) && profile.data.merchantId == null)) {
            fetchProfile()
        }
    }, [keycloak.authenticated])

    useEffect(() => {
        if (isSuccess(profile)) {
            setProfileData(profile.data)
        }
    }, [profile])

    useEffect(() => {
        if (!!userInfo) {
            setUser(userInfo)
        }
    }, [userInfo])

    if (keycloak.authenticated) {
        return <div className="dropdown">
            <button
                className="btn btn-light login-button dropdown-toggle my-2 my-sm-0 p-2 d-flex align-items-center justify-content-center custom-login-button"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <FontAwesomeIcon icon={faUser} size={"lg"} />
                <span className="mx-2">{nameShortener(profileData?.userName?.split(" ")[0] ?? "User", 15)}</span>
                {/*<FontAwesomeIcon icon={faAngleDown} size={"lg"} />*/}
            </button>

            <div
                className={`login-dropdown topbar-login-dropdown dropdown-menu shadow login-button ${!!getItem('inventoryId') ? "" : "d-none"}`}
                aria-labelledby="dropdownMenuButton">
                <Link to={"/account/details/profile"} className="dropdown-item"><FontAwesomeIcon icon={faUser}
                                                                                                 size={"lg"}/> {tx`account`}
                </Link>
                {/*<Link to={"/account/wishlist"} className="dropdown-item"><FontAwesomeIcon icon={faHeart}*/}
                {/*                                                                          size={"lg"}/> {tx`wishList`}*/}
                {/*</Link>*/}
                <Button className="dropdown-item" onClick={(e) => {
                    const inventoryId = localStorage.getItem('inventoryId')
                    localStorage.clear()
                    sessionStorage.clear()
                    if (inventoryId) localStorage.setItem('inventoryId', inventoryId)
                    logoutCallback()
                    keycloak.logout()
                }}><FontAwesomeIcon icon={faSignOutAlt} size={"lg"}/> {tx`logout`}</Button>
            </div>
        </div>
    } else {
        return <Link to={"/login"}>
            <button className="btn btn-light my-2 my-sm-0 p-2 border-0 bg-transparent login-button"
                    type="submit"
                    onClick={(e) => {
                        const inventoryId = localStorage.getItem('inventoryId')
                        localStorage.clear()
                        if (inventoryId) localStorage.setItem('inventoryId', inventoryId)
                        setPromoCode(undefined)
                        keycloak.login()
                    }}
            >
                <FontAwesomeIcon icon={faUser} size={"lg"}/> {tx`login`}
            </button>
        </Link>;
    }
}
