import React, {PropsWithChildren, useContext, useEffect} from "react";
import {useCookies} from "react-cookie";
import {useDispatch, useSelector} from "react-redux";
import {InitConfig, setBaseName} from "../reducers/initReducer";
import {RootState} from "../reducers";
import {parseCookie} from "../auth/AuthProvider";

export const baseUrl = window.location.origin

export function baseName(): string {
   return (window as any).BASEPATH ?? (parseCookie(document.cookie)["X-BASENAME"] === undefined ? window.location.pathname.split("/")[1] : parseCookie(document.cookie)["X-BASENAME"])
}

export function BaseNameProvider({children}: PropsWithChildren<any>) {
    let [cookies] = useCookies();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setBaseName((window as any).BASEPATH ?? (cookies["X-BASENAME"] === undefined ?  baseName() : cookies["X-BASENAME"])))
    }, [cookies])

    return <>
        {children}
    </>
}

export function useBaseName(): { baseName: string, initialized: boolean } {
    let {baseName, initialized} = useSelector<RootState, InitConfig>(state => state.initConfig);
    return {baseName: (window as any).BASEPATH ?? (baseName === undefined ? window.location.pathname.split("/")[1] : baseName), initialized: initialized}
}
