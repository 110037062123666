import {Action, Reducer} from "redux";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export interface DefaultCurrencyCodeState {
    currencyCode: string
}

interface ClearDefaultCurrencyCodeAction extends Action {
    type:"@appigo-retailer-ui/clear-default-currency",
}

function isClearDefaultCurrencyCodeAction(action: Action): action is ClearDefaultCurrencyCodeAction {
    return action.type == "@appigo-retailer-ui/clear-default-currency"
}

function clearDefaultCurrencyCode(): ClearDefaultCurrencyCodeAction {
    return {
        type: "@appigo-retailer-ui/clear-default-currency"
    }
}

interface SetDefaultCurrencyCodeAction extends Action {
    type:"@appigo-retailer-ui/set-default-currency",
    currencyCode: string
}

function isSetDefaultCurrencyCodeAction(action: Action): action is SetDefaultCurrencyCodeAction {
    return action.type === "@appigo-retailer-ui/set-default-currency"
}

function setDefaultCurrencyCode(currencyCode: string): SetDefaultCurrencyCodeAction {
    return {
        type: "@appigo-retailer-ui/set-default-currency",
        currencyCode: currencyCode
    }
}

export const defaultCurrencyCodeReducer: Reducer<DefaultCurrencyCodeState, (SetDefaultCurrencyCodeAction | ClearDefaultCurrencyCodeAction)> = (state = {currencyCode: "USD|$"}, action) => {
    if (isSetDefaultCurrencyCodeAction(action)) {
        return {
            currencyCode: action.currencyCode
        }
    } else if (isClearDefaultCurrencyCodeAction(action)) {
        return state
    }

    return state
}

export function useDefaultCurrencyCode(): [string, (currencyCode:string) => void, () => void ] {
    let dispatch = useDispatch()

    return [
        useSelector<RootState,DefaultCurrencyCodeState>(state => state.uiState.defaultCurrencyCode).currencyCode,
        (currencyCode) => dispatch(setDefaultCurrencyCode(currencyCode)),
        () => dispatch(clearDefaultCurrencyCode())
    ]
}