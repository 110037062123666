import {
    get, getFamily,
    NetworkState, NetworkStateFamily,
    networkStateReducer, networkStateReducerFamily, post, reset, resetFamily, resolveFamily,
} from "../networkStateReducer";

import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import { BanksResult, Customer } from ".";


export const domain = "@appigo-retailer/affiliate/get-service-status"

export const getAffiliateServiceStausReducer = networkStateReducerFamily<Boolean>(domain)

export function useGetAffiliateServiceStaus (family: string = 'Default') : [NetworkState<Boolean>, () => void,() => void] {
    let dispatch = useDispatch();

    return [
        resolveFamily(family, useSelector<RootState, NetworkStateFamily<Boolean>>(state => state.affiliate.getAffiliateServiceStaus)),
        () => dispatch(getFamily(domain, "/customer-api/v2/affiliate/status", family)),
        () => dispatch(resetFamily(domain, family))
    ]
}