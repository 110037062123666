import {
    isError,
    isSuccess,
    NetworkState,
    NetworkStateFamily,
    networkStateReducerFamily,
    postFamily,
    PromiseCallback,
    resetFamily,
    resolveFamily
} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {useEffect, useState} from "react";

export const domain = "@appigo-retailer/cart/file-upload"

export const fileUploadReducer = networkStateReducerFamily<string[]>(domain)

export function toFormData(files: File[]): FormData {
    let formData = new FormData();
    files.forEach(file => formData.append(file.name, file))
    return formData
}

export function useFileUpload(family: string = "Default"): [NetworkState<string[]>, (file: File[]) => void, () => void] {
    let dispatch = useDispatch();

    return [
        resolveFamily(family, useSelector<RootState, NetworkStateFamily<string[]>>(state => state.cart.fileUpload)),
        (file) => file.length && dispatch(postFamily(domain, "/customer-api/v2/resources/file/upload",family ,toFormData(file), {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })),
        () => dispatch(resetFamily(domain, family))
    ]
}

export function useClearFileUpload(family: string): () => void {
    const dispatch = useDispatch();
    return () => dispatch(resetFamily(domain,family))
}

export function useFileUploadPromise(family: string = "Default"): (file: File[]) => Promise<string[]> {
    let [promiseCallback, setPromiseCallback] = useState<PromiseCallback<string[]>>();

    let [state, fetch] = useFileUpload(family);
    let reset = useClearFileUpload(family);

    useEffect(() => {
        return reset
    }, [])

    useEffect(() => {
        if (isSuccess(state)) {
            promiseCallback?.resolve(state.data)
        } else if (isError(state)) {
            promiseCallback?.reject(state.error)
        }
    }, [state.state])

    return (req) => {
        return new Promise<string[]>((resolve, reject) => {
            if (!!promiseCallback) {
                promiseCallback.reject()
            }
            fetch(req)
            setPromiseCallback({
                resolve,
                reject
            })
        })
    }
}
