import {NetworkState, networkStateReducer, post, reset} from "../networkStateReducer";
import {Cart} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {Address} from "../profile/get";

export const domain = "@appigo-retailer/cart/set-shipping-address"

export const cartSetShippingAddressReducer = networkStateReducer<Cart>(domain)

export function useSetShippingAddressToCart(): [NetworkState<Cart>, (address: Address) => void, () => void] {

    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<Cart>>(state => state.cart.setShippingAddress),
        (address) => dispatch(post(domain, "/customer-api/v2/cart/set/shipping-address", address)),
        () => dispatch(reset(domain))
    ]
}