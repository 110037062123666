import {NetworkState, NetworkStateFamily} from "../networkStateReducer";
import {combineReducers} from "redux";
import {BrandSimpleView, ManagedBrand, PagedBrandSimpleView,} from "./types";
import {brandsList} from "./searchBrands";
import {findBrandReducer} from "./findBrand";
import QueryString from "query-string";
import {deleteBrandReducer} from "./delete";

export interface BrandState {
    find: NetworkStateFamily<ManagedBrand>
    list: NetworkStateFamily<PagedBrandSimpleView>
    delete: NetworkState<string>

}

export const brandReducers = combineReducers<BrandState>({
    list: brandsList,
    find: findBrandReducer,
    delete: deleteBrandReducer
})

interface BrandsSearchParams {
    req?: BrandSimpleView,
    page?: number,
    size?: number
}


export function useBrandsSearchQuery(): [BrandsSearchParams, (params: BrandsSearchParams) => string] {

    let url = "/collections/brands?"

    return [
        parsedBrandsSearchQueryParams(window.location.href),
        (params: BrandsSearchParams) => url + `${createBrandsSearchQueryParams(params)}`
    ]

}

export function parsedBrandsSearchQueryParams(url: string): BrandsSearchParams {
    let queryString = QueryString.parseUrl(url).query as any

    function getValue<T>(key: string): T {
        return (queryString[key] as T)
    }

    function getValueArray<T>(key:string): T[] {
        let value = getValue(key)
        if (!value) return []
        if (Array.isArray(value)) return value
        return [value as T]
    }

    return {
        req: {
            id: getValue("id"),
            name: getValue("name"),
            tags: getValueArray("tags")
        },
        page: getValue("page"),
        size: getValue("size")
    }

}

export function createBrandsSearchQueryParams(req: BrandsSearchParams): string {
    let params:any = {
        id: req.req?.id,
        name: req.req?.name,
        page: req.page,
        size: req.size,
        tags: req.req?.tags
    }

    return QueryString.stringify(params)
}