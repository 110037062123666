import React, {useEffect, useMemo, useState} from 'react';
import './App.css';
import {HelmetProvider} from 'react-helmet-async'
import {Provider} from "react-redux";
import {store} from "./reducers";
import {Route, Router} from "react-router-dom";
import {createBrowserHistory, History} from 'history'
import MainPage from "./view/Main/MainPage";
import {AuthProvider, parseCookie} from "./auth/AuthProvider";
import {BaseNameProvider, useBaseName} from "./connectors";
import * as Sentry from '@sentry/react'
import {ToastContainer} from "react-toastify";
import {I18nSupport} from "./i18n/I18nSupport";
import {configureI18n} from "./i18n/i18nMessages";
import {TrackingProvider} from "./tracking/trackingProvider";
import {useSchema} from "./reducers/schema";
import {isSuccess} from "./reducers/networkStateReducer";
import {pixelTracker} from "./tracking/pixelTracker";
import {googleAnalytics} from "./tracking/googleAnalytics";
import "react-datepicker/dist/react-datepicker.css"
import { useCookies } from 'react-cookie';
import {googleTagManager} from "./tracking/googleTagManager"
import {FbAnalyticsConfig, Ga4AnalyticsConfig, GtmAnalyticsConfig} from "./tracking/types";
function AppWithRoute() {

    const [schema, fetchSchema] = useSchema();
    const [pixelAnalytics, setPixelAnalytics] = useState<FbAnalyticsConfig>();
    const [googleAnalyticsConfig, setGoogleAnalyticsConfig] = useState<Ga4AnalyticsConfig>();
    const [googleTagManagerConfig, setGoogleTagManagerConfig] = useState<GtmAnalyticsConfig>();
    const [, setCookie, removeCookie] = useCookies(['RETAILER_AFF_SESSION']);
    const [sentryUrl, setSentryUrl] = useState<string | undefined>()

    useEffect(() => {
        fetchSchema()
    }, [])

    useEffect(() => {
        if (isSuccess(schema)) {
            setPixelAnalytics(schema.data.analyticsConfigurations?.facebookPixelConfigurations)
            setGoogleAnalyticsConfig(schema.data.analyticsConfigurations?.googleAnalyticsConfigurations)
            setGoogleTagManagerConfig(schema.data.analyticsConfigurations?.googleTagManagerConfigurations)
            setSentryUrl(schema.data.utilityConfigurations?.sentryApiKey)
        }
    }, [schema])

    const {baseName, initialized} = useBaseName();

    const history = useMemo<History<unknown>>(() => {
        return createBrowserHistory({
            basename: baseName
        });
    }, [baseName])

    useEffect(() => {
        Sentry.init({
            dsn: sentryUrl,
            debug: false,
        });
    }, [history, sentryUrl])

    useEffect(() => {
        const affiliateSessionId = new URLSearchParams(history.location.search)?.get('am_aid')
        if (affiliateSessionId == 'Invalid') sessionStorage.removeItem('RETAILER_AFF_SESSION')
        else if (affiliateSessionId) sessionStorage.setItem('RETAILER_AFF_SESSION', affiliateSessionId)

        const aff_session = sessionStorage.getItem('RETAILER_AFF_SESSION')
        if (aff_session) setCookie('RETAILER_AFF_SESSION', aff_session)
        else removeCookie('RETAILER_AFF_SESSION')
    }, [history])

    try {
        parseCookie(document.cookie)
    } catch (e) {
        return <>{JSON.stringify(e)}</>
    }

    return <TrackingProvider
        adaptors={[pixelTracker(pixelAnalytics), googleAnalytics(googleAnalyticsConfig), googleTagManager(googleTagManagerConfig)]}>
        <AuthProvider>
            {initialized &&
                <Router history={history}>
                    <Route path={"/**"} exact={true} component={MainPage}/>
                </Router>
            }
        </AuthProvider>
    </TrackingProvider>
}

function App() {

    console.error("Just A error Log");
    useEffect(() => {
        configureI18n({
            currencyCode: 'LKR'
        })
    }, [])

    return <HelmetProvider>
        <Provider store={store}>
            <BaseNameProvider>
                <I18nSupport>
                    <AppWithRoute/>
                </I18nSupport>
            </BaseNameProvider>
        </Provider>
        <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={true}
            pauseOnFocusLoss={true}
            draggable={true}
            pauseOnHover={true}
            // autoClose={false}
            // newestOnTop={true}
            // closeOnClick={false}
            // rtl={false}
            // pauseOnFocusLoss={false}
            // draggable={false}
        />
    </HelmetProvider>;
}

export default App;
