import {Action, combineReducers, Reducer} from "redux";

export interface InitConfig {
    baseName?: string,
    initialized: boolean
}

export interface SetBaseNameAction extends Action {
    type: "@appigo-retailer/set-base-name"
    baseName?: string
}

export function setBaseName(baseName?: string): SetBaseNameAction {
    return {
        baseName,
        type: "@appigo-retailer/set-base-name"
    }
}

function isSetBaseNameAction(action: Action): action is SetBaseNameAction {
    return action.type === "@appigo-retailer/set-base-name";
}

export const initConfigReducer: Reducer<InitConfig, SetBaseNameAction> = (state = { initialized: false}, action) => {
    if (isSetBaseNameAction(action)) {
        return {
            baseName: action.baseName,
            initialized: true
        }
    } else return state
}
