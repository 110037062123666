import {
    get, getFamily,
    NetworkState, NetworkStateFamily,
    networkStateReducer,
    networkStateReducerFamily,
    reset, resetFamily,
    resolveFamily
} from "../networkStateReducer";
import {Address} from "./get";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/profile/address/find-by-id"

export const findAddressByIdReducer = networkStateReducerFamily<Address>(domain)

export function useFindAddressById(family: string = "Default"):[NetworkState<Address>, (addressId?: string) => void, () => void] {
    const dispatch = useDispatch();

    return [
        resolveFamily(family, useSelector<RootState, NetworkStateFamily<Address>>(state => state.profile.findAddressById)),
        (addressId?: string) => dispatch(getFamily(domain, "/customer-api/v2/profile/find/address/{addressId}",family, {path:{"addressId": !!addressId ? addressId : undefined}})),
        () => dispatch(resetFamily(domain, family))
    ]

}