import {Product} from "../../../../../reducers/product/types";

export interface QuantityState {
    quantity: number
    available: boolean
    availableQuantity: number
}

export interface QuantityAction {
    type: "PRODUCT" | "QUANTITY"
}

export interface UpdateProduct extends QuantityAction {
    type: "PRODUCT"
    product: Product
}

export function updateProduct(product: Product): UpdateProduct {
    return {
        product,
        type: "PRODUCT"
    }
}

export function isUpdateProduct(action: QuantityAction): action is UpdateProduct {
    return action.type === "PRODUCT"
}

export interface UpdateQuantity extends QuantityAction {
    type: "QUANTITY"
    quantity: number
}

export function updateQuantity(quantity: number): UpdateQuantity {
    return {
        quantity,
        type: "QUANTITY"
    }
}

export function isUpdateQuantity(action: QuantityAction): action is UpdateQuantity {
    return action.type === "QUANTITY"
}

export function productStateReducer(prevState: QuantityState, action: QuantityAction): QuantityState {
    console.log({action, prevState})
    if (isUpdateProduct(action)) {
        return {
            quantity: 1,
            available: !!action.product.quantity && (action.product.availability ?? false),
            availableQuantity: action.product.quantity ?? 1
        }
    }
    if (isUpdateQuantity(action)) {
        return {
            ...prevState,
            quantity: Math.max(1, action.quantity),
            available: prevState.availableQuantity >= action.quantity
        }
    }
    return prevState;
}

export const initProductState: QuantityState = { quantity: 1, available: false, availableQuantity: 1 }
