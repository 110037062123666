import {
    getFamily,
    NetworkState,
    NetworkStateFamily,
    networkStateReducerFamily,
    resetFamily,
    resolveFamily
} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {ManagedPage} from "./types";

export const domain = "@appigo-retailer/page/find"

export const findReducer = networkStateReducerFamily<ManagedPage>(domain)

export function useFindPageById (family: string): [NetworkState<ManagedPage>, (id?: string) => void, () => void]{
let dispatch = useDispatch();

    return [
        resolveFamily(family, useSelector<RootState, NetworkStateFamily<ManagedPage>>(state => state.page.find)),
        (id) => dispatch(getFamily(domain, "/customer-api/v2/page/{id}", family, { path: {id}})),
        () => dispatch(resetFamily(domain, family))
    ]
}