import {components} from "../../__generated/api";
import {NetworkStateFamily} from "../networkStateReducer";
import {ManagedPage} from "../pages/types";
import {combineReducers} from "redux";
import {findReducer} from "../pages/findPage";
import {PageState} from "../pages";
import {findCityByIdReducer} from "./findCityById";
import {findCityReducer} from "./getCitiesById";
import {findCountryByIdReducer} from "./findCountryByName";

export type CityInfo = components["schemas"]["CityInfo"]
export type GeoCoordinates = components["schemas"]["GeoCoordinates"]

export type CitySearchInfo = components["schemas"]["CitySearchResult"]

export type CountryInfo = components["schemas"]["CountryInfo"]

export interface CityDetails {
    cityDetails: NetworkStateFamily<CitySearchInfo>
    cityDetailsById: NetworkStateFamily<CityInfo>
    countryInfoById: NetworkStateFamily<CountryInfo>
}

export const cityDetailsReducer = combineReducers<CityDetails>({
    cityDetails: findCityReducer,
    cityDetailsById: findCityByIdReducer,
    countryInfoById: findCountryByIdReducer
})


