import {NetworkState, networkStateReducer, post, reset} from "../networkStateReducer";
import {Cart} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/cart/set/set-order-due-date"

export const cartSetOrderDueDateReducer = networkStateReducer<Cart>(domain)

export function useSetSelectedOrderDueDate():[NetworkState<Cart>,(dueDate?: string, orderDueTime?: string, deliveryType?: string) => void, () => void] {
    let dispatch = useDispatch()

    return [
        useSelector<RootState, NetworkState<Cart>>(state => state.cart.setSelectedOrderDueDate),
        (dueDate, orderDueTime, deliveryType) => dispatch(post(domain, "/customer-api/v2/cart/set/selected-order-due-date",{},{ query:{ orderDueDate: dueDate ? dueDate : null, orderDueTime: orderDueTime ? orderDueTime : null, deliveryType: deliveryType ? deliveryType : null }})),
        () => dispatch(reset(domain))
    ]

}