import {get, NetworkState, networkStateReducer, post, reset} from "../networkStateReducer";
import {Cart} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {Address} from "../profile/get";

export const domain = "@appigo-retailer/cart/set-billing-address/web-ticketing"

export const cartSetBillingAddressReducer = networkStateReducer<Cart>(domain)

export function useSetBillingAddressToCart(): [NetworkState<Cart>, (email: string, address: Address) => void, () => void] {

    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<Cart>>(state => state.cart.setBillingAddress),
        (email, address) => {
            const billingRequest = { email, billingAddress: address };
            dispatch(post(domain, "/customer-api/v2/cart/set/billing-address/web-ticketing", billingRequest));
        },
        () => dispatch(reset(domain))
    ]
}
