import {get, NetworkState, networkStateReducer} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {OrderDetails} from "../order";

export const domain = "@appigo-retailer/complete-payment"

export const paymentCompleteReducer = networkStateReducer<String>(domain)

export function usePaymentComplete(): [NetworkState<OrderDetails>, () => void] {
    let dispatch = useDispatch();
    return [
        useSelector<RootState, NetworkState<OrderDetails>>(state => state.payment.paymentComplete),
        () => dispatch(get(domain, "/customer-api/v2/cart/complete-payment"))
    ];
}

