import {get, NetworkState, networkStateReducer, reset} from "../networkStateReducer";
import {Cart} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/cart/clear-cart"

export const clearCartReducer = networkStateReducer<Cart>(domain)

export function useClearCart(): [NetworkState<Cart>, () => void, () => void] {
    let dispatch = useDispatch()

    return [
        useSelector<RootState, NetworkState<Cart>>(state => state.cart.clearCart),
        () => dispatch(get(domain, "/customer-api/v2/cart/clear-cart")),
        () => dispatch(reset(domain))
    ]
}