import {
    get,
    NetworkState,
    networkStateReducer,
} from "./networkStateReducer";
import {components, operations} from "../__generated/api";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "./index";

export const domain = "@appigo-retailer/templates"

export const schemaReducer = networkStateReducer<TemplateReq>(domain)

export type TemplateReq = operations["schema"]
export type TemplateRes = components["schemas"]["CustomerWebSchema"]
export type ServiceConfiguration = components["schemas"]["ServiceConfiguration"]
export type SortProductsBy = components["schemas"]["MerchantGeneralSettings"]["sortProductsBy"]
export type InventoryLocations = components["schemas"]["InventoryLocations"]
export type InventoryLocation = components["schemas"]["InventoryLocation"]
export type SchedulerProfile = components["schemas"]["Profile"]
export type SchedulerProfileRule = components["schemas"]["Rule"]
export type BannerItem = components["schemas"]["BannerItem"]

export function useSchema(): [NetworkState<TemplateRes>, () => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<TemplateRes>>(state => state.schema),
        () => dispatch(get(domain, "/customer-api/v2/schema/get"))
    ];


}
