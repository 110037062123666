import {Action, Reducer} from "redux";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export interface MultiStoreState {
    enable: boolean
}

interface SetMultiStoreState extends Action {
    type: "@appigo-retailer-ui/multi-store--status"
    enable: boolean
}

function isSetMultiStoreState(action: Action): action is SetMultiStoreState {
    return action.type === "@appigo-retailer-ui/multi-store--status"
}

function setMultiStoreState(enable: boolean): SetMultiStoreState {
    return {
        enable,
        type: "@appigo-retailer-ui/multi-store--status"
    }
}

export const multiStoreReducer: Reducer<MultiStoreState, SetMultiStoreState> = (state = { enable: true }, action) =>
    isSetMultiStoreState(action) ? {enable: action.enable} : state

export function useMultiStore(): [boolean, (enable: boolean) => void] {
    const dispatch = useDispatch();
    return [
        useSelector<RootState, MultiStoreState>(state => state.uiState.multiStore).enable,
        enable => dispatch(setMultiStoreState(enable))
    ]
}