import {Link} from "react-router-dom";
import React from "react";
import {isSuccess, NetworkState} from "../../reducers/networkStateReducer";
import {useI18n, WithPlaceholders} from "../../i18n/I18nSupport";


interface PaginationProps {
    parseQueryFunc(url:string):any
    link(page:number):string
    items: NetworkState<any>
    retrievedItems:number
    totalItems: number,
    after?: number,
    pageSize: number
}

export function Pagination({parseQueryFunc, pageSize, items, link, totalItems, retrievedItems}: PaginationProps) {

    let {tx} = useI18n("searchResult");

    let query = parseQueryFunc(window.location.href)
    let after = !!query.after ? query.after : query.size

    if (!isSuccess(items)) {
        return null
    }

    const pages = (totalItems ? Math.floor(totalItems/ pageSize) : 0)
    let totalPages = totalItems % pageSize > 0  ? pages + 1 : pages
    const currPage =  after ? Math.floor(after / pageSize) : 1

    const pagesArray = [currPage - 2, currPage - 1, currPage, currPage + 1, currPage + 2].filter(page => page > 0 && page <= totalPages)

    function calculateCurrentItems(itemCount: number, after: number): number {
        return +itemCount + +after
    }

    if (totalPages <= 0 || (totalPages > 0 && totalItems <= pageSize)) {
        return <WithPlaceholders value={{
            totalItems,
            currentItems: calculateCurrentItems(retrievedItems, after)
        }}>
            <div className="row">
                <div className="col-sm-4">
                    <p className="mt-4">
                        {tx`countLabel`}
                    </p>
                </div>
            </div>
        </WithPlaceholders>
    }

    return <WithPlaceholders value={{
        totalItems,
        currentItems: calculateCurrentItems(retrievedItems, after)
    }}>
        <nav aria-label="Page navigation example">
            <div className="row">
                <div className="col-sm-4">
                    <p className="mt-4">
                        {tx`countLabel`}
                    </p>
                </div>
                <div className="col-sm-8">
                    <ul className="pagination justify-content-sm-end mt-3 mb-0">
                        <li className={`page-item ${currPage === 0 ? 'disabled' : ''}`}>
                            <Link to={link(0)} className="page-link" tabIndex={-1} aria-disabled="true">«</Link>
                        </li>
                        <li className={`page-item ${currPage === 0 ? 'disabled' : ''}`}>
                            <Link to={link(currPage - 1)} className="page-link" tabIndex={-1}
                                  aria-disabled="true">&lt;</Link>
                        </li>
                        {pagesArray.map(page => <li key={page}
                                                    className={`page-item ${(page - 1) === currPage ? 'active' : ''}`}>
                            <Link to={link(page - 1)} className="page-link">
                                {page}
                            </Link>
                        </li>)
                        }
                        <li className={`page-item ${currPage === totalPages - 1 ? 'disabled' : ''}`}>
                            <Link to={link(currPage + 1)} className="page-link">&gt;</Link>
                        </li>
                        <li className={`page-item ${currPage === totalPages - 1 ? 'disabled' : ''}`}>
                            <Link to={link(totalPages - 1)} className="page-link">»</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </WithPlaceholders>
}
