import {NetworkState, networkStateReducer, post, reset} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/brand/delete"

export const deleteBrandReducer = networkStateReducer<string>(domain)

export function useDeleteBrand(): [NetworkState<string>, (id: string) => void, () => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState,NetworkState<string>>(state => state.brand.delete),
        (id) => dispatch(post(domain, "/customer-api/v2/brand/delete", {}, {query: {id}})),
        () => dispatch(reset(domain))
    ]
}
