export function createImgProxyUrl(imageUrl: any, width: any, height: any) {

    let merchantId = !!imageUrl ? imageUrl.split("/")[2] : ""
    let imgName = !!imageUrl ? imageUrl.split("/")[3] : ""
    let domainPrefix = `http://retailer.imgs:80/${merchantId}/${imgName}`

    let modifiedImageUrl = domainPrefix

    const imgUrl = {
        signature : "appigoRetailer",
        resizing_type :"fit",
        gravity : "ce",
        enlarge : "1",
        plain : "plain",
        cropWidth: "0",
        cropHeight: "0",
        focusPointX: "0",
        focusPointY: "0",
        extend:"1",
        red:"255",
        green:"255",
        blue:"255"
    }

    const hostUrl = "/img-proxy";
    const base64encodedImage = !!modifiedImageUrl ? btoa(modifiedImageUrl) : modifiedImageUrl

    return `${hostUrl}/${imgUrl.signature}/rs:${imgUrl.resizing_type}:${width}:${height}:${imgUrl.enlarge}/g:fp:${imgUrl.focusPointX}:${imgUrl.focusPointY}/ex:${imgUrl.extend}/bg:${imgUrl.red}:${imgUrl.green}:${imgUrl.blue}/${base64encodedImage}.jpg`
}
