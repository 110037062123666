import {NetworkState, networkStateReducer, post} from "../networkStateReducer";
import {Cart} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/cart/set/email"

export const cartSetEmailReducer = networkStateReducer<Cart>(domain)

export function useCartSetEmail(): [NetworkState<Cart>, (email: string) => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<Cart>>(state => state.cart.setEmail),
        (email) => dispatch(post(domain, "/customer-api/v2/cart/set/email", email))
    ]
}
