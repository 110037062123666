import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { definition as faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { Link, useHistory } from "react-router-dom";
import { useSchema } from "../../../../reducers/schema";
import { isSuccess } from "../../../../reducers/networkStateReducer";
import { useUserInfo } from "../../../../reducers/user/userInfo";
import { useLogoutCallback } from "../../../../reducers/user/logout";
import { useKeycloak } from "@react-keycloak/web";
import { definition as faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import Button from "react-bootstrap/Button";
import { definition as faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { useMobileMenuState } from "../../../../reducers/ui/mobilemenu";
import { ProfileRes, useProfileGet } from "../../../../reducers/profile/get";
import { useHeaderHeightState } from "../../../../reducers/ui/HeaderHeight";
import { useI18n, WithPlaceholders } from "../../../../i18n/I18nSupport";
import { useCartSetPromoCode } from "../../../../reducers/cart/setPromoCode";
import { definition as faHeart } from "@fortawesome/free-solid-svg-icons/faHeart";
import { LanguageSelection } from "../MenuBar_Old/LanguageSelection";
import { isMenuWithItems, Menu } from "./NavMenuItems";
import { definition as faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { definition as faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { StoreSelection } from "../TopBar/StoreSelection";
import { useLocalStorage } from "../../Body/Checkout/CheckoutFlowRevamp/utils";
import { CurrencySelection } from "../TopBar/CurrencySelection";
import {Promos} from "../../Body/Home/Promos/Promos";
import {PromoSection} from "../../Body/Home/Home";
import {components} from "../../../../__generated/api";



type SectionType = components["schemas"]["Section"]["type"]

interface Section {
    type: SectionType
}

function MobileLogin() {

    let [, setMobileMenuState] = useMobileMenuState()
    const history = useHistory()
    const [userInfo] = useUserInfo();
    const [, logoutCallback] = useLogoutCallback();
    const { keycloak } = useKeycloak();
    const [profile, fetchProfile] = useProfileGet()
    const [profileData, setProfileData] = useState<ProfileRes>()
    const [result, setPromoCode] = useCartSetPromoCode();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };

    function handleClick() {
        history.push("/account/")
        setMobileMenuState(false)
    }

    useEffect(() => {
        if (keycloak.authenticated && (isSuccess(profile) && profile.data.merchantId == null)) {
            fetchProfile()
        }
    }, [])

    useEffect(() => {
        if (isSuccess(profile)) {
            setProfileData(profile.data)
        }
    }, [profile])

    if (keycloak.authenticated) {
        return  <div className="dropdown" style={{ borderRadius: "10px"}}>
            <button
                className="custom-mobile-login-button"
                type="button"
                id="dropdownMenuButton"
                aria-haspopup="true"
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    objectFit: "contain",
                }}
                aria-expanded={isDropdownOpen ? "true" : "false"}
                onClick={toggleDropdown}
            >
                <FontAwesomeIcon icon={faUser} size={"lg"} />
                &nbsp;
                {profileData?.userName ?? "User"}
                &nbsp;
                <FontAwesomeIcon icon={faAngleDown} size={"lg"} style={{ alignContent: "flex-end" }} />
            </button>
            {/*<div className={`dropdown-menu w-100 shadow ${isDropdownOpen ? "show" : ""}`} aria-labelledby="dropdownMenuButton">*/}
            {isDropdownOpen &&  <div className={`dropdown-menu w-100 shadow}`}>
                <Button onClick={() => { handleClick(); closeDropdown(); }} className="btn btn-primary rounded-0 w-100 dropdown-item">
                    <FontAwesomeIcon icon={faUser} size={"lg"} /> Account
                </Button>
                <Button className="btn btn-primary rounded-0 w-100 dropdown-item" onClick={() => {
                    logoutCallback();
                    keycloak.logout();
                    closeDropdown();
                }}>
                    <FontAwesomeIcon icon={faSignOutAlt} size={"lg"} /> Logout
                </Button>
            </div>
            }
        </div>
    } else {
        return <Link to={"/login"}>
            <button className="btn btn-primary rounded-0 w-100" type="submit" onClick={(e) => {
                setPromoCode(undefined)
                keycloak.login()
            }}>
                <FontAwesomeIcon icon={faUser} size={"lg"} /> Login
            </button>
        </Link>;

    }
}

interface MobileMenuItemProps {
    menu: Menu
    opened: string[]
    setOpened: (opened: string[]) => void
}

function MobileMenuItem({ menu, setOpened, opened }: MobileMenuItemProps) {

    const history = useHistory()
    let [mobileMenuState, setMobileMenuState] = useMobileMenuState()
    let { tx } = useI18n('header.menu');

    function handleClick(link: string) {
        history.push(link)
        if (mobileMenuState) {
            setMobileMenuState(!mobileMenuState)
        }
        // window.location.reload()
    }

    return <WithPlaceholders value={{ menu }}>
        <div className="accordion" id="accordionCategory1">
            <a className={`mobile-menu-list__item-link btn-toggle ${(opened?.filter((item) => item === menu.name?.en)?.length > 0) ? '' : 'collapsed'}`} type="button"
                data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
                aria-controls="collapseOne" onClick={() => {
                    if (isMenuWithItems(menu) && menu?.menuItems?.length > 0) {
                        if (opened?.some((item) => item === menu.name?.en)) {
                            let filtered = opened?.filter((item) => item !== menu.name?.en)
                            setOpened(filtered)
                        } else {
                            setOpened([...opened, menu.name?.en])
                        }
                    } else {
                        handleClick(menu.link)
                    }
                }}> {tx`name`}
            </a>
        </div>
        <div className={"mobile-menu-list-sub-list"}>
            <div id="collapseOne" className={`collapse ${(opened?.filter((item) => item === menu.name?.en)?.length > 0) ? 'show' : ''}`} aria-labelledby="headingOne" data-parent="#accordionCategory1">
                <ul className="mobile-menu-list-sub-list__item">
                    {isMenuWithItems(menu) && menu.menuItems?.map((item: { link: string; }, key: string | number | null | undefined) => <WithPlaceholders key={key} value={{ menu: item }}>
                        <li key={key} style={{ listStyleType: "circle" }}
                            onClick={(e) => handleClick(item.link)}>
                            <span className={" mobile-menu-list-sub-list__item-link"}>{tx`name`}</span>
                        </li>
                    </WithPlaceholders>)
                    }
                </ul>
            </div>
        </div>

    </WithPlaceholders>

}

interface MobileMenuProps {
    isMobileMenuClicked?: boolean
    handleMobileMenuClick?: (isMobileMenuClicked: boolean) => void
    // @ts-ignore
    menus?: Menu[],
    popupHeight?: number
}

export default function NavMobileMenu({ menus, popupHeight }: MobileMenuProps) {

    let [schema] = useSchema();
    const [open, doOpen] = useState(false)
    const history = useHistory()
    let [mobileMenuState,setMobileMenuState] = useMobileMenuState()
    let [opened, setOpened] = useState<string[]>([]);
    const ref = useRef<HTMLDivElement>(null)
    let [height] = useHeaderHeightState()
    let [style, setStyle] = useState({ maxHeight: `calc(100vh - ${height}px)` })
    const [getItem] = useLocalStorage<{ inventoryId: string }>();

    function handleClick(link: string) {
        history.push(link)
        setMobileMenuState(false)
    }

    function isPromoSection(section: Section): section is PromoSection {
        return section.type === "PROMO"
    }

    useEffect(() => {
        setStyle({
            maxHeight: `calc(100vh - ${!!popupHeight ? height - popupHeight : height}px)`
        })
    }, [height, popupHeight])


    if (!isSuccess(schema)) return null

    return (
        <div className={`mobile-menu d-lg-none d-block  ${mobileMenuState && 'active'}`} ref={ref} style={style}>
            <div className="row no-gutters mb-5">
                <div className=" custom-mobile-nav-dropdown col-12 " style={{borderRadius:"10px"}}>
                    <MobileLogin />
                    {/*{!!getItem('inventoryId') && <StoreSelection />}*/}
                    {/*<LanguageSelection />*/}
                    {/*<CurrencySelection />*/}
                </div>
            </div>
            <div className="mobile-menu-section">
                <ul className="mobile-menu-list common-list">
                    <li className="mobile-menu-list__item">
                        {
                            schema.data.header.menus.map((menu, key) => {
                                return <MobileMenuItem
                                    key={key}
                                    opened={opened}
                                    menu={menu}
                                    setOpened={setOpened}
                                />
                            })
                        }
                    </li>
                </ul>
            </div>

            <h2 className="mobile-nav-heading">Categories</h2>
            {schema.data.sections.homePageSections.map((section, index) => {
                if (isPromoSection(section)) {
                    return (
                        <ul key={index} className="promo-nav-list">
                            {section.items?.map((promo, idx) => (
                                <li key={idx} className="promo-nav-item"
                                    onClick={() => handleClick(promo.link)}
                                >
                                    {/*<Link to={promo.link}>*/}
                                        {promo.details?.en || "Promo"}
                                    {/*</Link>*/}
                                </li>
                            ))}
                        </ul>
                    );
                }
            })}



        </div>
    )
}

