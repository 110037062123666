import {
    get,
    NetworkState,
    networkStateReducer,
} from "./networkStateReducer";
import {components, operations} from "../__generated/api";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "./index";

export const domain = "@appigo-retailer/outlet"

export const outletReducer = networkStateReducer<OutletRes>(domain)

export type OutletReq = operations["schema"]
export type OutletRes = components["schemas"]["Outlets"]

export type OpenHourType = components["schemas"]["OpenHours"]
export type Outlet = components["schemas"]["Outlet"]

export function useOutlets(): [NetworkState<OutletRes>, () => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<OutletRes>>(state => state.outlet),
        () => dispatch(get(domain, "/customer-api/v2/outlet/get"))
    ];


}
