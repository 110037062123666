import {get, NetworkState, networkStateReducer, post} from "../networkStateReducer";
import {OrderDetails} from "./index";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {components} from "../../__generated/api";
import QueryString from "query-string";

export type OrderSearchReq = components["schemas"]["OrderSearchReq"]
export type OrderSearchResponse = components["schemas"]["PurchasableProductV2OrderSearchResponse"]

export const domain = "@appigo-retailer/ongoing-order"

export const ongoingOrderReducer = networkStateReducer<OrderSearchReq>(domain)

export function useGetOngoingOrders(): [NetworkState<OrderSearchResponse>, (orderSearchReq: OrderSearchReq) => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<OrderSearchResponse>>(state => state.order.ongoing),
        (orderSearchReq) => dispatch(post(domain, "/customer-api/v2/order/get-orders-ongoing", orderSearchReq))
    ]
}

export type QueryType = 'ONGOING' | 'COMPLETED'

export function useOrderSearchQuery(type: QueryType) : [OrderSearchReq, (orderSearchReq: OrderSearchReq) => string] {

    let url: string =""

    switch (type){
        case 'ONGOING': url = `/account/history/ongoing-orders`
            break;
        case 'COMPLETED': url = `/account/history/past-orders`
            break;
        default: url = `/account/history/ongoing-orders`
    }

    return [
        parseOrderQueryParams(window.location.href),
        (orderSearchReq: OrderSearchReq) => `${url}?${createOrderQueryParams(orderSearchReq)}`
    ]
}

export function parseOrderQueryParams(url : string) : OrderSearchReq {
    let query = QueryString.parseUrl(url).query as any

    function getValue<T>(key: string): T {
        return (query[key] as T);
    }

    return {
        first: getValue("first"),
        after: getValue("after"),
        userId: getValue("userId")
    }
}

export function createOrderQueryParams(req: OrderSearchReq) : string {
    let searchReq: any  = {
        first: req.first,
        after: req.after,
        userId: req.userId
    }

    return QueryString.stringify(searchReq)
}
