import {combineReducers} from "redux";
import {Product, ProductConnection} from "./types";
import {searchReducer} from "./search";
import {NetworkState, NetworkStateFamily} from "../networkStateReducer";
import {getProductReducer} from "./get";
import {getProductFromSlugReducer} from "./getFromSlugs";

export interface ProductState {
    search: NetworkStateFamily<ProductConnection>
    get: NetworkStateFamily<Product>
    getFromSlugs: NetworkStateFamily<Product>
}

export const productReducer = combineReducers<ProductState>({
    search: searchReducer,
    get: getProductReducer,
    getFromSlugs: getProductFromSlugReducer
})
