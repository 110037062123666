import {
    getFamily,
    NetworkState,
    NetworkStateFamily,
    networkStateReducerFamily, postFamily, reset, resetFamily,
    resolveFamily
} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

import QueryString from "query-string";
import {ProductConnection, SearchReq} from "./types";

export const domain = "@appigo-retailer/product/search"

export const searchReducer = networkStateReducerFamily<ProductConnection>(domain)

export function useSearch(family: string): [NetworkState<ProductConnection>, (query: SearchReq) => void, () => void] {
    let dispatch = useDispatch();

    return [
        resolveFamily(family, useSelector<RootState, NetworkStateFamily<ProductConnection>>(state => state.product.search)),
        query => dispatch(postFamily(domain, "/customer-api/v2/product/search", family, query)),
        () => dispatch(resetFamily(family,domain))
    ]
}

export function useSearchQuery(): [SearchReq, (searchReq: SearchReq) => string] {
    return [
        parseQueryParams(window.location.href),
        (searchReq: SearchReq) => `/search?${createQueryParams(searchReq)}`
    ]
}

export function parseQueryParams(url: string): SearchReq {
    let query = QueryString.parseUrl(url ?? "").query as any;

    function getValue<T>(key: string): T | undefined {
        return (query[key] as T);
    }

    function getValueArray<T>(key: string): T[] {
        let value = getValue(key)
        if (!value) return [];
        if (Array.isArray(value)) return value
        return [value as T]
    }

    let ranges: { [k: string]: number[]} = {}
    Object.entries(query).filter(([k, v]) => k.startsWith('r_'))
        .forEach(([k, v]) => {
            try {
                let range = (v as any).toString().split(',').map((a: string) => parseFloat(a));
                ranges[k.replace(/^r_/, '')] = range
            } catch (e) {

            }
        })

    return {
        after: getValue("after"),
        first: getValue("first"),
        query: getValue("query"),
        related: getValueArray("related"),
        sortBy: getValue("sortBy"),
        tags: getValueArray("tags"),
        ranges: ranges,
        source: "search"
    }
}

export function createQueryParams(req: SearchReq): string {
    let stringifiableSearchReq: any = {
        after: req.after,
        first: req.first,
        query: req.query,
        related: req.related,
        sortBy: req.sortBy,
        tags: req.tags
    }
    for (let rangesKey in req.ranges) {
        stringifiableSearchReq[`r_${rangesKey}`] = req.ranges[rangesKey].join(',')
    }

    return QueryString.stringify(stringifiableSearchReq);
}
