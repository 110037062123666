import {Action, Reducer} from "redux";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export interface SearchBarState {
    open: boolean
}

interface SetSearchBarState extends Action {
    type: "@appigo-retailer-ui/set-search-status"
    open: boolean
}

function isSetSearchBarState(action: Action): action is SetSearchBarState {
    return action.type === "@appigo-retailer-ui/set-search-status"
}

function setSearchBarState(open: boolean): SetSearchBarState {
    return {
        open,
        type: "@appigo-retailer-ui/set-search-status"
    }
}

export const searchBarStatusReducer: Reducer<SearchBarState, SetSearchBarState> = (state = { open: false }, action) =>
    isSetSearchBarState(action) ? {open: action.open} : state

export function useSearchBarState(): [boolean, (open: boolean) => void] {
    const dispatch = useDispatch();
    return [
        useSelector<RootState, SearchBarState>(state => state.uiState.searchBar).open,
        open => dispatch(setSearchBarState(open))
    ]
}