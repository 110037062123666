import {get, NetworkState, networkStateReducer, reset,} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {Address} from "./get";

export const domain = "@appigo-retailer/profile/get/addresses"

export const getAddressesReducer = networkStateReducer<Address[]>(domain)

export function useGetUserAddresses(): [NetworkState<Address[]>, () => void, () => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<Address[]>>(state => state.profile.getAddresses),
        () => dispatch(get(domain, "/customer-api/v2/profile/update/get/addresses")),
        () => dispatch(reset(domain))
    ]
}
