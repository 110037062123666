import {NetworkState, networkStateReducer, post, reset} from "../networkStateReducer";
import {ConsumerProfileReq} from "./updateBillingAddress";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/profile/update-default-shipping-address"

export const updateDefaultShippingAddressReducer = networkStateReducer<ConsumerProfileReq>(domain)

export function useUpdateDefaultShippingAddress():[NetworkState<ConsumerProfileReq>, (addressId?: string) => void, () => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<ConsumerProfileReq>>(state => state.profile.updateDefaultShippingAddress),
        (addressId) => dispatch(post(domain, "/customer-api/v2/profile/update/default-shipping-address/{addressId}",{}, {path:{addressId: !!addressId ? addressId : undefined}})),
        () => dispatch(reset(domain))
    ]
}