
import {useEffect, useState} from "react";
import {
    isError,
    isSuccess, NetworkState,
    NetworkStateFamily,
    networkStateReducerFamily, postFamily,
    PromiseCallback,
    resetFamily,
    resolveFamily
} from "../networkStateReducer";
import {BrandSimpleView, PagedBrandSimpleView} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/brands/search"

export const brandsList = networkStateReducerFamily<PagedBrandSimpleView>(domain)

export function useBrandList(family: string = "Default"): [NetworkState<PagedBrandSimpleView>, (req: BrandSimpleView, page?: number, size?: number) => void, () => void] {
    const dispatch = useDispatch();

    return [
        resolveFamily(family, useSelector<RootState, NetworkStateFamily<PagedBrandSimpleView>>(state => state.brand.list)),
        (req, page, size) => dispatch(postFamily(domain, '/customer-api/v2/brand/search', family, req, {query: {...(page ? {page} : {}), ...(size ? {size} : {})}})),
        () => dispatch(resetFamily(domain, family))
    ]
}

export function useBrandListPromise(family: string = "Default"): (req: BrandSimpleView, page?: number, size?: number) => Promise<PagedBrandSimpleView> {
    let [promiseCallback, setPromiseCallback] = useState<PromiseCallback<PagedBrandSimpleView>>();

    let [state, fetch, reset] = useBrandList(family);

    useEffect(() => {
        return reset
    }, [])

    useEffect(() => {
        if (isSuccess(state)) {
            promiseCallback?.resolve(<PagedBrandSimpleView>state.data)
        } else if (isError(state)) {
            promiseCallback?.reject(state.error)
        }
    }, [state.state])

    return (req, page, size) => {
        return new Promise<PagedBrandSimpleView>((resolve, reject) => {
            if (!!promiseCallback) {
                promiseCallback.reject()
            }
            reset()
            fetch(req, page, size)
            setPromiseCallback({
                resolve,
                reject
            })
        })
    }
}
