import {Middleware} from "redux";
import {get} from "../networkStateReducer";
import {domain as getDomain} from "./cardList";
import {domain as deleteDomain} from "./deleteCard";

const managedCardReloadActions = [deleteDomain].map(a => new RegExp(`^${a}_(SUCCESS|FAIL)(.*)`))

export const managedCardMiddleware: Middleware = api => next => action => {
    if (!!managedCardReloadActions.find(a => a.test(action.type))) {
        api.dispatch(get(getDomain, "/customer-api/v2/card/list"))
    }
    return next(action);
}
