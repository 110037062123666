import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./resources/css/style.css"
import './resources/css/fontawesome-free-5.14.0-web/css/all.css'
import "./resources/css/animate.css"
import "./resources/css/daterangepicker.css"
import * as Sentry from '@sentry/react'
import 'intl'
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css'
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';

ReactDOM.render(
    <Sentry.ErrorBoundary fallback={"An error occurred" as any}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Sentry.ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
