import {Action, Reducer} from "redux";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export interface QuickSearchPopupState {
    open: boolean,
    top: number
}

interface SetQuickSearchPopupState extends Action {
    type: "@appigo-retailer-ui/set-quick-search-popup-status"
    open: boolean,
    top: number
}

function isSetSearchBarState(action: Action): action is SetQuickSearchPopupState {
    return action.type === "@appigo-retailer-ui/set-quick-search-popup-status"
}

function setSearchBarState(open: boolean, top: number = 0): SetQuickSearchPopupState {
    return {
        open,
        top,
        type: "@appigo-retailer-ui/set-quick-search-popup-status"
    }
}

export const quickSearchPopupReducer: Reducer<QuickSearchPopupState, SetQuickSearchPopupState> = (state = { open: false, top: 0 }, action) =>
    isSetSearchBarState(action) ? {open: action.open, top: action.top} : state

export function useQuickSearchPopupState(): [{ open: boolean, top: number }, (open: boolean, top: number) => void] {
    const dispatch = useDispatch();
    return [
        useSelector<RootState, QuickSearchPopupState>(state => state.uiState.quickSearchPopup),
        (open, top) => dispatch(setSearchBarState(open, top))
    ]
}
