import {Action, Reducer} from "redux";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export interface CountryCodeState {
    code?: string
}

interface SetCountryCodeAction extends Action {
    type: "@appigo-retailer-ui/set-country-code"
    code?: string
}

interface ClearCountryCodeAction extends Action {
    type: "@appigo-retailer-ui/clear-country-code"
}

function isSetCountryCodeAction(action: Action): action is SetCountryCodeAction {
    return action.type === "@appigo-retailer-ui/set-country-code"
}

function isClearCountryCodeAction(action: Action): action is ClearCountryCodeAction {
    return action.type === "@appigo-retailer-ui/clear-country-code"
}


function setCountryCode(code?: string): SetCountryCodeAction {
    return {
        code,
        type: "@appigo-retailer-ui/set-country-code"
    }
}

function clearCountryCode(): ClearCountryCodeAction {
    return {
        type: "@appigo-retailer-ui/clear-country-code"
    }
}

export const countryCodeStateReducer: Reducer<CountryCodeState, SetCountryCodeAction> =
    (state = {code: undefined}, action) => {
        if(isSetCountryCodeAction(action)) {
            return {code: action.code}
        } else if (isClearCountryCodeAction(action)) {
            return state
        }

        return state
}

export function useCountryCode(): [(string | undefined), (code?: string) => void, () => void] {
    let dispatch = useDispatch()

    return [
        useSelector<RootState, CountryCodeState>(state => state.uiState.countryCodeState).code,
        (code) => dispatch(setCountryCode(code)),
        () => dispatch(clearCountryCode())
    ]
}