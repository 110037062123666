import React, {Suspense, useEffect, useState} from "react";
import {Route, useLocation} from "react-router-dom";
import Switch from "react-bootstrap/Switch";
import {useSearchBarState} from "../../../reducers/ui/searchBar";
import {useSchema} from "../../../reducers/schema";
import {isSuccess} from "../../../reducers/networkStateReducer";
import {useHeaderHeightState} from "../../../reducers/ui/HeaderHeight";
import {Loader} from "../../common/Loader";

import {useTracking} from "../../../tracking/trackingProvider";
import {useLocalStorage} from "./Checkout/CheckoutFlowRevamp/utils";
import {useSetInventoryLocation} from "../../../reducers/cart/setInventoryLocation";
import {useCart} from "../../../reducers/cart/get";


const Home = React.lazy(() => import("./Home/Home"))
const SearchResult = React.lazy(() => import("./SearchResult/SearchResult"))
const ProductInfo = React.lazy(() => import("./Product/Product"))
const Cart = React.lazy(() => import("../../Main/Body/Cart/CartView"))
const Checkout = React.lazy(() => import("./Checkout/CheckoutFlowRevamp/Checkout"))
const Account = React.lazy(() => import("./Account/Account"))
const OrderView = React.lazy(() => import("./Order/OrderView"))
const IpgComplete = React.lazy(() => import("./IpgComplete/IpgComplete"))
const OrderError = React.lazy(() => import("./OrderError/OrderError"))
const Page = React.lazy(() => import("./Pages/Page"))
const MessengerChatHead = React.lazy(() => import("./Messenger/MessengerChatHead"))
const GrabYoursPage = React.lazy(() => import("./Pages/GrabYoursPage/GrabYoursPage"))

export function Body() {
    const location = useLocation();
    let [schema] = useSchema()
    let [headerHeight,] = useHeaderHeightState()
    let [image, setImage] = useState("")
    const [isEnable, setIsEnable] = useState(false);
    let {pathname} = useLocation();
    const {track} = useTracking();

    let [getItem] = useLocalStorage<{ inventoryId: string }>();
    let [response, setInventoryLocation, resetInventoryLocationSetter] = useSetInventoryLocation();
    const [cartRes, getCart, resetCart] = useCart("cart-for-inventory-location-check");

    useEffect(() => {
        if (isSuccess(schema) && !!schema.data.inventoryLocations?.length && getItem("inventoryId")?.inventoryId) {
            console.log(`Updating cart with inventory id ${getItem("inventoryId")?.inventoryId}`);
            setInventoryLocation(getItem("inventoryId")?.inventoryId)
        }

    }, [schema, getItem("inventoryId")?.inventoryId])

    useEffect(() => {
        if((isSuccess(cartRes) && !cartRes.data)) {
            getCart()
        }
    }, [])

    useEffect(() => {
        if (isSuccess(cartRes) && !cartRes.data.inventoryLocation && getItem("inventoryId")?.inventoryId) {
            setInventoryLocation(getItem("inventoryId")?.inventoryId)
        }
    }, [cartRes, getItem("inventoryId")?.inventoryId])

    useEffect(() => {
        track({
            type: "PageView",
            data: {
                path: pathname
            }
        })
        window.scrollTo(0, 0)
    }, [location])

    useEffect(() => {
        if (isSuccess(schema)) {
            let _schema = schema.data
            if (_schema.backgroundType == "image") {
                setImage(_schema.backgroundImage ?? "")
            }

            setIsEnable(_schema.messengerConfigurations?.isEnabled ?? false)

        }
    }, [schema])

    const [open] = useSearchBarState();

    if (!isSuccess(schema)) return null

    return <div className={`${open ? "search-active" : ""} appigo-body pt-md-0 pt-0`} style={{
        backgroundImage: "url(" + "\"" + image + "\"" + ")",
        overflow: "hidden",
        // backgroundSize: "100% 100%",
        height: "100%",
        width: "100%",
        minHeight:"90vh"
    }}>
        <Suspense fallback={<Loader/>}>
            <Switch style={{paddingLeft: 0, marginTop: (headerHeight)}}>
                <Route path={"/search"} location={location} component={SearchResult}/>
                <Route path={"/product/:productId"} location={location} component={ProductInfo}/>
                <Route path={"/products/:productSlug"} location={location} component={ProductInfo}/>
                <Route path={"/cart"} location={location} exact={true} component={Cart}/>
                <Route path={"/checkout"} location={location} exact={true} component={Checkout}/>
                <Route path={"/order/view/:orderId"} location={location} exact={true} component={OrderView}/>
                <Route path={"/account/*"} location={location} component={Account}/>
                <Route path={"/ipg-complete"} location={location} component={IpgComplete}/>
                <Route path={"/order-error"} location={location} component={OrderError}/>
                <Route path={'/information/page/:pageId'} component={Page}/>
                <Route path={'/brands'} component={GrabYoursPage}/>
                {/*<Route path={"/"}  location={location} exact={true} component={MultiStoreSelection}/>*/}
                <Route path={"/"} exact={true} component={Home}/>
            </Switch>
        </Suspense>

        <div style={{backgroundColor: "orange"}}>

            {isSuccess(schema) && isEnable &&
                <Suspense fallback={<></>}>
                    <MessengerChatHead pageId={schema.data.messengerConfigurations?.pageId}
                                       language={schema.data.messengerConfigurations?.language}
                                       themeColorHex={schema.data.messengerConfigurations?.themeColor}
                                       bottomSpacing={40}
                                       loggedInGreeting={schema.data.messengerConfigurations?.welcomeMessage}
                                       loggedOutGreeting={schema.data.messengerConfigurations?.welcomeMessage}
                                       debugMode={false}/>
                </Suspense>
            }
        </div>
    </div>
}
