import {TrackingAdaptor, TrackingEvent} from "./trackingProvider";
import TagManager from '@sooro-io/react-gtm-module'
import {GtmAnalyticsConfig} from "./types";


export interface ItemType {
    item_id?: string | null;
    item_name?: string | null;
    affiliation?: string | null;
    coupon?: string | null;
    discount?: number | null;
    index?: number | null;
    item_brand?: string | null;
    item_category?: string | null;
    item_category2?: string | null;
    item_category3?: string | null;
    item_category4?: string | null;
    item_category5?: string | null;
    item_list_id?: string | null;
    item_list_name?: string | null;
    item_variant?: string | null;
    location_id?: string | null;
    price?: number | null;
    quantity?: number | null;
}


export function googleTagManager(googleTagAnalytics: GtmAnalyticsConfig | undefined): TrackingAdaptor {

    if (!!googleTagAnalytics && googleTagAnalytics.isEnabled && !!googleTagAnalytics.gtmTrackingId) {
        const tagManagerArgs = {
            gtmId: googleTagAnalytics.gtmTrackingId
        }
        console.log("Initiate Google Tag Manager in googleTagManager.ts,", googleTagAnalytics)
        TagManager.initialize(tagManagerArgs);
    }

    function sendGTMEvent(
        eventName: string,
        eventData: { [p: string]: any }
    ) {
        console.log("Google GTM event", eventName, "EventData:", eventData)
        TagManager.dataLayer({dataLayer: {event: eventName, eventData: eventData}});
    }


    return {
        track(trackingEvent: TrackingEvent) {
            console.log("Tracking Event: ", trackingEvent, "GTM Config: ", googleTagAnalytics)
            if (!!googleTagAnalytics && (!googleTagAnalytics.isEnabled || !googleTagAnalytics.isBuiltInTracking)) {
                console.log("Pixel is disabled. Skipping tracking event: ", trackingEvent)
                return;
            }
            switch (trackingEvent.type) {
                case "PageView":
                    TagManager.dataLayer({
                        dataLayer: {
                            event: "pageview",
                            page: window.location.pathname + window.location.search,
                            title: document.title
                        }
                    });

                    // ReactGA.pageview(window.location.pathname);
                    break;
                case "Search":
                    // ReactGA.event({category: 'User', action: 'search'}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    sendGTMEvent('search', trackingEvent.data)
                    break;
                case "CustomizeProduct":
                    // ReactGA.event({category: 'User', action: "customize_product"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    sendGTMEvent("customize_product", trackingEvent.data)
                    break;
                case "AddToCart":
                    // ReactGA.event({category: 'User', action: "add_to_cart"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    sendGTMEvent("add_to_cart", trackingEvent.data)
                    break;
                case "InitiateCheckout":
                    // ReactGA.event({category: 'User', action: "begin_checkout"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    sendGTMEvent("begin_checkout", trackingEvent.data)
                    break;
                case "AddShippingDetails":
                    sendGTMEvent("proceeded_to_shipping_details", trackingEvent.data)
                    // ReactGA.event({category: 'User', action: "proceeded_to_shipping_details"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    break;
                case "PaymentAndBilling":
                    sendGTMEvent("proceeded_to_payment_and_billing", Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]))
                    // ReactGA.event({category: 'User', action: "proceeded_to_payment_and_billing"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    break;
                case "PromoApply":
                    sendGTMEvent("promo_apply", trackingEvent.data)
                    // ReactGA.event({category: 'User', action: "promo_apply"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    break;
                case "PlaceOrderCOD":
                    sendGTMEvent("place_order_cod", trackingEvent.data)
                    // ReactGA.event({category: 'User', action: "place_order_cod"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    break;
                case "PlaceOrderCard":
                    sendGTMEvent("place_order_card", trackingEvent.data)
                    // ReactGA.event({category: 'User', action: "place_order_card"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    break;
                case "IPGFailure":
                    // ReactGA.event({category: 'User', action: "ipg_failure"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    sendGTMEvent("ipg_failure", trackingEvent.data)
                    break;
                case "ViewContent":
                    sendGTMEvent("view_item", trackingEvent.data)
                    break;
                case "PaymentCompleteCOD":
                    // ReactGA.event({category: 'User', action: "purchase"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    sendGTMEvent("payment_success_cod", trackingEvent.data)
                    break;
                case "PaymentComplete":
                    sendGTMEvent("payment_success", trackingEvent.data)
                    // ReactGA.event({category: 'User', action: "purchase"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    break;
                case "Purchase-GA":
                    // ReactGA.event({category: 'User', action: "purchase"}, Object.keys(trackingEvent.data).map((key) => trackingEvent.data[key]));
                    sendGTMEvent("purchase", trackingEvent.data)
                    break;
            }

        }
    }

}
