import {
    getFamily,
    NetworkState,
    NetworkStateFamily,
    networkStateReducerFamily,
    postFamily, resetFamily,
    resolveFamily
} from "../networkStateReducer";
import {CountryInfo} from "./index";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/cities/find-country-by-id"

export const findCountryByIdReducer = networkStateReducerFamily<CountryInfo>(domain)

export function useFindCountryInfoByName(family: string = "DEFAULT"):[NetworkState<CountryInfo>, (name?: string) => void, () => void] {
    let dispatch = useDispatch()

    return [
        resolveFamily(family, useSelector<RootState, NetworkStateFamily<CountryInfo>>(state => state.city.countryInfoById)),
        (name) => dispatch(getFamily(domain, "/customer-api/v2/cities/search/find-country-info/{name}", family, {path:{name: !!name ? name : undefined}})),
        () => dispatch(resetFamily(domain, family))
    ]
}