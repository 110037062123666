import {get, NetworkState, networkStateReducer, reset,} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {DeliveryPlan} from "./types";

export const domain = "@appigo-retailer/cart/get/delivery-plans"

export const getDeliveryPlansReducer = networkStateReducer<DeliveryPlan[]>(domain)

export function useDeliveryPlans(): [NetworkState<DeliveryPlan[]>, () => void, () => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<DeliveryPlan[]>>(state => state.cart.deliveryPlans),
        () => dispatch(get(domain, "/customer-api/v2/cart/get/delivery-plans")),
        () => dispatch(reset(domain))
    ]
}
