import {
    getFamily, isError, isSuccess,
    NetworkState,
    NetworkStateFamily,
    networkStateReducerFamily,
    resetFamily,
    resolveFamily
} from "../networkStateReducer";
import {Product} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {toast, ToastOptions} from "react-toastify";
import {useEffect} from "react";

export const domain = "@appigo-retailer/product/get"

export const getProductReducer = networkStateReducerFamily<Product>(domain)

export function useProduct(family: string): [NetworkState<Product>, (productId: string, varianceKey?: string) => void, () => void] {
    let dispatch = useDispatch();

    return [
        resolveFamily(family, useSelector<RootState, NetworkStateFamily<Product>>(state => state.product.get)),
        (productId, varianceKey) => dispatch(getFamily(domain, "/customer-api/v2/product/{productId}", family, { path: {productId}, query: varianceKey ?  {varianceKey} :  undefined})),
        () => dispatch(resetFamily(domain, family))
    ]
}
