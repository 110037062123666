import {get, NetworkState, networkStateReducer} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/login"

export const logoutReducer = networkStateReducer<string>(domain)

export function useLogoutCallback(): [NetworkState<string>, ()=> void] {
    const dispatch = useDispatch();
    return [
        useSelector<RootState, NetworkState<string>>(state => state.user.logoutState),
        () => dispatch(get(domain, "/customer-api/v2/user/logout"))
    ]
}
