import React, {useEffect, useRef, useState} from "react";
import {definition as faCartPlus} from "@fortawesome/free-solid-svg-icons/faCartPlus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {useCart} from "../../../reducers/cart/get";
import {isSuccess} from "../../../reducers/networkStateReducer";
import {definition as faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import {createQueryParams, useSearch, useSearchQuery} from "../../../reducers/product/search";
import {useMobileMenuState} from "../../../reducers/ui/mobilemenu";
import {useDebouncedState} from "../../common/util";
import {useI18n} from "../../../i18n/I18nSupport";
import {useSchema} from "../../../reducers/schema";
import {keycloak} from "../../../auth/AuthProvider";

export function MobileResponsiveHeader() {

    let [isActive, setActive] = useState(false)
    let [mobileMenuStatus, setMobileMenuStatus] = useMobileMenuState()

    function handleOpenMenuClicked() {
        setMobileMenuStatus(true)
    }

    function handleCloseMenuClicked() {
        setMobileMenuStatus(false)
    }

    let showBanner = false

    return <>
        {/*<div className={`mobile-menu__dark-btn d-lg-none d-block ${showBanner && `pt-5 pt-md-0` }` }>*/}
        {/*    <label className="switch enable-dark-theme-switch">*/}
        {/*        <input type="checkbox"/>*/}
        {/*        <span className="slider round"></span>*/}
        {/*    </label>*/}
        {/*</div>*/}
        <MobileCart/>
        <a className={`mobile-menu-btn ${!showBanner && `mobile-menu-open-btn`} float-right d-lg-none d-block ${showBanner  && `pt-3 pt-md-3 mobile-menu-open-btn-promo `}  ${mobileMenuStatus ? 'inactive': 'active'}`} onClick={handleOpenMenuClicked}>
            <span className="mobile-menu-strip mobile-menu-strip-1"></span>
            <span className="mobile-menu-strip mobile-menu-strip-2"></span>
            <span className="mobile-menu-strip mobile-menu-strip-3"></span>
        </a>

        <a className={`mobile-menu-btn ${!showBanner && `mobile-menu-close-btn`} d-lg-none d-block ${showBanner && `pt-3 pt-md-3 mobile-menu-close-btn-promo`} ${!mobileMenuStatus ? 'inactive' :  'active'}`}onClick={handleCloseMenuClicked}>
            <span className="mobile-menu-strip mobile-menu-strip-1"></span>
            <span className="mobile-menu-strip mobile-menu-strip-3"></span>
        </a>

    </>
}

function MobileCart() {

    let showBanner = false
    const [schema] = useSchema()
    const [cart] = useCart()

    // if (isSuccess(schema) && schema.data.generalSettings?.isRegisteredUsersOnly == true && !keycloak.authenticated){
    //     return <Link className={`mobile-menu__cart-btn ${!showBanner && `notification-icon` } float-right text-light d-lg-none d-block ${showBanner && `pt-5 pt-md-0 notification-icon-promo` }`} style={{textDecoration: "none"}} to={"/login"} onClick={(e) => {
    //         keycloak.login()}}>
    //         <FontAwesomeIcon icon={faCartPlus} size={"lg"} className={"bg-transparent login-button"}/>
    //         { isSuccess(cart) &&  <span className="notification-icon__badge">{Object.keys(cart.data.items).length}</span>}
    //     </Link>
    // }

    return <Link className={` d-flex mobile-menu__cart-btn ${!showBanner && `notification-icon` } float-right text-light d-lg-none d-block ${showBanner && `pt-5 pt-md-0 notification-icon-promo` }`} style={{textDecoration: "none", width:"60px"}} to={"/cart"}>
        <FontAwesomeIcon color={"white"} icon={faCartPlus} size={"lg"} className={"bg-transparent  "}/>
        { isSuccess(cart) &&  <span className="notification-icon__badge font-weight-bold ml-4" style={{position:"absolute"}} >{Object.keys(cart.data.items).length}</span>}
    </Link>
}
export function MobileSearch() {

    let [query] = useSearchQuery();

    const [input, setInput, debounced] = useDebouncedState<string>(300, "")

    let [, search] = useSearch("quick-search");

    useEffect(() => {
        if (!!debounced?.length) {
            search({
                query: debounced,
                first: 6,
                after: 0,
                source: "search"
            })
        }
    }, [debounced])

    useEffect(() => {
        if (query.query) {
            setInput(query.query)
        }
    }, [query.query])

    let {ts} = useI18n("header.search");

    const searchLink = useRef<HTMLAnchorElement>(null);

    return <div className="container" style={{maxWidth:"1240px"}}>
        <div className="mobile-main-search d-lg-none d-sm-block pt-2">
            <div className="input-group mb-2">
                <input type="search" className="form-control" placeholder={ts`placeholder`} aria-label="Search"
                       aria-describedby="button-addon3"
                       value={input}
                       onChange={(e) => {
                           setInput(e.target.value)
                       }}
                       onKeyDown={(e: any) => {
                           if (e.code === "Enter" && !!searchLink.current) {
                               searchLink.current.click()
                           }
                       }}
                />
                <div className="input-group-append">
                    <Link to={`/search?${createQueryParams({query:input, source: "search"})}`} ref={searchLink} className={"btn btn-primary"} type={"button"} id="button-addon3">
                        <FontAwesomeIcon icon={faSearch} size={"lg"}/>
                    </Link>
                </div>
            </div>
        </div>
    </div>
}
