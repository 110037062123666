import {NetworkState, networkStateReducer, post, reset} from "../networkStateReducer";
import {CardOptions, Cart, DeliveryPlan} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/cart/set/save-delivery-plan"

export const cartSetDeliveryPlanReducer = networkStateReducer<Cart>(domain)

export function useSetDeliveryPlan(): [NetworkState<Cart>, (deliveryPlan: DeliveryPlan) => void, () => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<Cart>>(state => state.cart.deliveryPlan),
        (req) => {
            dispatch(post(domain, "/customer-api/v2/cart/set/delivery-plan", req));
        },
        () => dispatch(reset(domain))
    ]
}
