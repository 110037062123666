import {
    get,
    NetworkState,
    networkStateReducer, post,
} from "../networkStateReducer";
import {components, operations} from "../../__generated/api";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {Address, ProfileRes} from "./get";

export const domain = "@appigo-retailer/profile/update"

export const profileUpdateBillingAddressReducer = networkStateReducer<ConsumerProfileReq>(domain)

export type ConsumerProfileReq = components["schemas"]["ConsumerProfile"]

export function useProfileUpdateBillingAddress(): [NetworkState<ProfileRes>, (address: Address) => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<ProfileRes>>(state => state.profile.updateBillingAddress),
        (address) => dispatch(post(domain, "/customer-api/v2/profile/update/billing-address", address))
    ];

}
