import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {definition as faCartPlus} from "@fortawesome/free-solid-svg-icons/faCartPlus";
import {isSuccess} from "../../../../reducers/networkStateReducer";
import {Link} from 'react-router-dom';
import {Cart, PurchasableProductV2} from "../../../../reducers/cart/types";
import {useCart} from "../../../../reducers/cart/get";
import {createImgProxyUrl} from "../../../common/createImageProxy";
import {capitalizeVariants} from "../../Body/Product/ProductInfo/ProductInformation";
import {useI18n, WithPlaceholders} from "../../../../i18n/I18nSupport";
import {useSchema} from "../../../../reducers/schema";
import {keycloak} from "../../../../auth/AuthProvider";
import {definition as faHeart} from "@fortawesome/free-solid-svg-icons/faHeart";
import {LazyLoadImage} from "react-lazy-load-image-component";

interface CartPopUpItemProps {
    cartItem: PurchasableProductV2
}

interface CartItemDetailsProps {
    item: PurchasableProductV2
    isPopUp?: boolean
}

export function CartItemDetails({item, isPopUp}: CartItemDetailsProps) {

    const {tx, localized} = useI18n("cart.items.item")

    return <div className="container">
        <div className={`row ${isPopUp ? 'long-text' : ''} font-enlarged mb-1`}><strong>{tx`name` } &nbsp;X{item.quantity}</strong></div>
        {/*<div className={`row font-enlarged mb-1`}>{productName}</div>*/}
        {Object.entries(item.product.values ?? {}).map(values => <div className={"row"}>
                <small
                    style={{fontSize: "95%"}}>{`${localized(item.product.variances.find((v) => v?.id === values[0])?.label)} : ${capitalizeVariants(values[1].label!!)}`}</small>
            </div>
        )}
    </div>
}

export function CartPopUpItem({cartItem}: CartPopUpItemProps) {

    const {tx} = useI18n("cart.popup.popupItem")

    return (
        <Link className="dropdown-item"
              to={`/product/${cartItem.product.baseId}?varianceKey=${cartItem.product.varianceKey}`}>
            <div className="row">
                <div className="col-2">
                    {<LazyLoadImage
                        src={createImgProxyUrl(cartItem.product.imageReference ?? ((cartItem.product.iconUrl ?? [])[0]), 700, 700)}
                        className="w-100"/>}
                </div>
                <div className="col-6">
                    <WithPlaceholders value={{
                        item: cartItem
                    }}>
                        <CartItemDetails item={cartItem} isPopUp={true}/>
                    </WithPlaceholders>
                </div>
                <div className="col-4" style={{
                    width: "fit-content",
                    blockSize: "fit-content",
                    marginLeft: -5
                }}>
                    <text style={{marginLeft: -10}}>{tx`itemTotal`}</text>
                </div>
            </div>
        </Link>
    )
}

interface CartPopUpProps {
    cart?: Cart
}

export default function CartPopUp(props: CartPopUpProps) {

    const [cart, getCart] = useCart()
    let [currCart, setCurrCart] = useState<Cart>()
    const {tx} = useI18n("cart.popup")
    const [schema] = useSchema()

    useEffect(() => {
        if ((isSuccess(cart) && !cart.data)) {
            getCart()
        }
    }, [])

    useEffect(() => {
        if (isSuccess(cart)) {
            setCurrCart(cart.data)
        }
    }, [cart])

    let [isCartClicked, setCartClicked] = useState(false)

    if (isSuccess(schema) && schema.data.generalSettings?.isRegisteredUsersOnly === true && !keycloak.authenticated) {
        return (
            <WithPlaceholders value={{
                cart: currCart
            }}>
                <li className={`d-inline mt-0 dropdown ${isCartClicked && 'show'}`}>
                    <button className="btn dropdown-toggle notification-icon login-button bg-transparent" type="button"
                            id="dropdownMenuButton4" style={{fontSize: "0.75rem"}}
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded={isCartClicked}>
                        {!!currCart &&
                            <span className="notification-icon__badge">{Object.keys(currCart.items).length}</span>}
                        <FontAwesomeIcon onMouseEnter={() => setCartClicked(!isCartClicked)}
                                         onMouseLeave={() => setCartClicked(!isCartClicked)}
                                         style={{fontSize: "1.25rem"}} icon={faCartPlus} size={"lg"}/>
                    </button>
                    {isSuccess(cart) && Object.keys(cart.data.items).length > 0 &&
                        <div
                            className={`dropdown-menu dropdown-menu-right mt-0 p-3 bg-white ${isCartClicked && 'show'}`}
                            style={{width: '32rem', left: "auto"}}
                            aria-labelledby="dropdownMenuButton4">
                            <div className={'cartList'}>
                                {
                                    isSuccess(cart) && Object.values(cart.data.items).map(cartItem => {
                                        return <WithPlaceholders value={{
                                            popupItem: cartItem
                                        }} key={`${cartItem.product.id}-${cartItem.product.varianceKey}`}>
                                            <CartPopUpItem key={`${cartItem.product.id}-${cartItem.product.varianceKey}`}
                                                           cartItem={cartItem}/>
                                        </WithPlaceholders>
                                    })
                                }
                            </div>
                            <Link id={"linkToCart"}
                                  className={"btn btn-primary text-white w-100 mt-2 py-2 font-weight-normal"}
                                  style={{borderBottomColor: "transparent !important"}}
                                  to={"/login"} onClick={(e) => {
                                keycloak.login()
                            }}>
                                {tx`buttonLabel`}
                            </Link>
                        </div>
                    }
                </li>
            </WithPlaceholders>
        );

    }

    return (
        <WithPlaceholders value={{
            cart: currCart
        }}>
            <li className={`d-inline dropdown ${isCartClicked && 'show'}`}>
                <button className="btn dropdown-toggle notification-icon login-button  text-white my-2 my-sm-0 p-2  mr-2"
                        type="button"
                        id="dropdownMenuButton4" style={{fontSize: "0.9rem", background: "#271B75", color: "white", width:"60px"}}
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded={isCartClicked}>
                    <FontAwesomeIcon onMouseEnter={() => setCartClicked(!isCartClicked)}
                                     onMouseLeave={() => setCartClicked(!isCartClicked)}
                                     style={{fontSize: "1rem"}}
                                     color={"white"}
                                     icon={faCartPlus}
                                     size={"lg"}/>
                    {!!currCart  &&
                        <span className="notification-icon__badge font-weight-bold">{Object.keys(currCart.items).length}</span>}
                    {/*{isSuccess(cart) && Object.keys(cart.data.items).length}*/}
                </button>


                {isSuccess(cart) && Object.keys(cart.data.items).length > 0 &&
                    <div className={`dropdown-menu dropdown-menu-right mt-0 bg-white p-3 ${isCartClicked && 'show'}`}
                         style={{width: '32rem', left: "auto"}}
                         aria-labelledby="dropdownMenuButton4">
                        <div className={'cartList'}>
                            {
                                isSuccess(cart) && Object.values(cart.data.items).map(cartItem => {
                                    return <WithPlaceholders value={{
                                        popupItem: cartItem
                                    }} key={`${cartItem.product.id}-${cartItem.product.varianceKey}`}>
                                        <CartPopUpItem key={`${cartItem.product.id}-${cartItem.product.varianceKey}`}
                                                       cartItem={cartItem}/>
                                    </WithPlaceholders>
                                })
                            }
                        </div>

                        <Link id={"linkToCart"}
                              className={"btn btn-primary text-white w-100 mt-2 py-2 font-weight-normal"}
                              style={{borderBottomColor: "transparent !important"}} to={"/cart"}>
                            {tx`buttonLabel`}
                        </Link>
                    </div>
                }
            </li>
        </WithPlaceholders>
    );
}
