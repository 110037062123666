import {
    isError,
    isSuccess, NetworkState,
    NetworkStateFamily,
    networkStateReducerFamily,
    post, postFamily,
    reset, resetFamily, resolveFamily,
} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {Cart, CartItemRequest} from "./types";
import {useEffect} from "react";
import {toast} from "react-toastify";
import {toastCustomOptions} from "../../view/common/commons";
import {useCartPopUp} from "../ui/cartPopUp";

export const domain = "@appigo-retailer/cart/add"

export const cartAddReducer = networkStateReducerFamily<Cart>(domain)

export function useCartAdd(family: string = "Default"): [NetworkState<Cart>, (query: CartItemRequest) => void, () => void] {
    let dispatch = useDispatch();
    let [cartPopUpState, setCartPopUpState] = useCartPopUp()

    const networkState = resolveFamily(family,useSelector<RootState, NetworkStateFamily<Cart>>(state => state.cart.add))

    useEffect(() => {
        if (isSuccess(networkState)) {
            toast.success(`Product added to cart successfully`, {
                ...toastCustomOptions,
                toastId:"success product"
            })
            if (cartPopUpState) {
                setCartPopUpState(!cartPopUpState)
            }

        } else if (isError(networkState)) {
            toast.error(`Product addition failed`, {
                ...toastCustomOptions,
                toastId:"error product",
            })
        }
    }, [networkState])

    return [
        networkState,
        (query) => dispatch(postFamily(domain, "/customer-api/v2/cart/add",family, query)),
        () => dispatch(resetFamily(domain,family))
    ]
}
