import {NetworkState, networkStateReducer, post, reset} from "../networkStateReducer";
import {Cart} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/cart/set/save-inventory-location"

export const cartSetInventoryLocationReducer = networkStateReducer<Cart>(domain)

export function useSetInventoryLocation():[NetworkState<Cart>,(inventoryLocationId: string) => void, () => void] {
    let dispatch = useDispatch()

    return [
        useSelector<RootState, NetworkState<Cart>>(state => state.cart.setInventoryLocation),
        (inventoryLocationId) => dispatch(post(domain, "/customer-api/v2/cart/set/inventory-location",{},{ query:{ inventoryLocationId: inventoryLocationId ? inventoryLocationId : undefined }})),
        () => dispatch(reset(domain))
    ]

}