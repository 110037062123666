import {NetworkState, networkStateReducer, post} from "../networkStateReducer";
import {Cart, PaymentMethodSelectionRequest} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/cart/set/payment-method"

export const cartSetPaymentMethodReducer = networkStateReducer<Cart>(domain)

export function useCartSetPaymentMethod(): [NetworkState<Cart>, (paymentMethod: PaymentMethodSelectionRequest) => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<Cart>>(state => state.cart.setPaymentMethod),
        (paymentMethod) => dispatch(post(domain, "/customer-api/v2/cart/set/payment-method", paymentMethod))
    ]
}
