import {get, NetworkState, networkStateReducer} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {Outlets} from "./types";
import {RootState} from "../index";

export const domain = "@appigo-retailer/outlet/find"

export const findOutletsReducer = networkStateReducer<Outlets>(domain)

export function useFindOutlets(): [NetworkState<Outlets>, () => void] {
    let dispatch = useDispatch()

    return [
        useSelector<RootState, NetworkState<Outlets>>(state => state.outlets.getOutlets),
        () => dispatch(get(domain, "/merchant-api/v2/outlet/get"))
    ]
}