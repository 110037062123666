import React, {Reducer, useEffect, useReducer, useState} from "react";
import {Product, ProductConnection} from "../../../../../reducers/product/types";
import {ProductImageSlider} from "./ProductImageSlider";
import {PriceSection, ProductInformation} from "./ProductInformation";
import {ProductQuantity, ProductStateSection} from "./ProductStateSection";
import {ProductDetailsSection} from "./ProductDetailsSection";
import {initProductState, productStateReducer, QuantityAction, QuantityState, updateProduct} from "./util";
import {BuyNowButton} from "./BuyNowButton";
import {useI18n} from "../../../../../i18n/I18nSupport";
import {ProductViewOtherBrands} from "../../SearchResult/FilterSection/OtherBrands";
// import stage from "../../../../../resources/images/stage.png";
import {parseQueryParams, useSearch, useSearchQuery} from "../../../../../reducers/product/search";
import {useHistory} from "react-router-dom";
import {useProfileGet} from "../../../../../reducers/profile/get";
import {useSchema} from "../../../../../reducers/schema";
import {isSuccess} from "../../../../../reducers/networkStateReducer";
import {PAGE_SIZE} from "../../SearchResult/FilterSection/ResultSection";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {createImgProxyUrl} from "../../../../common/CreateImgProxyUrl";
import {useBrandList} from "../../../../../reducers/brands/searchBrands";
import {useBrandData} from "../../../../../reducers/brandData/get";
import {BrandData} from "../../../../../reducers/brandData/types";

export interface ProductInfoSectionProps {
    product: Product
}


export function ProductInfoSection({product}: ProductInfoSectionProps) {
    let {tx} = useI18n('product.info');
    let [state, dispatch] = useReducer<Reducer<QuantityState, QuantityAction>>(productStateReducer, initProductState);
    let [result, search] = useSearch("result-section")
    let [currSearchResult, setCurrSearchResult] = useState<ProductConnection>()
    const [, update] = useSearchQuery();
    const {replace} = useHistory();
    const [profile,getProfile] = useProfileGet();
    const [pageSize,setPageSize] = useState<number>()
    const [schema] = useSchema();
    const [brandDataSchema,getBrandsData] = useBrandData();
    const [brandData, setBrandData] = useState<BrandData[] >([]);



    useEffect(() => {
        getBrandsData();
    }, []);

    useEffect(()=>{
        isSuccess(brandDataSchema) && setBrandData(brandDataSchema.data[0].brands)
        isSuccess(brandDataSchema) && console.log("brandSchema",brandDataSchema.data[0])
    },[brandDataSchema])


    useEffect(() => {
        if (isSuccess(schema)) {
            setPageSize(schema.data.generalSettings?.searchPageProductSize ?? PAGE_SIZE)
        }
    },[schema])

    useEffect(() => {
        getProfile()
    },[])

    useEffect(() => {
        if (isSuccess(result)) {
            setCurrSearchResult(result.data)
        }
    },[result])


    useEffect(() => {

        console.log("Product Details hellloing:", product.brand?.name?.en );
    }, [product]);


    const currentBrandNameprod = product.brand?.name?.en;

    const imageUrlProduct = (() => {
        const defaultImage = "full-seating-img.png";
        let imageFileName = defaultImage;

        if (currentBrandNameprod && brandData.length > 0) {
            const matchedBrand = brandData.find(brand => brand.brandName === currentBrandNameprod);
            if (matchedBrand) {
                imageFileName = matchedBrand.bannerImg ?? defaultImage;
            }
        }

        return isSuccess(schema)
            ? createImgProxyUrl(`/merchant-resources/${schema.data.merchantId}/${imageFileName}`, 1140, 370)
            : "";
    })();



    useEffect(() => {
        dispatch(updateProduct(product))
    }, [product])

    return <>
        <div className="card-body" >
            {/*<div className="row d-flex justify-content-between">*/}
            <div className="row d-flex justify-content-between flex-sm-row">
                <div className="col-sm-3">
                    <ProductViewOtherBrands/>
                </div>
                <div className="col-sm-9 d-flex flex-column" style={{height: "fit-content"}}>
                    {/* IMAGE Section */}
                    <div className="w-100 d-flex mb-2 mb-4 rounded">
                        {/*<div className="d-flex justify-content-center align-items-center w-100 h-100"*/}
                        {/*     style={{padding: "16px"}}>*/}
                        {/*<img className=" mb-4" src={stage} alt="stage" style={{objectFit: "contain", width: "100%"}}/>*/}
                        <LazyLoadImage src={imageUrlProduct} className="grab-yours-page-main-img img-fluid" style={{objectFit: "contain", width: "100%", borderRadius:"7px"}} alt="FAIL"/>
                        {/*</div>*/}
                    </div>
                    <div className={"justify-content-between mb-1 product-name-mobile-view "}>
                        <h5 className=" font-weight-bold my-lg-3 my-3 product-info-text w-50">{tx`name`}</h5>
                        <div className=" justify-content-end quantity-btn-mobile-view my-2">
                            {!(product.hideProductCounter) &&
                                <ProductQuantity product={product} state={state} dispatch={dispatch}/>}
                        </div>
                    </div>
                    {/* Product Information and State Section */}
                    <div className="d-flex justify-content-between">
                        <div className="col-sm-6 d-flex justify-content-start w-100 p-0">
                            <ProductInformation product={product}/>
                        </div>
                        <div className="col-sm-6 d-flex justify-content-end w-100 p-0">
                            <ProductStateSection product={product}/>
                        </div>
                    </div>
                    <div className="justify-content-end buy-ticket-mobile-view">
                        <BuyNowButton product={product} state={state} dispatch={dispatch}/>
                    </div>
                    {/*<div className="card border-0 mt-3">*/}
                    <div className=" border-0 mt-3">
                        <ProductDetailsSection productData={product}/>
                    </div>
                </div>

            </div>

        </div>
    </>
}
