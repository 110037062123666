import {Middleware} from "redux";
import {domain as addDomain} from "./add";
import {get, getFamily} from "../networkStateReducer";
import {domain as getDomain} from "./get";
import {domain as updateDomain} from "./update"
import {domain as removeDomain} from "./remove";
import {domain as setOutlet} from "./setOutlet"
import {domain as setPaymentMethod} from "./setPaymentMethod"
import {domain as setBillingAddress} from "./setBillingAddress"
import {domain as setEmail} from "./setEmail"
import {domain as setAdditionalInfo} from "./setAdditionalInfo"
import {domain as purchaseDomain} from "../purchase/purchase";
import {domain as paymentCompleteDomain} from "../purchase/completePayment";
import {domain as saveCardOptionsDomain} from "./saveCardOptions";
import {domain as setShippingAddress} from "./setShippingAddress"
import {domain as setDeliveryMethod} from "./setDeliveryMethod";
import {domain as getDeliveryPlansDomain} from "./getDeliveryPlans";
import {domain as setDeliveryPlan} from "./setDeliveryPlan";
import {domain as setPromoCode} from "./setPromoCode";
import {domain as setShippingAddressGeoCoordinate} from "./setGeoCoordinates";
import {domain as setInventoryLocation} from "./setInventoryLocation"
import {domain as setOrderDueDate} from "./setSelectedOrderDueDate"
import {domain as setStorePickupDueDate} from "./setStorePickupDueDate"
import {domain as setSelectedCurrency} from "./setSelectedCurrency"
import {domain as setShippingAsBilling} from "./setIsBillingSameAsShipping";

const cartReloadActions = [
    addDomain,
    updateDomain,
    removeDomain,
    setEmail,
    setBillingAddress,
    setPaymentMethod,
    setOutlet,
    purchaseDomain,
    paymentCompleteDomain,
    saveCardOptionsDomain,
    setShippingAddress,
    setDeliveryMethod,
    setDeliveryPlan,
    setPromoCode,
    setShippingAddressGeoCoordinate,
    getDeliveryPlansDomain,
    setPromoCode,
    setInventoryLocation,
    setOrderDueDate,
    setSelectedCurrency,
    setStorePickupDueDate,
    setShippingAsBilling
].map(a => new RegExp(`^${a}_(SUCCESS|FAIL)(.*)`))

export const cartMiddleware: Middleware = api => next => action => {
    if (!!cartReloadActions.find(a => a.test(action.type))) {
        api.dispatch(getFamily(getDomain, "/customer-api/v2/cart/get", "Default"))
    }
    return next(action);
}
