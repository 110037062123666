import {TrackingAdaptor, TrackingEvent} from "./trackingProvider";
import ReactPixel from 'react-facebook-pixel';
import {FbAnalyticsConfig} from "./types";


export function pixelTracker(pixelConfig: FbAnalyticsConfig | undefined): TrackingAdaptor {

    if (!!pixelConfig && !!pixelConfig.fbPixelId && pixelConfig.isEnabled) {
        console.log("Initializing Pixel with ID: ", pixelConfig.fbPixelId)
        ReactPixel.init(pixelConfig.fbPixelId);
    }

    return {
        track(trackingEvent: TrackingEvent) {
            console.log("Tracking Event: ", trackingEvent, "Pixel Config: ", pixelConfig)
            if (!!pixelConfig && (!pixelConfig.isEnabled || !pixelConfig.isBuiltInTracking)) {
                console.log("Pixel is disabled. Skipping tracking event: ", trackingEvent)
                return;
            }

            switch (trackingEvent.type) {
                case "PageView":
                    ReactPixel.track('PageView', trackingEvent.data)
                    break;
                case "Search":
                    ReactPixel.track('Search', trackingEvent.data)
                    break;
                case "CustomizeProduct":
                    ReactPixel.track('CustomizeProduct', trackingEvent.data)
                    break;
                case "AddToCart":
                    ReactPixel.track('AddToCart', trackingEvent.data)
                    break;
                case "InitiateCheckout":
                    ReactPixel.track('InitiateCheckout', trackingEvent.data)
                    break;
                case "AddShippingDetails":
                    ReactPixel.track('ProceedToShippingDetails', trackingEvent.data)
                    break;
                case "AddPaymentInfo":
                    ReactPixel.track('AddPaymentInfo', trackingEvent.data)
                    break;
                case "PaymentAndBilling":
                    ReactPixel.track('ProceedToPaymentAndBilling', trackingEvent.data)
                    break;
                case "PromoApply":
                    ReactPixel.track('PromoApply', trackingEvent.data)
                    break;
                case "PlaceOrderCOD":
                    ReactPixel.track('PlaceOrderCOD', trackingEvent.data)
                    break;
                case "PlaceOrderCard":
                    ReactPixel.track('PlaceOrderCard', trackingEvent.data)
                    break;
                case "IPGFailure":
                    ReactPixel.track('IPGFailure', trackingEvent.data)
                    break;
                case "Purchase":
                    ReactPixel.track('Purchase', trackingEvent.data)
                    break;
            }

        }
    }
}
