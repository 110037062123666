import {Action, Reducer} from "redux";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export interface ProductIdState {
    id: string
    merchantId: string
}

interface SetProductIdState extends Action {
    type: "@appigo-retailer-product/set-product-id"
    id: string
    merchantId: string
}

function isProductIdState(action: Action): action is SetProductIdState {
    return action.type === "@appigo-retailer-product/set-product-id"
}

function setProductIdState(id: string, merchantId: string): SetProductIdState {
    return {
        id,
        merchantId,
        type: "@appigo-retailer-product/set-product-id"
    }
}

export const productIdReducer:Reducer<ProductIdState, SetProductIdState> = (state = {id: "", merchantId: ""}, action) => isProductIdState(action) ? {id: action.id, merchantId: action.merchantId} : state

export function useProductIdState(): [ProductIdState, (id: string, merchantId: string) => void] {
    const dispatch = useDispatch()
    return [
        useSelector<RootState, ProductIdState>(state => state.productId),
        (id, merchantId) => dispatch(setProductIdState(id, merchantId))
    ]
}
