import {combineReducers, Reducer} from "redux";
import {SearchBarState, searchBarStatusReducer} from "./searchBar";
import {quickSearchPopupReducer, QuickSearchPopupState} from "./quickSearchPopup";
import {MobileMenuState, mobileMenuStatusReducer} from "./mobilemenu";
import {cartPopUpReducer, CartPopupState} from "./cartPopUp";
import {promotionPopUpReducer, PromotionPopupState} from "./PromotionMessagePopup";
import {HeaderHeightState, headerHeightStatusReducer} from "./HeaderHeight";
import {errorStatusReducer, RemoteErrorStatus} from "./errorStatus";
import {storeSelectionPopUpReducer, StoreSelectionPopupState} from "./storeSelectionPopUp";
import {multiStoreReducer, MultiStoreState} from "./multiStoreSelection";
import {defaultCurrencyCodeReducer, DefaultCurrencyCodeState} from "./defaultCurrencyCode";
import {CountryCodeState, countryCodeStateReducer} from "./countryCode";
import {defaultLanguageReducer, DefaultLanguageState} from "./defaultLanguage";
import {addressSectionReducer, AddressSectionState} from "./cityInfo";

export interface UiState {
    searchBar: SearchBarState,
    addressDetailsState : AddressSectionState,
    headerHeight: HeaderHeightState,
    quickSearchPopup: QuickSearchPopupState,
    mobileMenu: MobileMenuState
    cartPopUp: CartPopupState
    storeSelectionPopUp: StoreSelectionPopupState
    multiStore: MultiStoreState
    promotionPopup: PromotionPopupState,
    remoteErrorStatus: RemoteErrorStatus,
    countryCodeState: CountryCodeState,
    defaultCurrencyCode: DefaultCurrencyCodeState
    defaultLanguage: DefaultLanguageState
}

export const uiReducer: Reducer<UiState, any> = combineReducers({
    searchBar: searchBarStatusReducer,
    addressDetailsState: addressSectionReducer,
    headerHeight: headerHeightStatusReducer,
    quickSearchPopup: quickSearchPopupReducer,
    mobileMenu: mobileMenuStatusReducer,
    cartPopUp: cartPopUpReducer,
    storeSelectionPopUp: storeSelectionPopUpReducer,
    multiStore: multiStoreReducer,
    promotionPopup: promotionPopUpReducer,
    remoteErrorStatus: errorStatusReducer,
    defaultCurrencyCode: defaultCurrencyCodeReducer,
    countryCodeState: countryCodeStateReducer,
    defaultLanguage: defaultLanguageReducer
})
