import {Action, Reducer} from "redux";

export interface SetInventoryIdAction extends Action {
    type: "@appigo-retailer/set-inventory-id",
    inventoryId?: string
}

export interface InventoryIdState {
    inventoryId?: string
}

export function setInventoryId(inventoryId?: string): SetInventoryIdAction {
    return {
        type: "@appigo-retailer/set-inventory-id",
        inventoryId
    }
}

function isSetInventoryIdAction(action: Action): action is SetInventoryIdAction {
    return action.type === "@appigo-retailer/set-inventory-id"
}

export const inventoryIdReducer: Reducer<InventoryIdState, SetInventoryIdAction> = (state = {}, action) =>
    isSetInventoryIdAction(action) ? {
        inventoryId: action.inventoryId
    } : state
