import {Action, Reducer} from "redux";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export interface HeaderHeightState {
    height: number
}

interface SetHeaderHeightState extends Action {
    type: "@appigo-retailer-ui/set-height-status"
    height: number
}


function isSetPromotionPopUpState(action: Action): action is SetHeaderHeightState {
    return action.type === "@appigo-retailer-ui/set-height-status"
}

function SetHeaderHeightState(height: number): SetHeaderHeightState {
    return {
        height,
        type: "@appigo-retailer-ui/set-height-status"
    }
}

export const headerHeightStatusReducer: Reducer<HeaderHeightState, SetHeaderHeightState> = (state = { height: 0 },
                                                                                            action) =>
    isSetPromotionPopUpState(action) ? {height: action.height} : state


export function useHeaderHeightState(): [number, (height: number) => void] {
    const dispatch = useDispatch();
    return [
        useSelector<RootState, HeaderHeightState>(state => state.uiState.headerHeight).height,
        height => dispatch(SetHeaderHeightState(height))
    ]
}