import React from 'react';
import { Link, useHistory } from "react-router-dom";
import { useSchema } from "../../../../reducers/schema";
import { isSuccess } from "../../../../reducers/networkStateReducer";
import { useSearchBarState } from "../../../../reducers/ui/searchBar";
import { MobileResponsiveHeader } from "../MobileResponsiveHeader";
import Logo from "../TopBar_Old/Logo";
import NavMobileMenu from "../MenuBar/NavMobileMenu";
import CartPopUp from "../MenuBar_Old/CartPopUp";
import SearchIcon from "../MenuBar_Old/SearchIcon";
import { LanguageSelection } from "../MenuBar_Old/LanguageSelection";
import { Login } from "../TopBar_Old/Login";
import { StoreSelection } from "./StoreSelection";
import { useLocalStorage } from "../../Body/Checkout/CheckoutFlowRevamp/utils";
import { CurrencySelection } from "./CurrencySelection";
import {NavMenu} from "../MenuBar/NavMenu";
import SearchPopup from "../MenuBar_Old/SearchPopup";

interface TopBarMenuProps {
    searchPopupHeight?: number,
    setSearchPopupHeight: (height: number) => void;}

export function TopBarMenu({ searchPopupHeight,setSearchPopupHeight }: TopBarMenuProps) {

    const history = useHistory()
    let [schema] = useSchema()
    const [getItem] = useLocalStorage<{ inventoryId: string }>();


    function handleLogoClick() {
        if (window.location.pathname === "/") {
            window.location.reload()
        }
    }

    const [isSearchClicked, setSearchClicked] = useSearchBarState();

    function handleSearchClick() {
        setSearchClicked(!isSearchClicked)
    }

    if (!isSuccess(schema)) return null

    if (isSuccess(schema) && schema.data.generalSettings?.useSiteAsCatalog == true) {
        return <>
            <div className={`container pb-2 pb-md-2 pb-lg-0`} style={{ maxWidth: "1240px" }}>
                <div className={`row  text-center flex-nowrap justify-content-between align-items-center no-gutters`}>
                    {schema.data.logoAlignment !== "LEFT" && <div className="col-sm-5 d-lg-block d-none"></div>}
                    <div className={`col-sm-2 m-2`}>
                        <Link to={"/"} onClick={handleLogoClick}><Logo logoAlignment={schema.data.logoAlignment} logoURL={schema.data.brandLogo} /></Link>
                    </div>

                    <MobileResponsiveHeader />
                    <div className="col-sm-6 justify-content-end align-items-center d-lg-flex d-none">
                        <form className="form-inline my-2 my-lg-0">
                            {!!getItem('inventoryId') && <StoreSelection />}
                            {/*<SearchIcon handleSearchClicked={handleSearchClick} />*/}
                            <CurrencySelection />
                            <LanguageSelection />
                            <Login />
                        </form>
                    </div>
                </div>
            </div>
            {!!getItem('inventoryId') && <NavMenu setHeight={setSearchPopupHeight} />}

            <NavMobileMenu popupHeight={searchPopupHeight} />
        </>;
    } else {
        return <>
            <div className={`container pb-2 pb-md-2 pb-lg-0 `} style={{ maxWidth: "1240px" }}>
                <div className={`row  text-center flex-nowrap justify-content-between align-items-center no-gutters `}>
                    {schema.data.logoAlignment !== "LEFT" && <div className="col-sm-4 d-lg-block d-none"></div>}
                    <div className={`col-sm-2 m-2 `}>
                        <Link to={"/"} onClick={handleLogoClick}><Logo logoAlignment={schema.data.logoAlignment}
                            logoURL={schema.data.brandLogo} /></Link>
                    </div>
                    <div className='sticky-top'>
                    {!!getItem('inventoryId') && (
                        <div >
                            <NavMenu setHeight={setSearchPopupHeight} showSearchBar={true} showCategoryBarLogo={false}/>
                        </div>
                    )}
                    </div>
                    <MobileResponsiveHeader />
                    <div className=" justify-content-end align-items-center d-lg-flex d-none">
                        <form className="form-inline my-2 my-lg-0 ">
                            {/*{!!getItem('inventoryId') && <StoreSelection />}*/}
                            {/*<SearchIcon handleSearchClicked={handleSearchClick} />*/}
                            <LanguageSelection />
                            <CartPopUp />
                            {/*<CurrencySelection />*/}
                            <Login />
                        </form>
                    </div>
                </div>
                 {/*<SearchPopup setHeight={setSearchPopupHeight}/>*/}
            </div>
            <NavMobileMenu popupHeight={searchPopupHeight} />
        </>;
    }
}
