import { useDispatch } from "react-redux";
import { Reducer, useEffect, useReducer, useState } from "react";
import { useDebouncedState } from "../../../../common/util";

export interface CityInfoSearchState {
    searchString?: string
}

export interface CityInfoSearchActions {
    type: "UPDATE_SEARCH" | "CLEAR_SEARCH"
}

export interface UpdateSearchAction extends CityInfoSearchActions {
    type: "UPDATE_SEARCH",
    data: CityInfoSearchState
}

export interface ClearSearchAction extends CityInfoSearchActions {
    type: "CLEAR_SEARCH",
    data?: CityInfoSearchState
}


export function isUpdateSearchAction(action: CityInfoSearchActions): action is UpdateSearchAction {
    return action.type === "UPDATE_SEARCH"
}

export function isClearSearchAction(action: CityInfoSearchActions): action is ClearSearchAction {
    return action.type === "CLEAR_SEARCH"
}

export function updateSearch(data: CityInfoSearchState): UpdateSearchAction {
    return {
        type: "UPDATE_SEARCH",
        data: data
    }
}

export function clearSearch(): ClearSearchAction {
    return {
        type: "CLEAR_SEARCH",
        data: undefined
    }
}

export function cityMapSearchReducer(state: CityInfoSearchState, action: CityInfoSearchActions): CityInfoSearchState {
    if (isUpdateSearchAction(action)) {
        return action.data
    } else if (isClearSearchAction(action)) {
        return {
            searchString: undefined
        }
    }

    return state
}


export function useCitySearch(): [(string | undefined), (action: CityInfoSearchActions) => void] {

    let [currValue, setCurrValue, debouncedValue] = useDebouncedState<CityInfoSearchState>(1000, { searchString: "" });

    let [state, dispatch] = useReducer<Reducer<CityInfoSearchState, CityInfoSearchActions>>(cityMapSearchReducer, {
        searchString: undefined
    })

    useEffect(() => {
        setCurrValue(state)
    }, [state])

    return [
        debouncedValue?.searchString,
        (action) => {
            dispatch(action)
        }
    ]
}

export interface SessionStorageValue {
    value: any
}

export function useSessionStorage<T>(): [(key: string) => T, (key: string, _value: SessionStorageValue) => void, (key: string, wait?: number) => void, () => void] {

    return [
        (key) => {
            let val = sessionStorage.getItem(key) ?? JSON.stringify("")
            return ((JSON.parse(val) as SessionStorageValue).value as T)
        },
        (key, value) => {
            sessionStorage.setItem(key, JSON.stringify(value))
        },
        (key, wait) => {
            if (!!wait) {
                setTimeout(() => {
                    sessionStorage.removeItem(key)
                }, wait)
            } else {
                sessionStorage.removeItem(key)
            }
        },
        () => {
            sessionStorage.clear()
        }
    ]
}

export function useLocalStorage<T>(): [(key: string) => T, (key: string, _value: SessionStorageValue) => void, (key: string, wait?: number) => void, () => void] {

    return [
        (key) => {
            let val = localStorage.getItem(key) ?? JSON.stringify("")
            return ((JSON.parse(val) as SessionStorageValue).value as T)
        },
        (key, value) => {
            localStorage.setItem(key, JSON.stringify(value))
        },
        (key, wait) => {
            if (!!wait) {
                setTimeout(() => {
                    localStorage.removeItem(key)
                }, wait)
            } else {
                localStorage.removeItem(key)
            }
        },
        () => {
            localStorage.clear()
        }
    ]
}
