import React, {createContext, useContext, useEffect} from "react";
import i18n from 'i18next'
import {JSONSchema7} from "json-schema";
import {FormValidation} from "../view/common/form";
import {useTranslation} from "react-i18next";

type PlaceholderType = {[k:string]: any}

type PlaceholderContextType = {
    placeholders: PlaceholderType
}

const PlaceholderContext = createContext<PlaceholderContextType>({
    placeholders: {}
});

export function I18nSupport({children}: {children: any}) {

    useEffect(() => {
        (window as any).addLocale = async (lang: string, key: string, text: string) => {
            i18n.services.backendConnector.backend.create(lang, 'translations', key, text)
            await i18n.reloadResources(lang)
            await i18n.changeLanguage(lang)
        }
    }, [])

    return <PlaceholderContext.Provider value={{
        placeholders: {}
    }}>
        {children}
    </PlaceholderContext.Provider>
}

export function WithPlaceholders({value, children}: {value: PlaceholderType, children: any}) {
    const {placeholders, ...rest} = useContext(PlaceholderContext);

    return <PlaceholderContext.Provider value={{
        placeholders: {
            ...placeholders,
            ...value
        },
        ...rest
    }}>
        {children}
    </PlaceholderContext.Provider>
}

interface I18nComponentProps {
    i18nKey: string
    domain: string
    defaultValue: string
}

export function I18nComponent({i18nKey, domain, defaultValue}: I18nComponentProps) {
    const {placeholders} = useContext(PlaceholderContext);

    let {t} = useTranslation();

    return <>{t(`${domain}.${i18nKey}`, {
        defaultValue,
        ...placeholders
    })}</>
}

export function useI18n(domain: string) {

    const {placeholders} = useContext(PlaceholderContext);

    let {t} = useTranslation();

    function tx(raw: TemplateStringsArray, ...parts: any[]) {

        let result = '';
        raw.forEach((value, index) => {
            result += value + (parts[index] || '')
        });

        let [key, ...defaultValue] = result.split("|");

        return <I18nComponent domain={domain} i18nKey={key} defaultValue={defaultValue.join('|') ?? result}/>
    }

    function ts(raw: TemplateStringsArray, ...parts: any[]) {
        let result = '';
        raw.forEach((value, index) => {
            result += value + (parts[index] || '')
        });

        let [key, ...defaultValue] = result.split("|");

        return t(`${domain}.${key}`, defaultValue.join('|') ?? key, {
            result,
            ...placeholders
        })
    }

    function jsonSchemaI18n(_schema: JSONSchema7): JSONSchema7 {
        function recur(schema: JSONSchema7, path: string[] = []) {
            let type = Array.isArray(schema.type) ? schema.type[0] : schema.type;
            switch (type) {
                case "object":
                    Object.entries(schema.properties ?? {}).forEach(([k, v]) => {
                        if (typeof v !== "boolean") {
                            recur(v, [...path, k])
                        }
                    })
                    break;
                case "array":
                    if (Array.isArray(schema.items)) {
                        schema.items.forEach((def: any) => recur(def, [...path, 'items']))
                    } else if (typeof schema.items !== "boolean" && !!schema.items) {
                        recur(schema.items, [...path, 'items'])
                    }
                    break;
            }
            schema.title = t(`${[domain, ...path].join('.')}.title`, schema.title ?? '', {
                ...placeholders
            })
        }
        recur(_schema)
        return _schema
    }

    function formValidationI18n<T>(validator: (t:T, errors: FormValidation<T>) => FormValidation<T>): (t:T, errors: FormValidation<T>) => FormValidation<T> {

        function isFormValidator(ob: any): ob is FormValidation<any> {
            return typeof ob.addError === "function" && Array.isArray(ob.__errors)
        }

        function recur(ob: FormValidation<any>, path: string[] = []) {
            ob.__errors = ob.__errors.map((error: string) => {
                return t(`${[domain, ...path, 'validation', error].join('.')}`, error, {
                    ...placeholders
                });
            })
            Object.entries(ob).forEach(([k, v]) => {
                if (isFormValidator(v)) {
                    recur(v, [...path, k])
                }
            })
        }

        return (t, errors) => {
            const result = validator(t, errors);
            recur(result);
            return result;
        }
    }

    return {
        tx,
        ts,
        jsonSchemaI18n,
        formValidationI18n,
        localized(localizedContent: {[l: string]: string} | undefined) {
            return localizedContent && (localizedContent[i18n.language] || localizedContent.en)
        }
    }
}

