import {get, NetworkState, networkStateReducer} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {ManagedCard} from "./index";

export const domain = "@appigo-retailer/managed-card/delete"

export const deleteCardReducer = networkStateReducer<ManagedCard[]>(domain)

export function useDeleteCard(): [NetworkState<ManagedCard[]>, (cardId: string) => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<ManagedCard[]>>(state => state.managedCards.deleteCard),
        (cardId) => dispatch(get(domain, "/customer-api/v2/card/delete/{cardId}", {path: {cardId}}))
    ]
}
