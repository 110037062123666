import {
    get, getFamily,
    NetworkState, NetworkStateFamily,
    networkStateReducer, networkStateReducerFamily, post, resetFamily, resolveFamily,
} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {Cart} from "./types";

export const domain = "@appigo-retailer/cart/get"

export const cartGetReducer = networkStateReducerFamily<Cart>(domain)

export function useCart(family: string = "Default"): [NetworkState<Cart>, () => void, () => void] {
    let dispatch = useDispatch();

    return [
        resolveFamily(family,useSelector<RootState, NetworkStateFamily<Cart>>(state => state.cart.get)),
        () => dispatch(getFamily(domain, "/customer-api/v2/cart/get",family)),
        () => dispatch(resetFamily(domain, family))
    ]
}
