import {
    get, getFamily,
    NetworkState, NetworkStateFamily,
    networkStateReducer, networkStateReducerFamily, post, reset, resetFamily, resolveFamily,
} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {CitySearchInfo} from "./index";
import {ManagedPage} from "../pages/types";


export const domain = "@appigo-retailer/cities/getCitiesById"

export const findCityReducer = networkStateReducerFamily<CitySearchInfo>(domain)

export function useGetCityDetails (family: string = "Default") : [NetworkState<CitySearchInfo>, (id:String) => void,() => void] {
    let dispatch = useDispatch();

    return [
        resolveFamily(family, useSelector<RootState, NetworkStateFamily<CitySearchInfo>>(state => state.city.cityDetails)),
        (id) => dispatch(getFamily(domain, "/customer-api/v2/cities/search/citysearch_by_id/{id}", family, { path: {id}})),
        () => dispatch(resetFamily(domain, family))
    ]
}
