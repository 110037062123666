import {Action, Reducer} from "redux";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export interface DefaultLanguageState {
    language: string
}

interface ClearDefaultLanguageAction extends Action {
    type:"@appigo-retailer-ui/clear-default-language",
}

function isClearDefaultLanguageAction(action: Action): action is ClearDefaultLanguageAction {
    return action.type == "@appigo-retailer-ui/clear-default-language"
}

function clearDefaultLanguage(): ClearDefaultLanguageAction {
    return {
        type: "@appigo-retailer-ui/clear-default-language"
    }
}

interface SetDefaultLanguageAction extends Action {
    type:"@appigo-retailer-ui/set-default-language",
    language: string
}

function isSetDefaultLanguageAction(action: Action): action is SetDefaultLanguageAction {
    return action.type === "@appigo-retailer-ui/set-default-language"
}

function setDefaultLanguage(language: string): SetDefaultLanguageAction {
    return {
        type: "@appigo-retailer-ui/set-default-language",
        language: language
    }
}

export const defaultLanguageReducer: Reducer<DefaultLanguageState, (SetDefaultLanguageAction | ClearDefaultLanguageAction)> = (state = {language: "en"}, action) => {
    if (isSetDefaultLanguageAction(action)) {
        return {
            language: action.language
        }
    } else if (isClearDefaultLanguageAction(action)) {
        return state
    }

    return state
}

export function useDefaultLanguage(): [string, (language:string) => void, () => void ] {
    let dispatch = useDispatch()

    return [
        useSelector<RootState,DefaultLanguageState>(state => state.uiState.defaultLanguage).language,
        (language) => dispatch(setDefaultLanguage(language)),
        () => dispatch(clearDefaultLanguage())
    ]
}