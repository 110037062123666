import {combineReducers} from "redux";
import {ManagedPage} from "./types";
import {findReducer} from "./findPage";
import {NetworkState, NetworkStateFamily} from "../networkStateReducer";

export interface PageState {
    find: NetworkStateFamily<ManagedPage>
}

export const pageReducer = combineReducers<PageState>({
    find: findReducer,
})
