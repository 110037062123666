export function createImgProxyUrl(imageUrl: any, width?: number, height?: number, resizing_type?: 'fill'|'fit') {

    let modifiedImageUrl = `local:/${imageUrl}`
    const imgUrl = {
        signature : "appigoRetailer",
        resizing_type : resizing_type ?? "fit",
        gravity : "ce",
        enlarge : "1",
        plain : "plain",
        cropWidth: "0",
        cropHeight: "0",
        focusPointX: "0",
        focusPointY: "0",
        extend:"1",
        red:"255",
        green:"255",
        blue:"255"
    }
    const hostUrl = "/img-proxy";
    const base64encodedImage = !!modifiedImageUrl ? btoa(modifiedImageUrl) : modifiedImageUrl

    if (width && height) return `${hostUrl}/${imgUrl.signature}/rs:${imgUrl.resizing_type}:${width}:${height}:${imgUrl.enlarge}/g:${imgUrl.gravity}:${imgUrl.focusPointX}:${imgUrl.focusPointY}/ex:${imgUrl.extend}/bg:${imgUrl.red}:${imgUrl.green}:${imgUrl.blue}/${base64encodedImage}.jpg`;

    return `${hostUrl}/${imgUrl.signature}/g:${imgUrl.gravity}:${imgUrl.focusPointX}:${imgUrl.focusPointY}/ex:${imgUrl.extend}/bg:${imgUrl.red}:${imgUrl.green}:${imgUrl.blue}/${base64encodedImage}.jpg`;
}
