import {isError, isSuccess, NetworkState, networkStateReducer, post, reset} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import { AffiliateLink, AffiliateLinkRequest } from ".";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { toastCustomOptions } from "view/common/commons";

export const domain = "@appigo-retailer/affiliate/createAffiliateLink"

export const createAffiliateLinkReducer = networkStateReducer<AffiliateLinkRequest>(domain)

export function useCreateAffiliateLink(): [NetworkState<AffiliateLink>, (req: AffiliateLinkRequest) => void, () => void] {
    let dispatch = useDispatch();
    const networkState = useSelector<RootState, NetworkState<AffiliateLink>>(state => state.affiliate.createAffiliateLink)

    useEffect(() => {
        if (isSuccess(networkState)) {
            toast.success('Affiliate Link Created Successfully', {
                ...toastCustomOptions,
                toastId:"success affiliate"
            })
        } else if (isError(networkState)) {
            toast.error('Affiliate Link Created Failed', {
                ...toastCustomOptions,
                toastId:"error affiliate"
            })
        }
    }, [networkState])

    return [
        networkState,
        (req) => dispatch(post(domain, "/customer-api/v2/affiliate", req)),
        () => dispatch(reset(domain))
    ]
}
