import {NetworkState, networkStateReducer, post, reset} from "../networkStateReducer";
import {Cart} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {Address} from "../profile/get";
import {GeoCoordinates} from "../cities";

export const domain = "@appigo-retailer/cart/set-shipping-address-geocoordinate"

export const cartSetShippingAddressGeoCoordinatesReducer = networkStateReducer<Cart>(domain)

export function useSetShippingAddressGeoCoordinatesToCart(): [NetworkState<Cart>, (geoCoordinates?: GeoCoordinates) => void, () => void] {

    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<Cart>>(state => state.cart.setGeoCodeToShippingAddress),
        (geoCoordinates) => dispatch(post(domain, "/customer-api/v2/cart/set/shipping-address-geocoordinate", geoCoordinates)),
        () => dispatch(reset(domain))
    ]
}