import {
    get, getFamily,
    NetworkState, NetworkStateFamily,
    networkStateReducer, networkStateReducerFamily, post, reset, resetFamily, resolveFamily,
} from "../networkStateReducer";

import {CityInfo, CitySearchInfo} from "./index";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";


export const domain = "@appigo-retailer/cities/findCityById"

export const findCityByIdReducer = networkStateReducerFamily<CitySearchInfo>(domain)

export function useGetCityInfoById (family: string) : [NetworkState<CityInfo>, (id:String) => void,() => void] {
    let dispatch = useDispatch();

    return [
        resolveFamily(family, useSelector<RootState, NetworkStateFamily<CityInfo>>(state => state.city.cityDetailsById)),
        (id) => dispatch(getFamily(domain, "/customer-api/v2/cities/search/find_by_id/{id}", family, { path: {id}})),
        () => dispatch(resetFamily(domain, family))
    ]
}