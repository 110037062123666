import {Action, Reducer} from "redux";
import {UserInfo} from "../../auth/AuthProvider";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {useKeycloak} from "@react-keycloak/web";

export interface SetTokenAction extends Action {
    type: "@appigo-retailer/set-token",
    token?: string
}

export interface TokenState {
    token?: string
}

export function setToken(token?: string): SetTokenAction {
    return {
        type: "@appigo-retailer/set-token",
        token
    }
}

function isSetTokenAction(action: Action): action is SetTokenAction {
    return action.type === "@appigo-retailer/set-token"
}

export const tokenReducer: Reducer<TokenState, SetTokenAction> = (state = {}, action) =>
    isSetTokenAction(action) ? {
        token: action.token
    } : state

export interface SetUserInfoAction extends Action {
    type: "@appigo-retailer/set-user-info"
    userInfo?: UserInfo
}

export interface UserInfoState {
    userInfo?: UserInfo
}

export function setUserInfo(userInfo?: UserInfo): SetUserInfoAction {
    return {
        type: "@appigo-retailer/set-user-info",
        userInfo
    }
}

function isUserInfoAction(action: Action): action is SetUserInfoAction {
    return action.type === "@appigo-retailer/set-user-info"
}

export const userInfoReducer: Reducer<UserInfoState, SetUserInfoAction> = (state = {}, action) =>
    isUserInfoAction(action) ? {
        userInfo: action.userInfo
    } : state

export function useUserInfo(): [UserInfo | undefined, () => void] {
    const dispatch = useDispatch();
    const {keycloak} = useKeycloak();
    return [
        useSelector<RootState, UserInfo | undefined>(state => state.userInfo.userInfo),
        () => {
            if (keycloak.authenticated) {
                keycloak.loadUserInfo().then(userInfo => dispatch(setUserInfo(userInfo as UserInfo)))
            }
        }
    ]
}