import {
    getFamily,
    NetworkState, NetworkStateFamily,
    networkStateReducerFamily,
    resetFamily,
    resolveFamily
} from "../networkStateReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {ManagedBrand} from "./types";

export const domain = "@appigo-retailer/brand/find"

export const findBrandReducer = networkStateReducerFamily<ManagedBrand>(domain)

export function useFindBrand(family: string = "Default"): [NetworkState<ManagedBrand>, (id: string) => void, () => void] {
    let dispatch = useDispatch();

    return [
        resolveFamily(family, useSelector<RootState, NetworkStateFamily<ManagedBrand>>(state => state.brand.find)),
        (id) => dispatch(getFamily(domain, "/customer-api/v2/brand/find/{id}", family, {path: { id}})),
        () => dispatch(resetFamily(domain, family))
    ]
}
