import React from 'react';
import {useSchema} from "../../../../reducers/schema";
import {isSuccess} from "../../../../reducers/networkStateReducer";
import {NavMenuItems} from "./NavMenuItems";
import SearchPopup from "../MenuBar_Old/SearchPopup";
import {toLocation} from "../../Body/Home/Banner/Banner";
import useResizeObserver from "use-resize-observer/polyfilled";
import CartPopUp from "../MenuBar_Old/CartPopUp";
import { Link } from 'react-router-dom';

interface MenuBarProps {
    setHeight(height: number): void,
    showSearchBar?: boolean,
    showCategoryBarLogo?: boolean
}

export function NavMenu({setHeight, showSearchBar = true, showCategoryBarLogo = false}: MenuBarProps) {

    let [schema] = useSchema();
    const {ref, width = 1, height = 1} = useResizeObserver<HTMLDivElement>();
    const {ref: logoRef, width: logoWidth = 1, height: logoHeight = 1} = useResizeObserver<HTMLDivElement>();
    function handleLogoClick() {
        if (window.location.pathname === "/") {
            window.location.reload()
        }
    }
    if (!isSuccess(schema)) return null

    return (
        <>
            {/*<div className="d-flex justify-content-end header-nav-menu mt-1 d-lg-block d-none bg-facebook ">*/}
            <div className="header-nav-menu mt-1 d-lg-block d-none ">

                <div className="container d-flex justify-content-between align-items-centers" style={{maxWidth: "960px"}}>
                    {showCategoryBarLogo && <div className=" d-flex justify-content-center align-items-center mt-auto mb-auto" style={{height:"2rem"}}>

                    {/*    style={{*/}
                    {/*    position: 'absolute',*/}
                    {/*    height: '2rem',*/}
                    {/*    margin: 'auto',*/}
                    {/*    top: 0,*/}
                    {/*    bottom: 0,*/}
                    {/*    left: Math.max(((window.innerWidth - width) / 2) - (logoWidth + 10), 0)*/}
                    {/*}}*/}
                        <Link to={"/"} onClick={handleLogoClick}>
                            <img src={schema.data.brandLogo}
                                 style={{height: '2rem', maxWidth: ((window.innerWidth - width) / 2)}} ref={logoRef}/>
                        </Link>
                    </div>}
                    <div className="d-flex align-items-center no-gutters"
                         style={{justifyContent: showCategoryBarLogo ? 'flex-end' : toLocation(schema.data.menuAlignment)}}
                         ref={ref}>
                        <NavMenuItems categoryItems={schema.data.header.menus} containerWidth={width}
                                      containerHeight={height}/>

                    </div>
                    {showCategoryBarLogo &&
                        <div style={{position: "relative"}} className="d-flex align-items-center">
                            <CartPopUp/>
                        </div>
                    }
                </div>
                {/*<CartPopUp />*/}

            </div>
            {/*{showSearchBar && <SearchPopup setHeight={setHeight}/>}*/}
        </>
    );
}
