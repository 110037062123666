import {Action, Reducer} from "redux";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export interface CartPopupState {
    open: boolean
}

interface SetCartPopupState extends Action {
    type: "@appigo-retailer-ui/set-cart-popup-status"
    open: boolean
}

function isSetCartPopUpState(action: Action): action is SetCartPopupState {
    return action.type === "@appigo-retailer-ui/set-cart-popup-status"
}

function setCartPopUpState(open: boolean): SetCartPopupState {
    return {
        open,
        type: "@appigo-retailer-ui/set-cart-popup-status"
    }
}

export const cartPopUpReducer: Reducer<CartPopupState, SetCartPopupState> = (state = { open: false }, action) =>
    isSetCartPopUpState(action) ? {open: action.open} : state

export function useCartPopUp(): [boolean, (open: boolean) => void] {
    const dispatch = useDispatch();
    return [
        useSelector<RootState, CartPopupState>(state => state.uiState.cartPopUp).open,
        open => dispatch(setCartPopUpState(open))
    ]
}