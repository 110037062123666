import {NetworkState, networkStateReducer, post, reset} from "../networkStateReducer";
import {ConsumerProfileReq} from "./updateBillingAddress";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export const domain = "@appigo-retailer/profile/update-default-billing-address"

export const updateDefaultBillingAddressReducer = networkStateReducer<ConsumerProfileReq>(domain)

export function useUpdateDefaultBillingAddress():[NetworkState<ConsumerProfileReq>, (addressId?: string) => void, () => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<ConsumerProfileReq>>(state => state.profile.updateDefaultBillingAddress),
        (addressId) => dispatch(post(domain, "/customer-api/v2/profile/update/default-billing-address/{addressId}",{}, {path:{addressId: !!addressId ? addressId : undefined}})),
        () => dispatch(reset(domain))
    ]
}