import {NetworkState, networkStateReducer, post,} from "../networkStateReducer";
import {components} from "../../__generated/api";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {ProfileRes} from "./get";

export const domain = "@appigo-retailer/profile/create"

export const profileCreateReducer = networkStateReducer<ProfileReq>(domain)

export type ProfileReq = components["schemas"]["ConsumerProfile"]

export function useProfileCreate(): [NetworkState<ProfileRes>, (profile: ProfileReq) => void] {
    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<ProfileRes>>(state => state.profile.create),
        (profile) => dispatch(post(domain, "/customer-api/v2/profile/create", profile))
    ];

}
