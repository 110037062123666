import React, {useEffect, useState} from "react";
import {useProduct} from "../../../../reducers/product/get";
import {Product} from "../../../../reducers/product/types";
import {RouteComponentProps} from "react-router";
import {isError, isSuccess} from "../../../../reducers/networkStateReducer";
import {InfoSubHeader} from "./InfoSubHeader";
import {ProductInfoSection} from "./ProductInfo/ProductInfoSection";
import {RelatedProducts} from "../../../common/RelatedProducts";
import {ProductDetailsSection} from "./ProductInfo/ProductDetailsSection";
import {parseUrl} from "query-string";
import {Loader} from "../../../common/Loader";
import {WithPlaceholders} from "../../../../i18n/I18nSupport";
import ProductUnavailable from "./ProductInfo/ProductUnavailable";
import {useProductFromSlugs} from "../../../../reducers/product/getFromSlugs";
import {PriceConversion} from "../../../common/ProductCard";
import {useDefaultCurrencyCode} from "../../../../reducers/ui/defaultCurrencyCode";
import {ItemType} from "../../../../tracking/googleAnalytics";
import {useTracking} from "../../../../tracking/trackingProvider";
import {useDefaultLanguage} from "../../../../reducers/ui/defaultLanguage";

export function formatSlug(slug: string) {
    return slug.trimEnd().replace(/\s+/g, '-').replace(/#+/g, '_').toLowerCase()
}

function varianceSlug(queryParams: object): string {
    return Object.entries(queryParams)?.map((param) => `${formatSlug(param[0])}=${formatSlug(param[1])}`).join("&")
}


export default function ProductInfo({match, location}: RouteComponentProps<{ productId: string, productSlug: string }>) {
    let [resp, fetch, reset] = useProduct(match.params.productId);
    let [slugResp, slugFetch, slugReset] = useProductFromSlugs(match.params.productSlug);
    let [product, setProduct] = useState<Product>();
    const [currency] = useDefaultCurrencyCode();
    const [language] = useDefaultLanguage()
    const {track} = useTracking()

    console.log("Default Curr",currency, "Default Lang", language)
    useEffect(() => {
        return reset
    }, [])

    useEffect(() => {
        return slugReset
    }, [])

    useEffect(() => {
        if(!!product?.id){
            sendTrackingProductData()
        }
        function sendTrackingProductData() {
            let gaItems: ItemType[] = [{
                item_id: product?.id,
                item_category: product?.categoryId,
                quantity: 1,
                item_name: product?.name?.[language]?.toString() ?? ""
            }]

            track({
                type: "ViewContent",
                data: {
                    value: Object.values(product?.price ?? {})[0],
                    currency: currency.split("|")[0] ?? Object.keys(product?.price ?? {})[0],
                    content_ids: product?.baseId ?? '',
                    content_type: product?.varianceKey ?? '',
                    quantity: 1,
                    name: product?.name?.[language]?.toString(),
                    items: gaItems
                }
            });
        }
    }, [product]);

    useEffect(() => {
        if (!!match.params.productSlug && (!isSuccess(slugResp) || slugResp.data.varianceSlug !== parseUrl(location.search)?.query?.varianceSlug)) {
            slugFetch(match.params.productSlug, varianceSlug(parseUrl(location.search, {sort: false})?.query) as string)
        } else {
            if (!!match.params.productId && (!isSuccess(resp) || resp.data.varianceKey !== parseUrl(location.search)?.query?.varianceKey)) {
                fetch(match.params.productId, parseUrl(location.search)?.query?.varianceKey as string)
            }
        }

    }, [match.params.productSlug, location.search, match.params.productId])

    useEffect(() => {
        if (isSuccess(resp)) {
            setProduct(resp.data)
        }
        if (isSuccess(slugResp)) {
            setProduct(slugResp.data)
        }
    }, [resp, slugResp])

    if (isError(resp)){
        return <ProductUnavailable/>
    }

    if (isError(slugResp)){
        return <ProductUnavailable/>
    }

    if (!product) {
        return <Loader/>
    }

    return <WithPlaceholders value={{
        product: product,
        percentage: Math.round((((PriceConversion(currency, product.price) ?? 0) - (PriceConversion(currency, product.specialPrice) ?? 0)) / (PriceConversion(currency, product.price) ?? 0))*100),
    }}>
        <main className={"flex-shrink-0 pt-0"}>
            <InfoSubHeader baseId={match.params.productId ?? ''} productSlug={match.params.productSlug}/>
            <div className="main-section pt-0">
                {/*<div className="container" style={{maxWidth:"1240px"}}>*/}
                <div className="container" style={{maxWidth:"1600px"}}>
                    <div className="card border-0 " style={{ boxShadow: 'none' }}>
                        <ProductInfoSection product={product}/>
                    </div>
                    {/*<div className="card border-0 mt-3">*/}
                    {/*    <ProductDetailsSection productData={product}/>*/}
                    {/*</div>*/}
                    {/*<div className="mt-3 noSelect">*/}
                    {/*    <RelatedProducts related={[product.baseId + '']}/>*/}
                    {/*</div>*/}
                </div>
            </div>
        </main>
    </WithPlaceholders>
}
