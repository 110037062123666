import {combineReducers} from "redux";
import {NetworkState} from "../networkStateReducer";
import {ManagedCard} from "./index";
import {managedCardListReducer} from "./cardList";
import {deleteCardReducer} from "./deleteCard";

export interface ManagedCardState {
    list: NetworkState<ManagedCard[]>
    deleteCard: NetworkState<ManagedCard[]>
}

export const managedCardReducer = combineReducers<ManagedCardState>({
    list: managedCardListReducer,
    deleteCard: deleteCardReducer
})