import {Middleware} from "redux";
import {get} from "../networkStateReducer";
import {domain as getDomain} from "./get";
import {domain as shippingAddressDomain} from "./updateShippingAddress"
import {domain as billingAddressDomain} from "./updateBillingAddress"
import {domain as updateProfileDomain} from "./updateProfile"
import {domain as updateWishlistDomain} from "./updateWishListItems"

const cartReloadActions = [
    shippingAddressDomain,
    billingAddressDomain,
    updateProfileDomain,
    updateWishlistDomain
].map(a => new RegExp(`^${a}_(SUCCESS|FAIL)(.*)`))

export const profileMiddleware: Middleware = api => next => action => {
    if (!!cartReloadActions.find(a => a.test(action.type))) {
        api.dispatch(get(getDomain, "/customer-api/v2/profile/get"))
    }
    return next(action);
}
