import {Action, Dispatch, Middleware, Reducer} from "redux";
import format from "string-format";
import {paths} from "../__generated/api";

export interface NetworkAction<T> extends Action {}

export interface NetworkPendingAction<T> extends NetworkAction<T> {
    readonly type: string;
    readonly payload: {
        request: {
            method: "get" | "post" | "put";
            url: string;
            data?: any;
            headers?: any
        };
    };
}

export interface PathParams {
    [key: string]: any
}

export interface QueryParams {
    [key: string]: any
}

export interface Headers {
    [key: string]: any
}

export interface PromiseCallback<T> {
    resolve: (value: T) => void,
    reject: (e?:any) => void
}

export interface Params {
    path?: PathParams
    query?: QueryParams,
    headers?: Headers
}

function createUrl(url: keyof paths, params: Params): string {
    let queryString: string | undefined = !!params.query ? Object.entries(params.query).map(([key,value]) => `${key}=${encodeURIComponent(value)}`).join('&') : undefined
    return `${format(url, params.path ?? {})}${queryString ? `?${queryString}` : ""}`
}

export function get<Resp>(
    domain: string,
    url: keyof paths,
    params: Params = {}
): NetworkPendingAction<Resp> {
    return {
        type: domain,
        payload: {
            request: {
                method: "get",
                url: createUrl(url, params),
                headers: params.headers
            },
        },
    };
}

export function post<Req, Resp>(
    domain: string,
    url: keyof paths,
    body: Req,
    params: Params = {}
): NetworkPendingAction<Resp> {
    return {
        type: domain,
        payload: {
            request: {
                url: createUrl(url, params),
                method: "post",
                data: body,
                headers: params.headers
            },
        },
    };
}

export function put<Req, Resp>(
    domain: string,
    url: keyof paths,
    body: Req,
    params: Params = {}
): NetworkPendingAction<Resp> {
    return {
        type: domain,
        payload: {
            request: {
                url: createUrl(url, params),
                method: "put",
                data: body,
                headers: params.headers
            },
        },
    };
}

export function isPendingAction<T>(
    action: NetworkAction<T>,
    domain: string
): action is NetworkPendingAction<T> {
    return action.type === domain;
}

export interface NetworkSuccessAction<T> extends NetworkAction<T> {
    readonly type: string;
    readonly payload: {
        data: T;
    };
}

export function isSuccessAction<T>(
    action: NetworkAction<T>,
    domain: string
): action is NetworkSuccessAction<T> {
    return action.type === `${domain}_SUCCESS`;
}

export interface NetworkFailAction<T> extends NetworkAction<T> {
    readonly type: string;
    readonly error: {
        response?: {
            data?: object
        }
        data: string;
    };
}

export function isFailAction<T>(
    action: NetworkAction<T>,
    domain: string
): action is NetworkFailAction<T> {
    return action.type === `${domain}_FAIL`;
}

export interface NetworkState<T> {
    state: "init" | "pending" | "success" | "error";
}

export interface InitState<T> extends NetworkState<T> {
    state: "init";
}

export function isInit<T>(state: NetworkState<T>): state is InitState<T> {
    return state.state === "init";
}

export interface PendingState<T> extends NetworkState<T> {
    state: "pending";
}

export function isPending<T>(state: NetworkState<T>): state is PendingState<T> {
    return state.state === "pending";
}

export interface SuccessState<T> extends NetworkState<T> {
    state: "success";
    data: T;
}

export function isSuccess<T>(state: NetworkState<T>): state is SuccessState<T> {
    return state.state === "success";
}

export interface ErrorState<T> extends NetworkState<T> {
    state: "error";
    error: any;
}

export function isError<T>(state: NetworkState<T>): state is ErrorState<T> {
    return state.state === "error";
}

export interface NetworkResetAction<T> extends NetworkAction<T> {
    readonly type: string
}

export function reset<T>(domain: string): NetworkResetAction<T> {
    return {
        type: `${domain}_RESET`
    }
}

export function isResetAction<T>(action: NetworkAction<T>, domain: string): action is NetworkResetAction<any> {
    return action.type == `${domain}_RESET`
}

function initState<T>(): NetworkState<T> {
    return {
        state: "init",
    };
}

export function networkStateReducer<T>(
    domain: string
): Reducer<NetworkState<T>, NetworkAction<T>> {
    return (state = initState(), action) => {
        if (isPendingAction(action, domain)) {
            let newState: PendingState<T> = {
                state: "pending",
            };
            return newState;
        } else if (isSuccessAction(action, domain)) {
            let newState: SuccessState<T> = {
                state: "success",
                data: action.payload.data as T,
            };
            return newState;
        } else if (isFailAction(action, domain)) {
            let newState: ErrorState<T> = {
                state: "error",
                error: action.error.response?.data ?? action.error.data,
            };
            return newState;
        } else if (isResetAction(action, domain)) {
            return initState()
        }

        return state;
    };
}

export interface NetworkFamilyAction<T> extends Action {
    readonly family: string
}

export interface NetworkFamilyPendingAction<T> extends NetworkFamilyAction<T> {
    readonly types: [string, string, string]
    readonly payload: {
        request: {
            method: "get" | "post" | "put";
            url: string;
            data?: any;
        };
    };
}

export function getFamily<Resp>(
    domain: string,
    url: keyof paths,
    family: string,
    params: Params = {}
): NetworkFamilyPendingAction<Resp> {
    return {
        type: undefined,
        types: [`${domain}:${family}`, `${domain}_SUCCESS:${family}`, `${domain}_FAIL:${family}`],
        payload: {
            request: {
                method: "get",
                url: createUrl(url, params),
            },
        },
        family
    };
}

export function postFamily<Req, Resp>(
    domain: string,
    url: keyof paths,
    family: string,
    body: Req,
    params: Params = {}
): NetworkFamilyPendingAction<Resp> {
    return {
        type: undefined,
        types: [`${domain}:${family}`, `${domain}_SUCCESS:${family}`, `${domain}_FAIL:${family}`],
        payload: {
            request: {
                url: createUrl(url, params),
                method: "post",
                data: body,
            },
        },
        family
    };
}

export interface NetworkFamilySuccessAction<T> extends NetworkFamilyAction<T> {
    readonly type: string;
    readonly payload: {
        data: T;
    };
}


export interface NetworkFamilyFailAction<T> extends NetworkFamilyAction<T> {
    readonly type: string;
    readonly error: {
        data: string;
    };
}

export interface NetworkFamilyResetAction<T> extends NetworkFamilyAction<T> {
    readonly type: string;
}

export function resetFamily<Resp>(domain: string, family: string): NetworkFamilyResetAction<Resp> {
    return {
        family,
        type: `${domain}_RESET:${family}`
    }
}

export function isSuccessFamilyAction<T>(
    action: NetworkAction<T>,
    domain: string,
    family: string
): action is NetworkSuccessAction<T> {
    return action.type === `${domain}_SUCCESS:${family}`;
}

export function isFailFamilyAction<T>(
    action: NetworkAction<T>,
    domain: string,
    family: string
): action is NetworkFailAction<T> {
    return action.type === `${domain}_FAIL:${family}`;
}

export function isResetFamilyAction<T>(
    action: NetworkAction<T>,
    domain: string,
    family: string
): action is NetworkFailAction<T> {
    return action.type === `${domain}_RESET:${family}`;
}

export interface NetworkStateFamily<T> {
    [k: string]: NetworkState<T>
}

export function networkStateReducerFamily<T>(domain: string): Reducer<NetworkStateFamily<T>, NetworkFamilyAction<T>> {
    let reducer = networkStateReducer<T>(domain);
    return (state = {}, action) => {
        if (action.type.startsWith(domain)) {
            let [type, family] = action.type.split(":")
            return {
                ...state,
                [family]: reducer(state[family] || initState<T>(), {
                    ...action,
                    type
                })
            };
        } else return state
    }
}

export function resolveFamily<T>(family: string, state: NetworkStateFamily<T>): NetworkState<T> {
    return state[family] || initState()
}

export type NetworkDependency<T> =
    (dispatch: Dispatch, action: Action) => void

export function networkDependency<T, U = unknown>(sourceDomain: string, nextAction: ((action: NetworkAction<U>) => (NetworkPendingAction<T> | NetworkFamilyPendingAction<T>))): NetworkDependency<T> {
    return function (dispatch: Dispatch, action: Action) {
        try {
            if (isSuccessAction(action, sourceDomain) || isFailAction(action, sourceDomain)) {
                dispatch(nextAction(action))
            }
        } catch (e) {

        }
    }
}

export function networkDependencyFamily<T, U = unknown>(sourceDomain: string, nextAction: ((action: NetworkAction<U>, family: string) => (NetworkPendingAction<T> | NetworkFamilyPendingAction<T>))): NetworkDependency<T> {
    return function (dispatch: Dispatch, action: Action) {
        try {
            let [type, family] = action.type.split(":")

            if (isSuccessFamilyAction(action, sourceDomain, family) || isFailFamilyAction(action, sourceDomain, family)) {
                dispatch(nextAction(action, family))
            }
        } catch (e) {

        }
    }
}

export function dependencyMiddleware(...deps: NetworkDependency<unknown>[]): Middleware {
    return api => next => action => {
        try {
            deps.forEach(dep => dep(api.dispatch, action))
        } catch (e) {

        }
        return next(action)
    }
}

interface CreateActionFamilyProps {
    domain:string
    url:keyof paths
    family: string
    params?: Params
    savePath?: NetworkFamilyPendingAction<any>
}

export function createActionFamily({domain, url, family, params, savePath}: CreateActionFamilyProps): NetworkFamilyPendingAction<any> {
    let output: NetworkFamilyPendingAction<any> = {} as NetworkFamilyPendingAction<any>

    if (!!params) {
       output =  getFamily(domain, url, family, params)
    } else {
       output =  getFamily(domain, url, family)
    }

    savePath = output ?? {} as NetworkFamilyPendingAction<any>
    return output
}

// interface CreateActionProps {
//     domain:string
//     url:keyof paths
//     params?: Params
//     savePath?: PendingAction
// }
//
// export function createAction({domain, url, params, savePath}: CreateActionProps) : PendingAction {
//     let output: PendingAction = {} as PendingAction
//
//     if (!!params) {
//         output =  get(domain, url, params)
//     } else {
//         output =  get(domain, url)
//     }
//
//     savePath = output ?? {} as PendingAction
//     return output
// }
