import {Action, Reducer} from "redux";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export interface MobileMenuState {
    open: boolean
}

interface SetMobileMenuState extends Action {
    type: "@appigo-retailer-ui/set-mobile-menu-status"
    open: boolean
}

function isMobileMenuState(action: Action): action is SetMobileMenuState {
    return action.type === "@appigo-retailer-ui/set-mobile-menu-status"
}

function setMobileMenuState(open: boolean): SetMobileMenuState {
    return {
        open,
        type: "@appigo-retailer-ui/set-mobile-menu-status"
    }
}

export const mobileMenuStatusReducer: Reducer<MobileMenuState, SetMobileMenuState> = (state = { open: false }, action) =>
    isMobileMenuState(action) ? {open: action.open} : state

export function useMobileMenuState(): [boolean, (open: boolean) => void] {
    const dispatch = useDispatch();
    return [
        useSelector<RootState, MobileMenuState>(state => state.uiState.mobileMenu).open,
        open => dispatch(setMobileMenuState(open))
    ]
}