import {NetworkState, networkStateReducer, post, reset} from "../networkStateReducer";
import {Cart} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";

export enum DeliveryMethod {
    NONE ,STORE, DELIVERY
}

export const domain = "@appigo-retailer/cart/set-delivery-method"

export const cartSetDeliveryMethodReducer = networkStateReducer<Cart>(domain)

export function useSetDeliveryMethod(): [NetworkState<Cart>, (deliveryMethod: DeliveryMethod) => void, () => void ] {

    let dispatch = useDispatch();

    return [
        useSelector<RootState, NetworkState<Cart>>(state => state.cart.setDeliveryMethod),
        (deliveryMethod) => dispatch(post(domain, "/customer-api/v2/cart/set/delivery-method", deliveryMethod)),
        () => dispatch(reset(domain))
    ]
}